import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 48px 0;
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const FirstSection = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 40px;
  h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 72px;
    line-height: 86px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #da5697;
    padding-bottom: 14px;
    @media screen and (max-width: 900px) {
      font-size: 30px;
      text-align: center;
    }
  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 56px;
    color: #111729;
    max-width: 664px;
    @media screen and (max-width: 900px) {
      font-size: 24px;
      line-height: 34px;
      text-align: center;
    }
  }
  .tenYears {
    padding-top: 32px;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    color: #111729;
    @media screen and (max-width: 900px) {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    }
  }
  ul {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #111729;
    padding: 12px 0 0 16px;
    @media screen and (max-width: 900px) {
      font-size: 18px;
    }
  }
`;

export const WrapHeart = styled.div`
  position: relative;
  margin-top: 31px;
  @media screen and (max-width: 900px) {
    margin-top: 60px;
    text-align: center;
  }
  a {
    color: #fff;
    padding: 7px 20px 7px 44px;
    background: #da5697;
    border-radius: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
  }
  img {
    position: absolute;
    width: 17.67px;
    height: 16.4px;
    left: 20px;
    top: 3px;
    @media screen and (max-width: 900px) {
      left: 50%;
      transform: translateX(-60px);
    }
  }
  &.onMainPage {
    a {
      padding: 11px 20px 11px 44px;
    }
  }
`;

export const WrapImg = styled.div`
  position: absolute;
  right: 0;
  top: -15px;
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const WhichHelp = styled.div`
  margin-top: 90px;
  @media screen and (max-width: 900px) {
    margin-top: 20px;
  }
  h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #111729;
    padding-bottom: 20px;
    @media screen and (max-width: 900px) {
      text-align: center;
      font-size: 30px;
    }
  }
  p {
    padding-bottom: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #111729;
    &.limitWidth {
      max-width: 65%;
      @media screen and (max-width: 1500px) {
        max-width: 50%;
      }
      @media screen and (max-width: 900px) {
        max-width: 100%;
      }
    }
  }
  ul {
    max-width: 360px;
    @media screen and (max-width: 1250px) {
      padding-left: 16px;
    }
    @media screen and (max-width: 1250px) {
      max-width: 320px;
    }
    @media screen and (max-width: 900px) {
      max-width: 100%;
    }
  }
  ul li {
    font-style: normal;
    white-space: nowrap;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #111729;
    @media screen and (max-width: 1200px) {
      white-space: inherit;
    }
    @media screen and (max-width: 1100px) {
      font-size: 16px;
    }
  }
`;

export const PinkCounter = styled.div`
  transform: translateY(-50px);
  height: 0;
  @media screen and (max-width: 900px) {
    transform: translateY(0);
    margin-top: 40px;
    height: auto;
  }
`;

export const SevenHundred = styled.div`
  padding: 12px 24px;
  background: #fbf2f8;
  border-radius: 12px;
  max-width: 360px;
  margin-bottom: 120px;
  span {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 56px;
    color: #da5697;
    display: block;
    @media screen and (max-width: 1250px) {
      font-size: 40px;
    }
  }
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #111729;
  @media screen and (max-width: 1250px) {
    font-size: 16px;
    line-height: 22px;
  }
  @media screen and (max-width: 900px) {
    margin-bottom: 8px;
  }
`;

export const TwoThousand = styled.div`
  padding: 12px 24px;
  background: #fbf2f8;
  border-radius: 12px;
  max-width: 360px;
  span {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 56px;
    color: #da5697;
    display: block;
    @media screen and (max-width: 1250px) {
      font-size: 40px;
    }
  }
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #111729;
  @media screen and (max-width: 1250px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const WrapperWhichHelpItems = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  .lastBRPink {
    display: inline-block;
    br {
      display: none;
    }
  }
  @media screen and (max-width: 900px) {
    justify-content: initial;
    flex-direction: column;
    margin-bottom: 40px;
    .lastBRPink {
      display: none;
    }
  }
  ul.specialTransform {
    transform: translateX(60px);
    @media screen and (max-width: 1000px) {
      transform: none;
    }
  }
`;

// GumHelp

export const GumHelp = styled.div``;
export const InnerGumHelp = styled.div`
  h4 {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #111729;
    padding-bottom: 40px;
    margin-top: 20px;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
    }
  }
`;
export const WrapItemsGum = styled.div`
  display: flex;
  position: relative;
  .starGum {
    position: absolute;
    right: 0;
    bottom: 0;
    @media screen and (max-width: 900px) {
      right: 30px;
    }
  }
  @media screen and (max-width: 1060px) {
    flex-direction: column;
  }
`;

export const FirsttGumItem = styled.div`
  background: #f3faf9;
  border-radius: 12px;
  padding: 12px 24px 28px 24px;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #111729;
  max-width: 376px;
  margin-right: 17px;
  span {
    font-weight: 900;
    font-size: 56px;
    line-height: 56px;
    color: #438a92;
    display: block;
  }
  @media screen and (max-width: 1060px) {
    padding-right: 0;
    padding-left: 0;
    max-width: 300px;
  }
  @media screen and (max-width: 900px) {
    max-width: 100%;
    padding: 12px 24px 28px 24px;
    margin: 0 0 17px 0;
  }
`;
export const SecondGumItem = styled.div``;

export const ThirdGumItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media screen and (max-width: 900px) {
    overflow: hidden;
  }
  span {
    position: relative;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: #69bac1;
    transform: translateX(50px);
    a {
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
      color: #69bac1;
    }
    img {
      position: absolute;
      top: -1px;
      left: -31px;
    }
  }
  .flowersGum {
    position: absolute;
    top: -50px;
    left: 20px;
    @media screen and (max-width: 900px) {
      position: relative;
      max-width: 100px;
      top: inherit;
      left: inherit;
      margin: 0 auto 40px auto;
    }
  }
`;
export const OneGumItem = styled.div`
  padding: 12px 24px;
  background: #f3faf9;
  border-radius: 12px;
  max-width: 334px;
  margin-bottom: 17px;
  margin-right: 17px;
  span {
    font-weight: 900;
    font-size: 56px;
    line-height: 56px;
    color: #438a92;
    display: block;
  }
  @media screen and (max-width: 1060px) {
    padding-left: 5px;
    padding-right: 5px;
  }
  @media screen and (max-width: 900px) {
    max-width: 100%;
    margin: 0 0 17px 0;
    padding: 12px 24px;
  }
`;
export const TwoGumItem = styled.div`
  padding: 12px 24px;
  background: #f3faf9;
  border-radius: 12px;
  max-width: 334px;
  margin-right: 17px;
  span {
    font-weight: 900;
    font-size: 56px;
    line-height: 56px;
    color: #438a92;
    display: block;
  }
  @media screen and (max-width: 1060px) {
    padding-left: 5px;
    padding-right: 5px;
  }
  @media screen and (max-width: 900px) {
    max-width: 100%;
    margin: 0 0 17px 0;
    padding: 12px 24px;
  }
`;

// /gumHelp

// MAP

export const MapArea = styled.div`
  margin: 115px 0;
  @media screen and (max-width: 900px) {
    margin: 50px 0 30px 0;
  }
`;
export const InnerMapArea = styled.div`
  position: relative;
  padding-bottom: 200px;
  @media screen and (max-width: 900px) {
    padding-bottom: 40px;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #111729;
    max-width: 760px;
    margin-bottom: 24px;
  }
  .mapArea {
    position: absolute;
    right: 0;
    top: 20px;
    max-width: 857px;
    @media screen and (max-width: 900px) {
      position: relative;
      max-width: 100%;
      top: 0;
      margin-bottom: 50px;
    }
  }
`;
export const WrapMapArea = styled.div`
  padding-left: 30px;
  @media screen and (max-width: 900px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const FirstValueMap = styled.div`
  margin-bottom: 24px;
  span {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 56px;
    color: #da5697;
    display: block;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: -30px;
      top: 30%;
      width: 20px;
      height: 20px;
      border: 1px solid #da5697;
      border-radius: 50%;
    }
  }
`;
export const SecondValueMap = styled.div`
  span {
    font-weight: 900;
    font-size: 56px;
    line-height: 56px;
    color: #69bac1;
    display: block;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: -30px;
      top: 30%;
      width: 20px;
      height: 20px;
      border: 1px solid #69bac1;
      border-radius: 50%;
    }
  }
`;

// /map

// OurMission

export const OurMission = styled.div`
  background: #f3fcfb;
  border-radius: 20px;
  padding: 64px 120px;
  width: 86%;
  margin: 40px auto 0 auto;
  @media screen and (max-width: 1150px) {
    padding: 60px;
  }
  @media screen and (max-width: 900px) {
    padding: 12px 24px;
    width: 80%;
  }
`;

export const InnerOurMission = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    text-align: center;
  }
  p {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #69bac1;
    white-space: nowrap;
    margin-right: 40px;
    @media screen and (max-width: 900px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  a {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: #69bac1;
    margin-top: 20px;
    display: inline-block;
  }
`;
export const TextOurMission = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  color: #111729;
  max-width: 760px;
  @media screen and (max-width: 1150px) {
    font-size: 24px;
    line-height: 27px;
  }
`;

// / ourMission

// WeInSmi

export const WeInSmi = styled.div`
  margin: 120px 0;
`;

export const InnerWeInSmi = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Rukovoditeli = styled.div`
  max-width: 560px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding-bottom: 36px;
  @media screen and (max-width: 1200px) {
    max-width: 500px;
    overflow: hidden;
  }
  @media screen and (max-width: 1100px) {
    max-width: 450px;
    overflow: hidden;
    img {
      transform: translateX(-40px);
    }
    .ruc-name {
      font-size: 32px !important;
    }
  }
  @media screen and (max-width: 900px) {
    max-width: 100%;
    img {
      transform: translateX(0);
      max-width: 100%;
    }
    .ruc-name {
      font-size: 24px !important;
      line-height: 29px !important;
      margin: 20px 0;
    }
  }
  .ruc-cent {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    text-transform: uppercase;
    color: #677489;
    padding-left: 24px;
    padding-top: 24px;
  }
  .ruc-name {
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #111729;
    padding-left: 24px;
  }
`;

export const RucRead = styled.div`
  img {
    width: 20px;
    height: 20px;
    transform: translate(24px, 4.5px);
  }
  a {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: #69bac1;
    padding-left: 34px;
    &:hover {
      color: #356d74;
    }
  }
`;

export const RukVideo = styled.div`
  display: flex;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    margin-top: 40px;
  }
  iframe {
    border-radius: 6px;
    @media screen and (max-width: 1100px) {
      max-width: 200px;
    }
    @media screen and (max-width: 900px) {
      min-width: 100%;
    }
  }
`;

export const President = styled.div`
  margin-right: 20px;
  @media screen and (max-width: 900px) {
    margin-right: 0;
  }
  position: relative;
  div {
    width: 260px;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    color: #111729;
    @media screen and (max-width: 1100px) {
      max-width: 200px;
      font-size: 16px;
    }
    @media screen and (max-width: 1100px) {
      min-width: 100%;
      text-align: center;
      margin-bottom: 30px;
    }
  }
  .prez-star {
    position: absolute;
    bottom: -7%;
    right: 40%;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  .prez-star-light {
    position: absolute;
    bottom: 2%;
    right: 34%;
    opacity: 0.5;
    width: 23px;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
`;

export const Patriarh = styled.div`
  position: relative;
  @media screen and (max-width: 900px) {
    text-align: center;
  }
  .text-patriarh {
    width: 260px;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    color: #111729;
    @media screen and (max-width: 1100px) {
      max-width: 200px;
      font-size: 16px;
    }
    @media screen and (max-width: 900px) {
      min-width: 100%;
      margin-bottom: 30px;
      text-align: center;
    }
  }
  .button-to-smi {
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    @media screen and (max-width: 1100px) {
      position: relative;
    }
    @media screen and (max-width: 900px) {
      transform: translateY(30px);
    }
    a {
      background: #d7f8f5;
      border-radius: 24px;
      padding: 7px 54px 9px 20px;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      color: #356d74;
      border: 1px solid #d7f8f5;
      &:hover {
        background: #d7f8f5;
        border: 1px solid #69bac1;
      }
      &:active {
        background: #b4eeea;
        border: 1px solid #69bac1;
      }
    }
    img {
      position: absolute;
      width: 16px;
      height: 15.56px;
      right: 23px;
      top: 4.5px;
    }
  }
`;

// / weInSmi

// News

export const WrapNews = styled.div`
  h5 {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
    }
  }
  .mainAllNews {
    position: relative;
    display: inline-block;
    margin-top: 50px;
    @media screen and (max-width: 900px) {
      margin-top: 10px;
    }
    a {
      background: #d7f8f5;
      border-radius: 24px;
      padding: 7px 54px 9px 20px;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      color: #356d74;
      border: 1px solid #d7f8f5;
      &:hover {
        background: #d7f8f5;
        border: 1px solid #69bac1;
      }
      &:active {
        background: #b4eeea;
        border: 1px solid #69bac1;
      }
    }
    img {
      position: absolute;
      width: 16px;
      height: 15.56px;
      left: 127px;
      top: 4.5px;
    }
  }
`;
export const InnerNews = styled.div`
  a {
    max-width: 30%;
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 900px) {
      max-width: 500px;
    }
  }
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: initial;
  }
  .TitleNewsMain {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    margin-top: 16px;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    color: #111729;
  }
  .SubtitleMain {
    margin-top: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #111729;
  }
  .dateMain {
    margin-top: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: #677489;
    @media screen and (max-width: 900px) {
      margin-bottom: 30px;
    }
  }
  &:hover {
    cursor: pointer;
  }
  p,
  img,
  a {
    &:hover {
      cursor: pointer;
    }
  }
`;
export const WrapItemsNewsOne = styled.div`
  &:hover {
    cursor: pointer;
  }
  img {
    max-width: 360px;
    height: 300px;
    display: block;
    margin: 0 auto;
  }
  @media screen and (max-width: 900px) {
    max-width: 500px;
  }
`;
export const WrapItemsNewsTwo = styled.div`
  max-width: 30%;
  img {
    max-width: 100%;
  }
  @media screen and (max-width: 900px) {
    max-width: 500px;
  }
`;
export const WrapItemsNewsThree = styled.div`
  max-width: 30%;
  img {
    max-width: 100%;
  }
  @media screen and (max-width: 900px) {
    max-width: 500px;
  }
`;

// /news

// Voluntteers

export const WrapVolunteers = styled.div`
  margin: 80px;
  @media screen and (max-width: 900px) {
    margin: 80px 0 0 0;
  }
`;
export const InnerVolunteers = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: initial;
  }
`;
export const LeftVolunteers = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  @media screen and (max-width: 900px) {
    text-align: center;
  }

  .vol-one {
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    text-transform: uppercase;
    color: #677489;
  }

  .vol-two {
    margin-bottom: 34px;
    max-width: 580px;
    margin-top: 24px;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #000;

    span {
      color: #69bac1;
    }

    @media screen and (max-width: 1150px) {
      font-size: 30px;
      line-height: 40px;
      max-width: 450px;
    }
    @media screen and (max-width: 900px) {
      font-size: 23px;
      line-height: 35px;
      max-width: 100%;
      text-align: center;
    }
  }

  .vol-url {
    background: #69bac1;
    border-radius: 24px;
    padding: 10px 24px;
    color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;

    &:hover {
      background: #47868cff;
    }
  }

  .leaf {
    position: absolute;
    top: 0;
    left: -100px;
  }

  .star-volunteers {
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0.6;
  }

  .wrap-cont-volun {
    @media screen and (max-width: 900px) {
      margin-bottom: 50px;
    }
  }
`;
export const RightVolunteers = styled.div`
  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;

// / voluntteers

// WrapPartners

export const WrapPartners = styled.div`
  h4 {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
    }
  }
  .url-to-partn a {
    background: #da5697;
    border-radius: 24px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    padding: 10px 24px;
  }
  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;

export const InnerPaartners = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  a {
    flex: 0 1 calc(20% - 8px);
    max-width: 200px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    display: inline-block;
    width: 100%;
    margin: 0 10px 40px 10px;
    @media screen and (max-width: 900px) {
      max-width: 150px;
    }
  }
`;

// / wrapPartners

export const CentriStraniWrap = styled.div`
  transform: translateY(-40px);
  @media screen and (max-width: 900px) {
    margin: 40px 0 20px 0;
    text-align: center;
    transform: none;
  }
  a {
    color: #fff;
    padding: 9px 20px;
    background: #69bac1;
    border-radius: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    &:hover {
      background: #7bccd1;
    }
  }
  .cetri-strani {
    color: #fff;
    padding: 9px 20px;
    background: #69bac1;
    border-radius: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    &:hover {
      background: #7bccd1;
    }
  }
`;
