import React from 'react';
import { MetodPomoshiPage } from "../components/metodPomoshiPage";

export const MetodPomoshi = () => {
  return (
    <div>
      <MetodPomoshiPage />
    </div>
  );
};
