import React from 'react';
import { Etapi } from "../components/etapi";

export const Steps = () => {
  return (
    <div>
      <Etapi />
    </div>
  );
};
