import React from "react";
import {
  Wrapper,
  Inner,
  WrapMain,
  LeftOne,
  RightOne,
  Wrapname,
  Wrapdescr,
  WrapMainDescr,
  WrapSecond,
  SecondText,
  WrapPhoto,
  InnerList,
  InnerItemtext,
  InnerItem,
  GrayText,
  WrapAnketa,
  SveaziPhoto,
} from "./previewPage-styled";
import { Link } from "react-router-dom";

import volone from "./volone.png";
import voltwo from "./voltwo.jpeg";

import one from "./one.png";
import two from "./two.png";
import three from "./three.png";
import four from "./four.png";

export const Volonteri = () => {
  return (
    <Wrapper>
      <Inner>
        <WrapMain>
          <LeftOne>
            <h2>Волонтеры</h2>
            <WrapMainDescr>
              Каждый день в работе Центра принимают участие десятки
              удивительных, неравнодушных людей, которым близки идеи
              взаимопомощи и милосердия. Среди них и действующие сотрудники, и
              волонтёры. К слову, практически все члены команды появились в ней,
              начав помогать сначала именно как волонтёры. Кто они для нас?
              Энергия бурной реки. Руки, которых всегда не хватает, и которые
              всегда готовы помочь. Так необходимое нам спасительное время,
              когда сроки поджимают, и нужно 150% ресурса.
            </WrapMainDescr>
          </LeftOne>
          <RightOne>
            <div>
              <img src={voltwo} alt="ДомДляМамы" />
              <Wrapname>
                Светлана
                <br />
                Краюшкина
              </Wrapname>
              <Wrapdescr>Руководитель службы волонтеров</Wrapdescr>
            </div>
            <div style={{ transform: "translateY(30px)" }}>
              <img src={volone} alt="ДомДляМамы" />
              <Wrapname>
                Виктория
                <br />
                Гребенюк
              </Wrapname>
              <Wrapdescr>Руководитель службы волонтеров</Wrapdescr>
            </div>
          </RightOne>
        </WrapMain>

        <WrapSecond>
          <h3>Кто такой волонтер?</h3>
          <SecondText>
            Благотворитель жертвует что-то материальное, волонтер - не менее
            ценное: внимание, опыт, знания… В России таких людей раньше называли
            добровольцами. Волонтёр — это человек, который безвозмездно и
            добровольно делает что-то для людей, нуждающихся в помощи. У каждого
            волонтера — своя жизнь, работа или учеба. Свободное время он
            посвящает не только отдыху и развлечениям, но и тем, кому нужно
            помочь.
          </SecondText>
          <h3>Чем может помочь волонтер?</h3>
          <SecondText>
            У нашего Центра есть несколько проектов, и в каждом из них — много
            вариантов помощи, которая нужна ежедневно. Каждый волонтер выбирает
            то, что ему по силам:
          </SecondText>
        </WrapSecond>

        <WrapPhoto>
          <InnerItem className="mb50">
            <InnerItemtext>Помощь в Центре</InnerItemtext>
            <img src={three} alt="ДомДляМамы" />

            <InnerList>
              <ul>
                <li>разбор вещей и продуктов на складе,</li>
                <li>подготовка к отправке по адресу или в регионы,</li>
                <li>уборка,</li>
                <li>уход за цветами,</li>
                <li>обзвон,</li>
                <li>мужские руки для мелкого ремонта,</li>
                <li>погрузка/разгрузка.</li>
              </ul>
            </InnerList>
          </InnerItem>

          <InnerItem className="mb50">
            <InnerItemtext>Помощь мамам с детьми</InnerItemtext>
            <img src={two} alt="ДомДляМамы" />
            <InnerList>
              <ul>
                <li>организация игр с детьми, пока маме нужно отлучиться,</li>
                <li>прогулки вместе с мамой, общение с ней,</li>
                <li>сопровождение на мероприятие.</li>
                <li style={{ opacity: "0" }} className="hide-it-vol">
                  -
                </li>
                <li style={{ opacity: "0" }} className="hide-it-vol">
                  -
                </li>
                <li style={{ opacity: "0" }} className="hide-it-vol">
                  -
                </li>
                <li style={{ opacity: "0" }} className="hide-it-vol">
                  -
                </li>
              </ul>
            </InnerList>
          </InnerItem>

          <InnerItem className="mb50">
            <InnerItemtext>Помощь с транспортом</InnerItemtext>
            <img src={one} alt="ДомДляМамы" />
            <InnerList>
              <ul>
                <li>развоз гуманитарной помощи,</li>
                <li>курьерская помощь (отвезти/забрать документы).</li>
                <li style={{ opacity: "0" }} className="hide-it-vol">
                  -
                </li>
                <li style={{ opacity: "0" }} className="hide-it-vol">
                  -
                </li>
                <li style={{ opacity: "0" }} className="hide-it-vol">
                  -
                </li>
                <li style={{ opacity: "0" }} className="hide-it-vol">
                  -
                </li>
                <li style={{ opacity: "0" }} className="hide-it-vol">
                  -
                </li>
              </ul>
            </InnerList>
          </InnerItem>

          <InnerItem>
            <InnerItemtext>Помощь специалистов</InnerItemtext>
            <img src={four} alt="ДомДляМамы" />
            <InnerList>
              <GrayText>
                психологи, педагоги, дефектологи, логопеды, юристы
              </GrayText>
              <ul>
                <li>проведение мастер-классов,</li>
                <li>обучения для взрослых, детей,</li>
                <li>организация тематических мероприятий,</li>
                <li>помощь в полиграфии,</li>
                <li>фото и видеосъемка.</li>
                <li style={{ opacity: "0" }} className="hide-it-vol">
                  -
                </li>
                <li style={{ opacity: "0" }} className="hide-it-vol">
                  -
                </li>
              </ul>
            </InnerList>
          </InnerItem>
        </WrapPhoto>

        <WrapAnketa>
          <h4>
            Заполни Анкету волонтера{" "}
            <span>и присоединяйся к нашей дружной команде!</span>
          </h4>
          <p>Добро пожаловать в ряды волонтеров!</p>
          <button>
            <Link to="/volonteri/anketa">Заполнить анкету</Link>
          </button>
        </WrapAnketa>

        <SveaziPhoto>
          {/*Если вы желаете стать волонтером Центра, пожалуйста напишите нам на{" "}*/}
          {/*<a href="mailto:info@domdliamamy.ru">info@domdliamamy.ru</a> . В*/}
          {/*письме обязательно укажите ваш контактный номер телефона. Коорднатор*/}
          {/*волонтеров свяжется с вами! <br />*/}
          Благодарим за внимание к нашему проекту и желание помочь!
        </SveaziPhoto>
      </Inner>
    </Wrapper>
  );
};
