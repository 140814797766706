import React from "react";
import {Inner, Wrapper, WrapRoomImage} from "../rooms-styled";
import room from "../rooms-images/14.jpeg";
export const FourTeenRoom = () => {
  return (
    <Wrapper>
      <Inner>
        <h2>Комната отремонтирована на средства, собранные Н.Фриске, М.Ивановой (группа Краски) и еще 302 друзьями "Дома для мамы"</h2>
        <WrapRoomImage>
          <img src={room} alt="Дом для мамы"/>
        </WrapRoomImage>
        <br/>
        <br/>
        <p className="bold-sec-text">Наталья Фриске:</p>
        <br/>
        <br/>
        <p className="norm-text">
          Максимально стараюсь помогать людям. Про “Дом для мамы” узнала от подписчицы, и решила по возможности помогать.

        </p>
        <br/>
        <br/>
        <p className="bold-sec-text">Марина Иванова:</p>
        <br/>
        <br/>
        <p className="norm-text">
          Вопрос рассказать о себе ставит меня всегда в ступор) Не умею много говорить о себе. Наверное, больше обо мне скажет моё творчество. Я - солистка группы “Краски”. Поэтому априори люблю раскрашивать жизнь людей, делать их немного счастливее. Люблю, когда люди улыбаются, радуются!Вопрос рассказать о себе ставит меня всегда в ступор) Не умею много говорить о себе. Наверное, больше обо мне скажет моё творчество. Я - солистка группы “Краски”. Поэтому априори люблю раскрашивать жизнь людей, делать их немного счастливее. Люблю, когда люди улыбаются, радуются!
          <br/>
          <br/>
          С детства моё сердце открыто к тем, кто нуждается. Всегда пытаюсь накормить голодных животных - собачек, кошечек. Бабушки на улице тоже всегда вызывают определенное чувство тревоги за старшее поколение. Хочется, чтобы каждый на этой планете был сыт, в тепле, уюте. Это элементарные вещи, но они и самые важные в построении жизни каждого человека.
          <br/>
          <br/>
          «Домик для мамы» для меня - это ещё один благотворительный проект, который создали добрые, светлые люди, которым не всё равно на женщин, которые в положении и ещё более уязвимы, нежны, нуждаются в заботе и поддержке! Очень хорошо, что я встретила вас и могу приобщиться к такому благому делу, как приют для мамочек.
          <br/>
          <br/>
          Женщина - это очень хрупкое создание. Я как их представитель, могу сказать, бывают такие моменты в жизни, когда мы нуждаемся в поддержке, когда не справляемся сами, когда нам нужна та рука помощи, которую нам протянут. Беременные женщины и женщины с детьми - это существа, которым нужно помогать. Они ведут в жизнь, в будущее нового человека. Это очень важно - быть рядом.
        </p>

      </Inner>
    </Wrapper>
  )
}
