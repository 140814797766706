import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 48px 0;
  margin-bottom: 160px;
  @media screen and (max-width: 900px) {
    margin-bottom: 80px;
  }
  h2  {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
    }
  }
  input, textarea {
    &:hover {
      cursor: auto;
    }
  }
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const WrapTwoParts = styled.div`
  display: flex;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    text-align: center;
  }
`;
export const FormAndDescription = styled.div`
  width: 65%;
  @media screen and (max-width: 900px) {
    width: inherit;
  }
  .main-help-description {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #111729;
    @media screen and (max-width: 900px) {
      font-size: 18px;
      line-height: 28px;
    }
  }
  .describe-help {
    margin-top: 40px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #111729;
  }
  textarea {
    resize: none;
    margin-top: 10px;
    border: 1px solid #CDD5E0;
    border-radius: 6px;
    width: 100%;
    padding: 5px;
    &:focus {
      outline: none !important;
      border: 1px solid #69BAC1;
    }
    @media screen and (max-width: 900px) {
      width: calc(100% - 10px);
    }
  }
`;

export const ContactsNeedHelp = styled.div`
  width: 30%;
  margin-left: 5%;
  @media screen and (max-width: 900px) {
    width: inherit;
    margin-left: 0;
  }
  p, a {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: #111729;
    margin-bottom: 12px;
  }
  .gray-help-text {
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
    color: #97A3B6;
    margin-bottom: 0;
  }
`;

export const WrapNamePhone = styled.div`
  display: flex;
  margin-top: 15px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const WrapName = styled.div`
  width: 47%;
  margin-right: 6%;
  @media screen and (max-width: 900px) {
    width: inherit;
    margin-right: 0;
  }
  input {
    width: 90%;
    @media screen and (max-width: 900px) {
      width: calc(100% - 46px);
    }
    border: 1px solid #CDD5E0;
    border-radius: 6px;
    padding: 14px 23px;
    &:focus {
      outline: none !important;
      border: 1px solid #69BAC1;
    }
  }
  p {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #111729;
  }
`;

export const WrapPhone = styled.div`
  width: 47%;
  @media screen and (max-width: 900px) {
    width: inherit;
    margin-top: 15px;
  }
  input {
    width: 90%;
    @media screen and (max-width: 900px) {
      width: calc(100% - 46px);
    }
    border: 1px solid #CDD5E0;
    border-radius: 6px;
    padding: 14px 23px;
    &:focus {
      outline: none !important;
      border: 1px solid #69BAC1;
    }
  }
  p {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #111729;
  }
`;

export const WrapHelpButton = styled.div`
  margin-top: 25px;
  button {
    padding: 10px 24px;
    background: #69BAC1;
    border-radius: 24px;
    border: 1px solid transparent;
    color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const TextBottomHelp = styled.div`
  width: 93%;
  margin-top: 40px;
  padding: 32px 40px;
  background: #F3FAF9;
  border-radius: 12px;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 30px;
  color: #111729;
  @media screen and (max-width: 900px) {
    width: inherit;
    margin-bottom: 40px;
  }
`;
export const OnMap = styled.div`
  a {
    margin-bottom: 0;
    display: flex;
    z-index: 99;
    width: 112px;
    &:hover {
      cursor: pointer !important;
    }
    @media screen and (max-width: 900px) {
      justify-content: center;
    }
    p {
      z-index: -1;
      margin-bottom: 0;
    }
    img {
      z-index: -1;
      height: 100%;
      margin-right: 10px;
    }
  }
`;

export const WrapEmail = styled.div`
  margin-top: 15px;
  input {
    width: calc(100% - 32px);
    @media screen and (max-width: 900px) {
      width: calc(100% - 46px);
    }
    border: 1px solid #CDD5E0;
    border-radius: 6px;
    padding: 14px 23px;
    &:focus {
      outline: none !important;
      border: 1px solid #69BAC1;
    }
  }
  p {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #111729;
  }
`;
