import React from "react";
import {Inner, Wrapper, WrapContent, WrapSocial} from "./iurist-styled";
import iur from "./iur.jpeg";

export const Iurist = () => {
  return (
    <Wrapper>
      <Inner>
        <WrapContent>

          <h2>Козубовский Юрий - наш юрист</h2>

          <WrapSocial>
            <a href="mailto:jurist@domdliamamy.ru" className="cont-email">jurist@domdliamamy.ru</a>
          </WrapSocial>

          <img src={iur} alt="Дом для мамы"/>

          <p>Имеет большой опыт работы в сфере юриспруденции, адвокатской практике. Доброжелательный, сдержанный, ответственный, что немаловажно в нашей работе.</p>
          <p className="green-block">«Главный закон, который необходимо соблюдать, — закон Любви»</p>
          <p>Несколько лет назад Юрий Николаевич начал помогать Центру, как волонтер, затем вошел в штат сотрудников, когда «Дом для мамы», в самый разгар пандемии, остался без юриста. С тех пор он консультирует и помогает всем подопечным.</p>
          <p className="title-about-pages">Какие задачи юрист решает в Центре:</p>
          <ul>
            <li>
              консультации по жилищным вопросам (право на жилплощадь, регистрация и др.)
            </li>
            <li>
              консультации по семейному законодательству (насилие в семье, лишение родительских прав, взыскание алиментов, порядок общения с ребенком, и др.)
            </li>
            <li>
              помощь в составлении юридических документов
            </li>
            <li>
              представление интересов в суде
            </li>
          </ul>
          <p className="pink-block">457 человек получили юридическую консультацию в Центре за последний год</p>
          <p>
            Если вам необходима консультация администратора приюта, вы можете обратиться в наш Центр по тел.<a href="tel:+79652629878" className="phon-black">+7 (965) 262-98-78</a> или написать на адрес электронной почты <a href="mailto:cat@domdliamamy.ru" className="cont-emil">cat@domdliamamy.ru</a>
          </p>
        </WrapContent>
      </Inner>
    </Wrapper>
  )
};
