import React, { useState } from "react";
import YouTube from 'react-youtube';
import {
  Wrapper,
  Inner,
  VideoPreview,
  InnerVideo,
  WrapButton,
  ArticlesPreview,
  FirstArticle,
  OtherArticle,
  ButtonOther
} from "./previewPage-styled";
import { Link } from "react-router-dom";


import wom from "../image/logo-woman-ru.jpg";
import sputnik from "../image/sputnik.png";
import mklogo from "../image/mk-logo.svg";
import foma from "../image/foma.png";
import pressa from "../image/pressa.svg";
import moskva from "../image/moskva24.svg";
import ui from "../image/ui.png";
import agency from "../image/agency.png"


export const PreviewPage = () => {
  const [show, setShow] = useState(false);
  const opts = {
    height: '314',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      // autoplay: 1,
    },
  };

  return (
    <Wrapper>
      <Inner>
        <VideoPreview>
          <h2>Мы в СМИ</h2>
          <p>Видео</p>
          <InnerVideo>
            <div className="vid-prev">
              <iframe title="president" src="https://vk.com/video_ext.php?oid=-93739533&id=456240002&hash=360f0bd15ae41b34&hd=2" width="100%"
                      height="314px" frameBorder="0" allowFullScreen/>
              <h5>Мария Студеникина заручилась поддержкой Президента РФ: </h5>
            </div>
            <div className="vid-prev">
              <YouTube videoId='jiW0CDcR0bA' opts={opts} />
              <h5>Патриарх Кирилл посетил «Дом для мамы» в Москве: </h5>
            </div>
          </InnerVideo>
          <InnerVideo>
            <div>
              <iframe title="1tv" width="100%" height="314" src="//www.1tv.ru/embed/212638:12" allow="encrypted-media" frameBorder="0"
                      allowFullScreen />
              <h5>Первый канал в гостях у Домика: </h5>
            </div>
            <div>
              <YouTube videoId='b5spL1RnjDc' opts={opts} />
              <h5>Рита Дакота в гостях у "Дома для мамы":</h5>
            </div>
          </InnerVideo>
        </VideoPreview>
        <WrapButton>
          <Link to="/video/1">Посмотреть все</Link>
        </WrapButton>
        <ArticlesPreview>
          <h2>Статьи</h2>
            <FirstArticle>
              {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href="https://www.woman.ru/psycho/dom-dlya-mamy-kuda-poiti-beremennoi-ili-s-detmi-na-rukakh-esli-nekuda-idti-id649173/" rel="noopener noreferrer" target="_blank">
                <img src={wom} alt="Дома для мамы"/>
                <h6>«Дом для мамы»: куда пойти беременной или с детьми на руках, если некуда идти</h6>
              </a>
              {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href="https://www.asi.org.ru/news/2021/10/01/razmer-materinskogo-kapitala-vyrastet-do-544-301-rublya/" target="_blank">
                <img src={agency} alt="Дома для мамы" className='sv' />
                <h6>Размер материнского капитала вырастет до 544 301 рубля</h6>
              </a>
              {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href="https://radiosputnik.ria.ru/20191125/1561580500.html" rel="noopener noreferrer" target="_blank">
                <img src={sputnik} alt="Дома для мамы"/>
                <h6>Вырваться из лап тирана: эксперты о спасении женщин от насилия</h6>
              </a>
            </FirstArticle>
          {!show && (<ButtonOther onClick={() => setShow(true)}><span>Показать еще</span></ButtonOther>) }
          {show && (
            <OtherArticle>
              {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href="https://www.mk.ru/social/2019/06/23/spasatelnyy-krug-otchayavshimsya-mamam-gde-protyagivayut-ruku-pomoshhi-obezdolennym-zhenshhinam.html" rel="noopener noreferrer" target="_blank">
                <img src={mklogo} alt="Дома для мамы" className='mk' />
                <h6>Мы в Mk.ru</h6>
              </a>
              {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href="https://foma.ru/na-ulice-podoshla-zhenshhina-i-skazala-mne-vsego-tri-slova-psiholog-o-trogatelnom-momente-v-ego-zhizni.html" rel="noopener noreferrer" target="_blank">
                <img src={foma} alt="Дома для мамы" className='fm'/>
                <h6>Мы в журналe Фома</h6>
              </a>
              {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href="https://svpressa.ru/blogs/article/240324/" rel="noopener noreferrer" target="_blank">
                <img src={pressa} alt="Дома для мамы" className='sv' />
                <h6>Мы в Свободная пресса</h6>
              </a>
              {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href="https://www.m24.ru/shows1/94/299311" rel="noopener noreferrer" target="_blank">
                <img src={moskva} alt="Дома для мамы" className='sv' />
                <h6>Мы в Москва24</h6>
              </a>
              {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href="https://www.u-tv.ru/a-kogda-nado/" rel="noopener noreferrer" target="_blank">
                <img src={ui} alt="Дома для мамы" className='sv' />
                <h6>Мы на канале Ю</h6>
              </a>
              {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href="/" style={{pointerEvents: "none"}}> </a>
            </OtherArticle>
          )}
        </ArticlesPreview>
      </Inner>
    </Wrapper>
  )
};
