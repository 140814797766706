import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 48px 0;
  margin-bottom: 40px;
  @media screen and (max-width: 900px) {
    margin-bottom: 80px;
  }
  h2  {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 64px;
    color: #111729;
    padding-bottom: 40px;
    max-width: 300px;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
      font-size: 40px;
      line-height: 48px;
    }
  }
  .list-row .header-row {
    text-align: left;
  }
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
`;

export const WrapYears = styled.div`

`;

export const ItemYears = styled.div`
  padding-left: 57px;
  position: relative;
  img {
    position: absolute;
    left: 0;
  }
  margin-bottom: 24px;
`;

export const Title = styled.div`
  margin: 40px 0 30px 0;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  color: #111729;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #69BAC1;
  &:hover {
    cursor: pointer;
  }
`;

export const Size = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #677489;
`;

export const WrapControlTabs = styled.div`
  padding-left: 20px;
  border-left: 1px solid #69BAC1;
  position: absolute;
  right: 15px;
  top: 0;
  @media screen and (max-width: 900px) {
    position: relative;
  }
`;
export const SwitchButtons = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  &.active {
    color: #69BAC1;
  }
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    cursor: pointer;
  }
`;


