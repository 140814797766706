import React from "react";
import { Link } from "react-router-dom";
import {
  Wrapper,
  Inner,
  WrapInner,
  WrapPingNumber,
  InnerDivs,
  WrapInnerDivs,
  NeedHelpArea
} from "./priutPage-styled";

import priutimg from "./priutimg.png";


export const PriutPage = () => {
  return (
    <Wrapper>
      <Inner>
        <WrapInner>
          <h2>Приют</h2>
          <p>
            Приют для мам с детьми и беременных женщин открыт 7 февраля 2012 года, завершив широту спектра, оказываемой Центром помощи.
          </p>
          <img src={priutimg} alt="Дом для мамы"/>
          <p>К нам обращаются женщины, которым некуда пойти, практически негде и не на что жить. Причины могут быть разные: лишились крыши над головой, подверглись домашнему насилию, остались без поддержки близкого окружения и т.д.</p>
          <p>Попадая в приют, женщина получает такую поддержку (материальную, психологическую, юридическую), которая помогает встать на ноги и выйти из кризисной ситуации.</p>
          <p className="proiectTitle">За 12 лет работы</p>
          <WrapPingNumber>
            <WrapInnerDivs>
              <InnerDivs>
                <span>16000+</span>
                <p>женщин получили социально-психологическую помощь</p>
              </InnerDivs>
              <InnerDivs>
                <span>800+</span>
                <p>женщин с детьми возвратились на родину (получили билеты для проезда по России и странам СНГ)</p>
              </InnerDivs>
            </WrapInnerDivs>
            <WrapInnerDivs>
              <InnerDivs>
                <span>217</span>
                <p>детей родилось во время нахождения мам в Кризисном центре.</p>
              </InnerDivs>
              <InnerDivs>
                <span>1400+</span>
                <p>мамы и ребёнка проживали в Центре «Дом для мамы»</p>
              </InnerDivs>
            </WrapInnerDivs>
          </WrapPingNumber>
            <p>С каждой мамой, поступающей в приют, заключается договор, в котором прописаны основные принципы и правила взаимодействия. В процессе проживания в приюте специалисты помогают женщине не только решить свои текущие проблемы, но и освоить новую профессию.</p>
            <p><span className="priutSpecialText">50 кружков и обучающих курсов</span> организовано Центром. Около 100 мам прошли обучение на профессиональных курсах повышения квалификации с получением диплома государственного образца.</p>
            <p>Двери «Дома для мамы» открыты для всех, кому больше некуда пойти. Мы помогаем женщинам бесплатно, независимо от их национальности и вероисповедания, наличия документов и регистрации.</p>
          <NeedHelpArea>
            <p>Помни, что ты не одна. Здесь тебе помогут!</p>
            <Link to="/nugnapomosi">
              Нужна помощь
            </Link>
          </NeedHelpArea>
        </WrapInner>
      </Inner>
    </Wrapper>
  )
};
