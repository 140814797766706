import React from 'react';
import { Link } from "react-router-dom";
import { Wrapper,
  Inner,
  LinksSocial,
  LinksPages,
  Social,
  ContactsDetails,
  Address,
  Phones,
  Emails,
  FactureData,
  CopyRight,
  WrapData,
  DataOne,
  DataTwo,
  DataThree,
  BottomText
} from "./footer-styled";
import vk from "./vk.svg";
import tg from "./tg.svg";

export const Footer = () => {
  const yearNow = new Date().getFullYear();
  return (
    <Wrapper>
      <Inner>

        <LinksSocial>
          <LinksPages>
            <Link to="/onas">О нас </Link>
            {/*<Link to="/proiecti">Проекты</Link>*/}
            <Link to="/nugnapomosi">Нужна помощь</Link>
            <Link to="/contacti">Контакты</Link>
          </LinksPages>
           <Social className="showMobile">
             {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
             <a target="_blank" rel="noopener noreferrer" href="https://vk.com/domdliamamy" className="vk-footer">
              <img src={vk} alt="Дом для мамы"/>
             </a>
             <a href="https://t.me/domdliamamy" target="_blank" rel="noopener noreferrer">
               <img src={tg} alt="Дом для мамы"/>
             </a>
           </Social>
        </LinksSocial>
         <ContactsDetails>
            <Address>
              <div className="firstLineOffice">Офис: г. Москва,Николоямская улица, д. 52 стр. 2</div>
              <div>Юридический адрес: 109004, г. Москва, ул. Станиславского, д. 22, стр.1</div>
            </Address>
             <Phones>
               {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
               <a href="tel:+79652629878" rel="noopener noreferrer">+7 965 262-98-78</a><br/>
               <span>круглосуточно</span><br/>
               {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
               <a href="tel:+79661367557" className="cont-black mb12">+7 (966) 136-75-57</a>
             </Phones>
             <Emails>
               <a href="mailto:info@domdliamamy.ru">info@domdliamamy.ru</a>
             </Emails>
             <Social className="showDesktop">
               <a target="_blank" rel="noopener noreferrer" href="https://vk.com/domdliamamy" className="vk-footer">
                 <img src={vk} alt="Дом для мамы"/>
               </a>
               <a href="https://t.me/domdliamamy" target="_blank" rel="noopener noreferrer">
                 <img src={tg} alt="Дом для мамы"/>
               </a>
             </Social>
         </ContactsDetails>

        <FactureData>
          <CopyRight>
            ©  2019 - {yearNow} АНО «Дом для мамы»
          </CopyRight>
          <WrapData>
            <DataOne>
              Автономная некоммерческая благотворительная организация «Центр помощи беременным женщинам и матерям с детьми, находящимся в трудной жизненной ситуации «Дом для мамы»
              <br/>
              <ul>
                <li>ОГРН	1197700009396</li>
                <li>ИНН	9709050791</li>
                <li>КПП	770901001</li>
              </ul>
            </DataOne>
            <DataTwo>
              Расчетный счет 40703810338000014560 в Дополнительном офисе № 01775 Московского банка ПАО «Сбербанк»
            </DataTwo>
            <DataThree>
              Корр. счет<br/>30101810400000000225
              БИК<br/>044525225
            </DataThree>
          </WrapData>
        </FactureData>
        <BottomText>
          Учредитель: Православная религиозная организация Отдел по церковной благотворительности и социальному служению Русской Православной Церкви (Московского Патриархата)
        </BottomText>

      </Inner>
    </Wrapper>
  )
}
