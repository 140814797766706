import React from "react";
import {Inner, Wrapper, WrapRoomImage} from "../rooms-styled";
import room from "../rooms-images/09.jpeg";
export const NineRoom = () => {
  return (
    <Wrapper>
      <Inner>
       <h2>Комната отремонтирована на средства, собранные Оксаной Самойловой</h2>
        <WrapRoomImage>
          <img src={room} alt="Дом для мамы"/>
        </WrapRoomImage>

        <br/>
        <br/>
        <p className="norm-text">
          Оксана Самойлова - счастливая мама четверых детей, успешная бизнес-вумен и №1 Instagram - блогер в РФ
          <br/>
          <br/>
          “Благотворительность - неотъемлемая часть моей жизни. Я не делаю добрые дела напоказ, но никогда не прохожу мимо нуждающихся в помощи.
          <br/>
          <br/>
          Я реализовала социальный проект, направленный на адаптацию и реализацию женщин в современном онлайн-бизнесе. Одна из подопечных Домика, Наташа, стала участницей моего проекта и изменила свою жизнь в лучшую сторону.
          <br/>
          <br/>
          Мне близки мотивы Домика - помочь женщинам в трудных жизненных ситуациях, и дать не только надежду, но и реальные инструменты для построения новой, лучшей жизни.”
        </p>
      </Inner>
    </Wrapper>
  )
}
