import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 48px 0;
  h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 64px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
      font-size: 30px;
      line-height: 38px;
    }
  }
  h6 {
    text-transform: uppercase;
    color: #677489;
    font-family: Mulish;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 16px;
  }
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
`;
