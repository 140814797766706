import React from 'react';
import { Otcheti } from "../components/otcheti";

export const Reports = () => {
  return (
    <>
      <Otcheti />
    </>
  );
};
