import React from "react";
import {Inner, Wrapper, WrapRoomImage} from "../rooms-styled";
import room from "../rooms-images/16.jpeg";
// import insta from "../instagram.svg";
export const SixTeenRoom = () => {
  return (
    <Wrapper>
      <Inner>
        <h2>Комната отремонтирована на средства, собранные Александром Ч.</h2>
        <WrapRoomImage>
          <img src={room} alt="Дом для мамы"/>
        </WrapRoomImage>
        <br/>
        <br/>
        <p className="norm-text">
          Александр узнал о нашем Центре на портале Pravoslavie.ru и захотел поддержать ремонт приюта.
          <br/>
          <br/>
          Александр - скромный и удивительно отзывчивый человек! Спасибо за вашу помощь <span style={{color: "#69BAC1", transform: "translate(6px, 1px)", display: "inline-block"}}>❤</span>

         </p>
      </Inner>
    </Wrapper>
  )
}
