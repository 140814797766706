import React, { useEffect, useState } from "react";
// https://www.npmjs.com/package/react-loading-skeleton
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  useParams
} from "react-router-dom";
import Axios from "axios";

import {
  Inner,
  Wrapper,
  WrapNews
} from "./styled-newsViewItem";

export const NewsViewItem = () => {
  const { id } = useParams();
  const [newsItem, setNewsItem] = useState();
  const [hidePreloader, setHidePreloader] = useState(true);

  useEffect(() => {
    if (id !== null) {
      Axios.get(`https://domdliamamy.ru/donation/wp-json/wp/v2/posts/?include=${id}`, { }).then(response => {
        setNewsItem(response.data[0]);
        setHidePreloader(false);
      });
    }
  }, [id]);

  return (
    <Wrapper>
      <Inner>
        <WrapNews>
          { hidePreloader ? <h2><Skeleton /></h2> : <h2 dangerouslySetInnerHTML={{ __html:newsItem?.title?.rendered }} /> }
          { hidePreloader ? <div><Skeleton count={25} /></div> : <div dangerouslySetInnerHTML={{ __html:newsItem?.content?.rendered }} /> }
        </WrapNews>
      </Inner>
    </Wrapper>
  )
};
