import React from "react";
import {
  Inner,
  Wrapper
} from "./video-youtube-three-styled";
import {Link} from "react-router-dom";
import {Pagination, ThreeItemsVideo, VideoView, WrapButton} from "../VideoYoutube/video-youtube-styled";
import arrow from "../VideoYoutube/arrow.png";
import YouTube from "react-youtube";

export const VideoYoutubeThree = () => {
  const optsTwo = {
    height: '300',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      // autoplay: 1,
    },
  };
  const videoArray = [
    {vid: 'SlSj2QxYsGY', text: "Сотни женщин нашли приют в «домах для мам» - МИР24"}, {vid: 'lQa1DaC78k0', text: "Поездка Tribal Dream в Дом для Мамы. 25.06.17"},
    {vid: 'nd0yuFVF7jk', text: "Практическая работа по предотвращению абортов"}, {vid: 'PeKdVH6LC3c', text: "Основатели. Выпуск 51. Дом для мамы"},
    {vid: 'YNEF9MwCfNU', text: "Фонд святителя Василия Великого"}
  ]
  return (
    <Wrapper>
      <Inner>
        <VideoView>
          <h2>Мы в СМИ</h2>
          <WrapButton>
            <img src={arrow} alt="ДомДляМамы"/>
            <Link to="/mivsmi">вернуться</Link>
          </WrapButton>
          <p className="v-video">Видео</p>
          <ThreeItemsVideo>
            {videoArray.map((value, index) => (
              <div className="videoArrayOne" key={index}>
                <YouTube videoId={value.vid} opts={optsTwo} />
                <p>{value.text}</p>
              </div>
            ))}
            <div className="videoArrayOne" />
          </ThreeItemsVideo>
        </VideoView>

        <Pagination>
          <div>
            <Link to='/video/1'>
              1
            </Link>
          </div>
          <div>
            <Link to='/video/2'>
              2
            </Link>
          </div>
          <div>
            <Link to='/video/3' className="inactive-pagination">
              3
            </Link>
          </div>
        </Pagination>
      </Inner>
    </Wrapper>
  )
}
