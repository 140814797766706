import React from "react";
import {Inner, Wrapper, WrapContent, WrapSocial} from "./buhgalter-styled";
import bugh from "./bugh.jpeg";

export const Buhgalter = () => {
  return (
    <Wrapper>
      <Inner>
        <WrapContent>

          <h2>Петрашкина Ольга - наш главный бухгалтер</h2>

          <WrapSocial>
            <a href="mailto:buh@domdliamamy.ru" className="cont-email">buh@domdliamamy.ru</a>
          </WrapSocial>

          <img src={bugh} alt="Дом для мамы"/>

          <p>Имеет высшее экономическое образование (Ульяновская государственная сельскохозяйственная академия) и опыт работы бухгалтером 37 лет.</p>
          <p>Ответственна, педантична, внимательна к работе.</p>
          <p className="green-block">«Бухгалтер должен обладать гибкостью мышления, любознательностью, желанием развиваться. Работа с цифрами требует внимательности к деталям, аккуратности, умения фокусироваться на поставленной задаче»</p>
          <p>В «Доме для мамы» с 2021 года. Ведет учет всех финансов Центра.</p>

          <p className="title-about-pages">Какие задачи главный бухгалтер решает в Центре:</p>
          <ul>
            <li>
              ведение бухгалтерского учета в полном объеме
            </li>
            <li>
              контроль финансовых вопросов
            </li>
            <li>
              подготовка отчетности
            </li>
          </ul>

          <p>
            Если вам необходима консультация администратора приюта, вы можете обратиться в наш Центр по тел.<a href="tel:+79652629878" className="phon-black">+7 (965) 262-98-78</a> или написать на адрес электронной почты <a href="mailto:cat@domdliamamy.ru" className="cont-emil">cat@domdliamamy.ru</a>
          </p>
        </WrapContent>
      </Inner>
    </Wrapper>
  )
};
