import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 48px 0;
  margin-bottom: 160px;
  @media screen and (max-width: 900px) {
    margin-bottom: 80px;
  }
  h2  {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 64px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
      font-size: 40px;
      line-height: 48px;
    }
  }
  input, textarea {
    &:hover {
      cursor: auto;
    }
  }
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const VideoPreview =styled.div`
  p {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #111729;
    margin: 48px 0 40px 0;
    @media screen and (max-width: 900px) {
      font-size: 20px;
      line-height: 28px;
      margin: 28px 0 20px 0;
    }
  }
`;

export const InnerVideo = styled.div`
  display: flex;
  justify-content: space-between;
  .vid-prev {
    max-width: 559px;
  }
  iframe {
    width: 559px;
    @media screen and (max-width: 1250px) {
      max-width: inherit;
      width: 90%;
    }
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .vid-prev {
      max-width: 100%;
      text-align: center;
    }
    iframe{
      width: 90%;
    }
  }
  h5 {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    color: #111729;
    margin-bottom: 60px;
  }
`;

export const WrapButton =styled.div`
  text-align: center;
  a {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #356D74;
    background: #D7F8F5;
    border-radius: 24px;
    padding: 7px 20px 9px;
    &:hover {
      background: #7BCCD1;
      color: #fff;
    }
  }
`;

export const ArticlesPreview = styled.div`
  padding-top: 80px;
  h6 {
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    color: #111729;
    max-width: 360px;
  }
  a:hover {
    cursor: pointer;
  }
  img {
    max-width: 200px;
    height: 60px;
    filter: grayscale(1);
  }
  a:hover img {
    filter: grayscale(0);
  }
  a:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 900px) {
    a {
      margin-bottom: 40px;
    }
    img {
      height: 40px;
      margin-bottom: 20px;
    }
  }
`;

export const FirstArticle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  @media screen and (max-width: 900px) {
    flex-wrap: inherit;
    justify-content: center;
    flex-direction: column;
    a {
      width: 100%;
    }
  }
`;
export const OtherArticle = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 40px;
  a {
    width: 33%;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 900px) {
    flex-wrap: inherit;
    justify-content: center;
    flex-direction: column;
    a {
      width: 100%;
    }
  }
`;

export const ButtonOther = styled.div`
  text-align: center;
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #356D74;
    background: #D7F8F5;
    border-radius: 24px;
    padding: 7px 20px 9px;
    &:hover {
      background: #7BCCD1;
      color: #fff;
      cursor: pointer;
    }
  }
`;
