import React from 'react';
import {FirstRoom} from "../components/remont/firstRoom";

export const FirstRoomPage = () => {
  return (
    <>
      <FirstRoom />
    </>
  );
};
