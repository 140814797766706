import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 60px 0 48px 0;
  position: relative;
  z-index: 100;
  background: #fff;
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
`;

export const WrapLogo = styled.div`
  max-width: 133px;
  display: flex;
  align-items: center;
  img {
    max-width: 100%;
    &:hover {
      cursor: pointer;
    }
  }
  @media screen and (max-width: 1100px) {
    max-width: 100px;
  }
`;

export const WrapNeedHelp = styled.div`
  margin-right: 12px;
  @media screen and (max-width: 1100px) {
    margin-right: 6px;
  }
  a {
    color: #fff;
    padding: 9px 20px;
    background: #69BAC1;
    border-radius: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    &:hover {
      background: #7BCCD1;
    }
    &:active {
      background: #69BAC1;
    }
  }
`;

export const WrapSupport = styled.div`
  position: relative;
  a {
    color: #fff;
    padding: 9px 20px 9px 44px;
    background: #DA5697;
    border-radius: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    &:hover {
      background: #E47AB3;
    }
    &:active {
      background: #C93C76;
    }
  }
`;

export const HeartWrap = styled.div`
  position: absolute;
  left: 22px;
  top: 2.5px;
  width: 16.67px;
  height: 15.4px;
`;

export const WrapPhone = styled.div`
  position: absolute;
  top: -34px;
  right: 0;
  a {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #000;
    padding: 0;
    background: none;
    border-radius: 0;
    &:hover {
      background: none;
    }
    &:active {
      background: none;
    }
  }
`;

export const WrapPages = styled.div``;
export const TopPart = styled.div`
  padding-bottom: 15px;
  a {
    color: #677489;
    margin-right: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    text-transform: uppercase;
    border-bottom: 2px solid transparent;
    &:hover {
      border-bottom: 2px solid #69BAC1
    }
  }
`;
export const BottomPart = styled.div`
  padding-top: 15px;
  border-top: 1px solid #E3E8EF;
  a {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #000;
    margin-right: 24px;
    border-bottom: 2px solid transparent;
    &:hover {
      border-bottom: 2px solid #69BAC1
    }
    @media screen and (max-width: 1100px) {
      margin-right: 16px;
      font-size: 12px;
    }
    @media screen and (max-width: 950px) {
      margin-right: 11px;
    }
  }
`;

export const WrapProiekti = styled.div`
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #000;
  margin-right: 24px;
  position: relative;
  border-bottom: 2px solid transparent;
  &:hover {
    cursor: pointer;
  }
  &.active {
    border-bottom: 2px solid #69BAC1;
  }
`;

export const WrapRightPart = styled.div`
  display: flex;
  align-items: center;
  width: 328px;
`;

export const WrapperMobile = styled.div`
  
`;

export const WrapFixedMenu = styled.div`
  position: fixed;
  left: 0;
  width: 100%;
  padding: 20px 0;
  background: #fff;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
  z-index: 99;
  top: 0;
  .logoMobile {
    width: 150px;
    height: 26px;
    img {
      width: 100%;
    }
  }
  .top-bottom-part {
    padding-top: 0;
    border-top: none
  }
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const InnerProiecti = styled.div`
  position: absolute;
  top: 21px;
  padding: 34px 24px 8px 24px;
  box-shadow: 0 7px 8px rgb(0 0 0 / 10%);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fff;
  width: 260px;
  a {
    margin-bottom: 24px;
    display: block;
    font-weight: lighter;
  }
`;

export const WrapProiektis = styled.div`
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #000;
  margin-right: 24px;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  &.active {
    border-bottom: 2px solid #69BAC1;
  }
`;
