import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 48px 0;
  margin-bottom: 40px;
  @media screen and (max-width: 900px) {
    margin-bottom: 80px;
  }
  h2  {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 64px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
      font-size: 40px;
      line-height: 48px;
    }
  }
  input, textarea {
    &:hover {
      cursor: auto;
    }
  }
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const WrapNews = styled.div`
  img {
    align-items: center;
    margin: 0 auto;
    display: inherit;
  }
  p {
    width: 100%;
  }
  h2 {
    text-align: center;
    @media screen and (max-width: 900px) {
      font-size: 20px;
      line-height: 25px;
    }
  }
  h5  {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
    }
  }
  .mainAllNews {
    position: relative;
    display: inline-block;
    margin-top: 50px;
    @media screen and (max-width: 900px) {
      margin-top: 10px;
    }
    a {
      background: #D7F8F5;
      border-radius: 24px;
      padding: 7px 54px 9px 20px;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      color: #356D74;
      border: 1px solid #D7F8F5;
      &:hover {
        background: #D7F8F5;
        border: 1px solid #69BAC1;
      }
      &:active {
        background: #B4EEEA;
        border: 1px solid #69BAC1;
      }
    }
    img {
      position: absolute;
      width: 16px;
      height: 15.56px;
      left: 127px;
      top: 4.5px;
    }
  }
`;
