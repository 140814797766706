import React, { useState, useEffect } from "react";
import Slider from "react-slick";

import {
  Wrapper,
  WrapThumbs,
  InnerThumbs,
  InnerLetterThumbs,
  WrapThumbsPink,
  WrapImg
} from "./pisima-styled";

import presidentT from "./thumbs/president-1.png";
import volonterT from "./thumbs/volonteri-1.png";
import miloserdieT from "./thumbs/miloserdie-1.png";
import gbuT from "./thumbs/gbu.png";
import belorusiT from "./thumbs/belorusi-1.png";
import beregineaT from "./thumbs/bereginea-1.png";
import fondT from "./thumbs/fond-1.png";
import yandT from "./thumbs/yandex-1.png";
import pissimoT from "./thumbs/pissimo-1.png";
import moretextT from "./thumbs/moretext-1.png";
import frolovaT from "./thumbs/frolova-1.png";
import text1T from "./thumbs/text-1.png";
import text2T from "./thumbs/text-2.png";
import dashkovaT from "./thumbs/dashkova-1.png";
import dargizni1T from "./thumbs/dargizni1.png";
import dargizni2T from "./thumbs/dargizni2.png";
import blagoT from "./thumbs/blago.png";
import mirvdomT from "./thumbs/mirvdom.png";
import sessionT from "./thumbs/session.png";
import pokrovT from "./thumbs/pokrov.png";
import magnitogT from "./thumbs/magnitogorsk-dom.png";
import pskovT from './thumbs/pskov.png';
import mosvolontT from './thumbs/mosvolont.png';
import dar1T from './thumbs/dar-1.png';
import breansk1T from './thumbs/breansk-1.png';

import president from "./images/president-1.png";
import volonter from "./images/volonteri-1.png";
import miloserdie from "./images/miloserdie-1.png";
import gbu from "./images/gbu.png";
import belorusi from "./images/belorusi-1.png";
import bereginea from "./images/bereginea-1.png";
import fond from "./images/fond-1.png";
import yand from "./images/yandex-1.png";
import pissimo from "./images/pissimo-1.png";
import moretext from "./images/moretext-1.png";
import frolova from "./images/frolova-1.png";
import text1 from "./images/text-1.png";
import text2 from "./images/text-2.png";
import dashkova from "./images/dashkova-1.png";
import dargizni1 from "./images/dargizni1.png";
import dargizni2 from "./images/dargizni2.png";
import blago from "./images/blago.png";
import mirvdom from "./images/mirvdom.png";
import session from "./images/session.png";
import pokrov from "./images/pokrov.png";
import magnitog from "./images/magnitogorsk-dom.png";
import pskov from './images/pskov.png';
import mosvolont from './images/mosvolont.png';
import dar1 from './images/dar-1.png';
import breansk1 from './images/breansk-1.png';

import closeIcon from './close-icon.svg';

export const Pisima = () => {
  // const images = [
  //   {
  //     original: 'https://picsum.photos/id/1018/1000/600/',
  //     thumbnail: 'https://picsum.photos/id/1018/250/150/',
  //   },
  //   {
  //     original: 'https://picsum.photos/id/1015/1000/600/',
  //     thumbnail: 'https://picsum.photos/id/1015/250/150/',
  //   },
  //   {
  //     original: 'https://picsum.photos/id/1019/1000/600/',
  //     thumbnail: 'https://picsum.photos/id/1019/250/150/',
  //   },
  // ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
  };
  const [imageNumber, setImageNumber] = useState(null);
  const setShowImage = (counter) => {
    setImageNumber(counter);
  };

  useEffect(() => {
    if (imageNumber !== null) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [imageNumber]);
  return (
    <>
      {imageNumber === 1 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={president} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 2 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={mosvolont} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 3 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={volonter} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 4 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={miloserdie} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 5 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={gbu} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 6 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={belorusi} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 7 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={bereginea} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 8 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={fond} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 9 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={dargizni1} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 10 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={dargizni2} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 11 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={blago} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 12 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={mirvdom} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 13 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={session} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 14 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={pokrov} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 15 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={magnitog} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 16 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={dar1} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 17 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={pskov} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 18 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={breansk1} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}

      {imageNumber === 19 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={pissimo} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 20 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={moretext} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 21 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={frolova} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 22 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={text1} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 23 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={text2} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 24 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={yand} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}
      {imageNumber === 25 && <WrapImg onClick={() => setImageNumber(null)}><span><img src={dashkova} alt="Дом для мамы"/><img className="closeIconPisima" onClick={() => setImageNumber(null)} src={closeIcon} alt="Дом для мамы"/></span></WrapImg>}

      <Wrapper>
        <InnerThumbs>
          <WrapThumbs onClick={() => setShowImage(1)}>
            <img src={presidentT} alt="Дом для мамы"/>
            <span>Грамота Президента РФ</span>
          </WrapThumbs>

          <WrapThumbs onClick={() => setShowImage(2)}>
            <img src={mosvolontT} alt="Дом для мамы"/>
            <span>Директор ГБУ города Москвы "Мосволонтер"</span>
          </WrapThumbs>

          <WrapThumbs onClick={() => setShowImage(3)}>
            <img src={volonterT} alt="Дом для мамы"/>
            <span>Волонтеры в помощь детям-сиротам</span>
          </WrapThumbs>

          <WrapThumbs onClick={() => setShowImage(4)}>
            <img src={miloserdieT} alt="Дом для мамы"/>
            <span>Хитровщинский дом милосердия</span>
          </WrapThumbs>

          <WrapThumbs onClick={() => setShowImage(5)}>
            <img src={gbuT} alt="Дом для мамы"/>
            <span>ГБУ ТЦСО ВОСТОЧНОЕ Измайлово</span>
          </WrapThumbs>

          <WrapThumbs onClick={() => setShowImage(6)}>
            <img src={belorusiT} alt="Дом для мамы"/>
            <span>Приход свт. Димитрия Ростовского</span>
          </WrapThumbs>

          <WrapThumbs onClick={() => setShowImage(7)}>
            <img src={beregineaT} alt="Дом для мамы"/>
            <span>Филиал "Берегиня"</span>
          </WrapThumbs>

          <WrapThumbs onClick={() => setShowImage(8)}>
            <img src={fondT} alt="Дом для мамы"/>
            <span>Фонд "Виктория"</span>
          </WrapThumbs>

          <WrapThumbs onClick={() => setShowImage(9)}>
            <img src={dargizni1T} alt="Дом для мамы"/>
            <span>Дар жизни</span>
          </WrapThumbs>

          <WrapThumbs onClick={() => setShowImage(10)}>
            <img src={dargizni2T} alt="Дом для мамы"/>
            <span>Дар жизни</span>
          </WrapThumbs>

          <WrapThumbs onClick={() => setShowImage(11)}>
            <img src={blagoT} alt="Дом для мамы"/>
            <span>Епископ Балашовский И Ртищевский</span>
          </WrapThumbs>

          <WrapThumbs onClick={() => setShowImage(12)}>
            <img src={mirvdomT} alt="Дом для мамы"/>
            <span>БФ "Мир в каждый дом"</span>
          </WrapThumbs>

          <WrapThumbs onClick={() => setShowImage(13)}>
            <img src={sessionT} alt="Дом для мамы"/>
            <span>Председатель совета по вопросам попечительства в Кузбассе</span>
          </WrapThumbs>

          <WrapThumbs onClick={() => setShowImage(14)}>
            <img src={pokrovT} alt="Дом для мамы"/>
            <span>Центр гуманитарной помощи "Покров"</span>
          </WrapThumbs>

          <WrapThumbs onClick={() => setShowImage(15)}>
            <img src={magnitogT} alt="Дом для мамы"/>
            <span>Магнитогорский "Дом для мамы"</span>
          </WrapThumbs>

          <WrapThumbs onClick={() => setShowImage(16)}>
            <img src={dar1T} alt="Дом для мамы"/>
            <span>Благотваарительный фонд дар жизни</span>
          </WrapThumbs>

          <WrapThumbs onClick={() => setShowImage(17)}>
            <img src={pskovT} alt="Дом для мамы"/>
            <span>Жительница г. Псков</span>
          </WrapThumbs>

          <WrapThumbs onClick={() => setShowImage(18)}>
            <img src={breansk1T} alt="Дом для мамы"/>
            <span>Уполномоченный по правам ребенка брянской области</span>
          </WrapThumbs>

        </InnerThumbs>

        <InnerLetterThumbs>

          <Slider {...settings}>
            <div>
              <WrapThumbsPink onClick={() => setShowImage(19)}>
                <img src={pissimoT} alt="Дом для мамы"/>
              </WrapThumbsPink>
            </div>
            <div>
              <WrapThumbsPink onClick={() => setShowImage(20)}>
                <img src={moretextT} alt="Дом для мамы"/>
              </WrapThumbsPink>
            </div>
            <div>
              <WrapThumbsPink onClick={() => setShowImage(21)}>
                <img src={frolovaT} alt="Дом для мамы"/>
              </WrapThumbsPink>
            </div>
            <div>
              <WrapThumbsPink onClick={() => setShowImage(22)}>
                <img src={text1T} alt="Дом для мамы"/>
              </WrapThumbsPink>
            </div>
            <div>
              <WrapThumbsPink onClick={() => setShowImage(23)}>
                <img src={text2T} alt="Дом для мамы"/>
              </WrapThumbsPink>
            </div>

            <div>
              <WrapThumbsPink onClick={() => setShowImage(24)}>
                <img src={yandT} alt="Дом для мамы"/>
              </WrapThumbsPink>
            </div>

            <div>
              <WrapThumbsPink onClick={() => setShowImage(25)}>
                <img src={dashkovaT} alt="Дом для мамы"/>
              </WrapThumbsPink>
            </div>

          </Slider>


        </InnerLetterThumbs>
      </Wrapper>
    </>
  )
}

