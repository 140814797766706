import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 48px 0;
  margin-bottom: 160px;
  @media screen and (max-width: 900px) {
    margin-bottom: 80px;
  }
  h2  {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 64px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
      font-size: 40px;
      line-height: 48px;
    }
  }
  input, textarea {
    &:hover {
      cursor: auto;
    }
  }
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const WrapContacts = styled.div`
  a {
   display: block;
  }
 .mb12 {
  margin-bottom: 12px;
 }
  .cont-green {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    color: #69BAC1;
  }
  .cont-black {
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 30px;
    color: #000;
  }
 .cont-email {
   font-style: normal;
   font-weight: 500;
   font-size: 20px;
   line-height: 30px;
   color: #69BAC1;
  margin-bottom: 40px;
  }
 .cont-gray {
   font-weight: 700;
   font-size: 15px;
   line-height: 30px;
   text-transform: uppercase;
   color: #677489;
  }
 .cont-details {
   font-weight: 500;
   font-size: 20px;
   line-height: 30px;
   color: #111729;
   margin-bottom: 20px;
  }
  display: flex;
 @media screen and (max-width: 900px) {
  flex-direction: column;
  text-align: center;
 }
 `;

export const WrapRecvizits = styled.div`
   h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #111729;
    margin-bottom: 40px;
   }
   p {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
   }
 `;

export const LeftPart = styled.div`
 width: 50%;
 @media screen and (max-width: 900px) {
  width: 100%;
 }
 a {
  display: inline-block;
 }
`;
export const LeftSpan = styled.span`
  color: rgb(105, 186, 193);
 padding-left: 61px;
 @media screen and (max-width: 900px) {
  padding-left: 0;
 }
`;
export const RightPart = styled.div`
  width: 50%;
 @media screen and (max-width: 900px) {
  width: 100%;
 }
`;

