import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 48px 0;
  margin-bottom: 160px;
  @media screen and (max-width: 900px) {
    margin-bottom: 80px;
  }
  h2  {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 64px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
      font-size: 40px;
      line-height: 48px;
    }
  }
  input, textarea {
    &:hover {
      cursor: auto;
    }
  }
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const WrapVideo =styled.div`
  aspect-ratio: 16/9;
  @media screen and (max-width: 900px) {
    //height: 300px;
  }
`;

export const TitleVideo = styled.div`
  line-height: 24px;
  text-align: right;
  margin: 20px 2px 20px 0;
  font-weight: 700;
  font-size: 17px;
  padding: 0;
  color: #677489;
`;

export const WrapRukovoditeli = styled.div`
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin-top: 60px;
  @media screen and (max-width: 900px) {
    margin-top: 40px;
    text-align: center;
  }
`;

export const InnerRuk = styled.div`
  position: relative;
  p {
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    text-transform: uppercase;
    padding: 0;
    color: #677489;
    margin: 12px 0;
  }
  h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #111729;
    max-width: 524px;
    margin-bottom: 144px;
    @media screen and (max-width: 900px) {
      margin-bottom: 30px;
    }
  }
  .about-director {
    position: absolute;
    top: 0;
    right: 0;
    @media screen and (max-width: 1150px) {
      width: 50%;
    }
    @media screen and (max-width: 900px) {
      width: 100%;
      position: relative;
      top: inherit;
      right: inherit;
    }
  }
`;

export const WrapButton = styled.div`
  position: relative;
  img {
   position: absolute;
  }
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: #69BAC1;
    padding-left: 30px;
  }
  margin-bottom: 39px;
  @media screen and (max-width: 900px) {
    margin: 30px 0 0 0;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const WrapTeam = styled.div`
.title-wrap-team {
  margin: 80px 0 40px 0;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  color: #111729;
  @media screen and (max-width: 900px) {
    text-align: center;
  }
}
` ;
export const DescrTeam = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #111729;
  max-width: 800px;
  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;

export const WrapPhoto = styled.div`
  margin-top: 40px;
`;

export const WrapRow = styled.div`
  height: 128px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  .name-team {
    max-width: 400px;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    color: #111729;
  }
  .team-function {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: #677489;
    max-width: 360px;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    height: auto;
    text-align: center;
  }
`;

export const HalfPart = styled.div`
  padding: 10px;
  width: 50%;
  position: relative;
  height: 128px;
  cursor: pointer;
  img {
    position: absolute;
    width: 128px;
    left: 10px;
  }
  a {
    z-index: 1;
    position: absolute;
    width: calc(100% - 161px);
    height: 100%;
    display: block;
    padding-left: 161px;
    &:hover {
      box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
      border-radius: 20px;
    }
    @media screen and (max-width: 900px) {
      position: relative;
      padding-left: 0;
      width: 100%;
    }
  }
  @media screen and (max-width: 900px) {
    height: auto;
    padding-left: 0;
    text-align: center;
    margin-bottom: 20px;
    img {
      position: relative;
      left: inherit;
    }
  }
`;

export const WrapAddition = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #111729;
  max-width: 800px;
  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;

export const WrapTargets = styled.div`
  margin-top: 80px;
  h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #111729;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;

export const InnerTargets = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    text-align: left;
  }
`;

export const HalfTargets = styled.div`
  width: 47%;
  ul li {
    margin-left: 30px;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
`;
export const NeedHelpArea = styled.div`
  margin-top: 30px;
  max-width: 760px;
  background: #F3FAF9;
  border-radius: 12px;
  padding: 32px 40px;
  p {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 30px;
    color: #111729;
    margin-bottom: 16px;
  }
  a {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    padding: 7px 20px;
    background: #69BAC1;
    border-radius: 24px;
    &:hover {
      background: #7BCCD1;
    }
    &:active {
      background: #69BAC1;
    }
  }
`;
