import React from "react";
import {
  Wrapper,
  Inner,
  WrapInner,
  WrapGreenNumber,
  WrapDoubleGreen,
  OneDouble
} from "./cgp-styled";

import cgpimage from "./cgpimage.png";


export const CgpPage = () => {
  return (
    <Wrapper>
      <Inner>
        <WrapInner>
          <h2>Гуманитарная помощь</h2>
          <p>
            Центр гуманитарной помощи был открыт 11 марта 2011 года. Впоследствии стал одной из составляющих проекта «Дом для мамы».
          </p>
          <img src={cgpimage} alt="Дом для мамы"/>
          <p>Благотворители "Дома для мамы" жертвуют продукты питания, одежду, обувь, средства гигиены, предметы первой необходимости, мебель для малышей, коляски, бытовую технику, книги и многое другое.</p>
          <WrapGreenNumber>
            <span>73 500+</span>
            <p>женщин и детей получили гуманитарную помощь в виде одежды, детских колясок, кроваток, памперсов, предметов первой необходимости по уходу за детьми, продуктов питания.</p>
          </WrapGreenNumber>
           <WrapDoubleGreen>
             <OneDouble>
               <span>60+</span>
               <p>регионов России получают помощь</p>
             </OneDouble>
             <OneDouble>
               <span>1000 +</span>
               <p>семей получают постоянную поддержку в Москве</p>
             </OneDouble>
           </WrapDoubleGreen>
          <p>Всегда найдутся люди, у которых есть много материальных благ, но мало времени или сложно разобраться, кому эти блага могут помочь. Также в нашем обществе достаточно людей, которым что-то может быть нужно.</p>
          <p>Сотрудники Центра принимают гуманитарную помощь и распределяют ее нуждающимся. Это может быть срочная или постоянная помощь многодетным, малоимущим семьям; мамам и папам, которые одни воспитывают детей (в том числе детей с инвалидностью).</p>
        </WrapInner>
      </Inner>
    </Wrapper>
  )
};
