import { useCallback } from "react";
import useIsomorphicLayoutEffect from "./useIsomorphicLayoutEffect";

const useOutsideClick = (ref, callback) => {
  const handleClick = useCallback(
    (e) => {
      if (ref && ref.current && !ref.current.contains(e.target)) {
        if (typeof callback === "function") {
          callback();
        }
      }
    },
    [ref, callback]
  );

  useIsomorphicLayoutEffect(() => {
    if (typeof document !== "undefined") {
      document.addEventListener("click", handleClick);
    }

    return () => document.removeEventListener("click", handleClick);
  }, [ref, handleClick]);
};

export default useOutsideClick;
