import React from "react";
import {Inner, Wrapper, WrapRoomImage} from "../rooms-styled";
import room from "../rooms-images/04.jpeg";
// import insta from "../instagram.svg";
export const FourRoom = () => {
  return (
    <Wrapper>
      <Inner>
       <h2>Комната отремонтирована на средства, собранные Соней 777</h2>
        <WrapRoomImage>
          <img src={room} alt="Дом для мамы"/>
        </WrapRoomImage>
        {/*<div style={{textAlign: "center"}}>*/}
        {/*  <a href="https://www.instagram.com/_sonya777" target="_blank" rel="noreferrer" style={{width: "80px"}}>*/}
        {/*    <img src={insta} alt="Дом для мамы" style={{width: "80px"}}/>*/}
        {/*  </a>*/}
        {/*</div>*/}
        <br/>
        <br/>
        <p className="bold-sec-text">Соня:</p>
        <br/>
        <br/>

        <p className="norm-text">
          Меня зовут Соня, мне 27. Есть сынок 3 года Микаэл. В ожидание дочки, закончила МГИМО (бакалавр и магистр), часто помогаю людям - от этого мне спокойнее на душе, - в особенности маленьким деткам и нуждающимся. Я очень люблю детей, а они меня)

        <br/>
        <br/>

          В Дом для мамы меня привели. Точнее, в день своего рождения я попросила гостей не дарить мне цветы, а подарить детские товары, которые я потом подарю деткам. И была в поиске в инстаграме, кому подарить. Наткнулась на страницу “Дома для мамы”. Потом увидела, что вы собираете деньги на стиральную машинку - часть денег перевела. Затем захотела поздравить ваших детишек с Новым годом и открыть сбор на ремонт комнаты.
        </p>

      </Inner>
    </Wrapper>
  )
}
