import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 48px 0;
  margin-bottom: 160px;
  @media screen and (max-width: 900px) {
    margin-bottom: 80px;
  }
  h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 64px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
      font-size: 40px;
      line-height: 48px;
    }
  }
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
`;

export const WrapInner = styled.div`
  max-width: 760px;
  @media screen and (max-width: 900px) {
    max-width: 100%;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #111729;
    margin-bottom: 40px;
  }
  img {
    margin-bottom: 40px;
    max-width: 100%;
  }
  .proiectTitle {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #111729;
  }
  h6 {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #111729;
    margin-bottom: 40px;
    @media screen and (max-width: 900px) {
      margin-top: 40px;
    }
  }
`;

export const CentriStraniWrap = styled.div`
  transform: translateY(-40px);
  @media screen and (max-width: 900px) {
    margin: 40px 0 20px 0;
    text-align: center;
    transform: none;
  }
   a {
    color: #fff;
    padding: 9px 20px;
    background: #69BAC1;
    border-radius: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    &:hover {
      background: #7BCCD1;
    }
  }  .cetri-strani {
       color: #fff;
       padding: 9px 20px;
       background: #69BAC1;
       border-radius: 24px;
       font-style: normal;
       font-weight: 700;
       font-size: 15px;
       &:hover {
         background: #7BCCD1;
       }
     }
`;

export const WrapManyColors = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  color: #111729;
  .greenSpan {
    color: rgba(105, 186, 193, 1);
  }
  .pinkSpan {
    color: rgba(218, 86, 151, 1);
  }
`;

export const WrappMapDet = styled.div`
  margin-top: 25px;
  margin-bottom: -50px;
  @media screen and (max-width: 900px) {
    margin-bottom: 0;
  }
  .metod-map {
    width: 100%;
    transform: translateY(-90px);
    @media screen and (max-width: 900px) {
      transform: none;
    }
  }
`;

export const WrapMapArea = styled.div`
  padding-left: 30px;
  display: flex;
`;

export const FirstValueMap = styled.div`
  margin-bottom: 24px;
  margin-right: 100px;
  span {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 56px;
    color: #DA5697;
    display: block;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: -30px;
      top: 30%;
      width: 20px;
      height: 20px;
      border: 1px solid #DA5697;
      border-radius: 50%;
    } 
  }
`;
export const SecondValueMap = styled.div`
  span {
    font-weight: 900;
    font-size: 56px;
    line-height: 56px;
    color: #69BAC1;
    display: block;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: -30px;
      top: 30%;
      width: 20px;
      height: 20px;
      border: 1px solid #69BAC1;
      border-radius: 50%;
    }
  }
`;

export const WrapBook = styled.div`
  height: 300px;
  position: relative;
  margin-bottom: 40px;
  @media screen and (max-width: 650px) {
    height: auto;
    text-align: center;
  }
  img {
    position: absolute;
    @media screen and (max-width: 650px) {
      position: relative;
    }
  }
  p {
    margin: 0;
    padding: 0 0 0 185px;
    @media screen and (max-width: 650px) {
      padding-left: 0;
    }
  }
  .greyTitleBook {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    color: #677489;
    margin-bottom: 8px;
  }
  .mainTitleBook {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    color: #111729;
    margin-bottom: 8px;
  }
  .nameTtleBook {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #111729;
  }
  .descriptionBook {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #677489;
  }
`;
