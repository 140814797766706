import React from 'react';
import { MainPageContent } from "../components/MainPageContent";

export const Main = () => {
  return (
    <div>
      <MainPageContent />
    </div>
  );
};
