import React from "react";
import {
  Inner,
  Wrapper,
  WrapContent,
  WrapSocial
} from "./socialWorker-styled";
import specsocial from "./specsocial.jpeg";

export const SocialWorker = () => {
  return (
    <Wrapper>
      <Inner>
        <WrapContent>

          <h2>Крючкова Ольга - наш специалист по социальной работе</h2>

          <WrapSocial>
            <a href="mailto:social@domdliamamy.ru" className="cont-email">social@domdliamamy.ru</a>
          </WrapSocial>

          <img src={specsocial} alt="Дом для мамы"/>

          <p>Имеет огромный опыт работы в социальной сфере — 25 лет трудилась в государственных учреждениях социальной защиты.</p>
          <p>Умеет по-настоящему слушать и слышать людей.</p>
          <p className="green-block">«Очень хочется, чтобы у женщин было все хорошо и не было проблем, которые приводят к нам.»</p>
          <p>Ольга Викторовна с нами уже год. Пришла на должность дежурной. За короткий срок влилась в команду, будто всегда работала в «Доме для мамы».</p>
          <p className="title-about-pages">Какие задачи специалист по социальной работе решает в Центре:</p>
          <ul>
            <li>
              проводит ежедневные собеседования, погружаясь в детали каждой кризисной ситуации, поиск решений, определение объема помощи
            </li>
            <li>
              помогает составлять и корректировать индивидуальные планы помощи женщинам
            </li>
            <li>
              собирает полную информацию по каждому обращению (документы из органов соцзащиты и опеки, контакты родственников и близких и пр.)
            </li>
            <li>
              консультирует мам по вопросам оформления документов и пособий на ребенка
            </li>
            <li>
              помогает найти жилье
            </li>
            <li>
              участвует в работе консилиума специалистов
            </li>
            <li>
              помогает женщинам в социальной адаптации и профориентации (совместно с психологом)
            </li>
            <li>
              ведет социальный патронаж выпускниц Центра
            </li>
          </ul>
          <p className="pink-block">«Ключевой момент — это когда получается помочь женщине объективно оценить ситуацию. В этом случае проблемы начинают решаться, появляется фундамент, на котором строится дальнейшая работа по выходу из кризисной ситуации. Не всегда это просто. Порой девочки отрицают очевидные вещи, отказываются от предлагаемых шагов к решению проблемы, которые им не нравятся. Это нормально, мы ведь работаем с живыми людьми», — делится Ольга Викторовна.</p>
          <p>
            Если вам необходима консультация администратора приюта, вы можете обратиться в наш Центр по тел.<a href="tel:+79652629878" className="phon-black">+7 (965) 262-98-78</a> или написать на адрес электронной почты <a href="mailto:cat@domdliamamy.ru" className="cont-emil">cat@domdliamamy.ru</a>
          </p>
        </WrapContent>
      </Inner>
    </Wrapper>
  )
};
