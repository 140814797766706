import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 48px 0;
  margin-bottom: 40px;
  @media screen and (max-width: 900px) {
    margin-bottom: 80px;
  }
  h2  {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 64px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
      font-size: 40px;
      line-height: 48px;
    }
  }
  input, textarea {
    &:hover {
      cursor: auto;
    }
  }
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const WrapSocial = styled.div`
  margin: 20px 0 40px 0;
  a {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: rgb(105, 186, 193);
    display: block;
  }
  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;

export const WrapContent = styled.div`
  max-width: 800px;
  img {
    max-width: 100%;
    max-height: 475px;
    border-radius: 12px;
    margin-bottom: 40px;
    @media screen and (max-width: 900px) {
      margin: 0 auto 40px auto;
      display: block;
    }
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #111729;
    margin-bottom: 30px;
    &.title-about-pages {
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      line-height: 30px;
      color: #111729;

    }
    a {
      &.phon-black {
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 30px;
        color: rgb(17, 23, 41);
      }
      &.cont-emil {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: rgb(105, 186, 193);
      }
    }
  }
  .green-block {
    background: #D7F8F5;
    border-radius: 12px;
    padding: 40px 23px;
    @media screen and (max-width: 900px) {
      padding: 30px;
      text-align: center;
    }
  }
  .pink-block {
    background: #F8E8F2;
    border-radius: 12px;
    padding: 40px 23px;
    @media screen and (max-width: 900px) {
      padding: 30px;
      text-align: center;
    }
  }
  ul {
    margin-top: 40px;
    margin-bottom: 40px;
    li {
      margin-bottom: 15px;
      margin-left: 15px;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #111729;
    }
  }
`;