import React from 'react';
import { Partneri } from "../components/partneri";

export const Partners = () => {
  return (
    <>
      <Partneri />
    </>
  );
};
