import React from "react";
import {Inner, Wrapper, WrapRoomImage} from "../rooms-styled";
import climatika from "./climatika.png";
import room from "../rooms-images/17.jpeg";
export const SevenTeenRoom = () => {
  return (
    <Wrapper>
      <Inner>
        <h2>Комната отремонтирована на средства, собранные ООО "НТП Климатика"</h2>
        <WrapRoomImage>
          <img src={room} alt="Дом для мамы"/>
        </WrapRoomImage>
        <div style={{textAlign: "center"}}>
          <a href="http://npt-c.ru/" target="_blank" rel="noopener noreferrer">
            <img src={climatika} alt="Дом для мамы"/>
          </a>
        </div>
        <br/>
        <br/>
        <p className="bold-sec-text">Сергей - руководитель организации ООО "НТП Климатика". Уже несколько лет он активно поддерживает работу “Дома для мамы”. Тема защиты материнства и детства близка Сергею.

        </p>
        <br/>
        <br/>

        <p className="norm-text">
          Как только Сергей узнал о том, что у нас будет проходить ремонт приюта, он сразу согласился помочь!
          <br/>
          <br/>
          Сергей также пожертвовал деньги Центру на покупку швейных машинок. Это значимое приобретение для Домика! Они будут использоваться для профессиональной переподготовки подопечных.
        </p>

      </Inner>
    </Wrapper>
  )
}
