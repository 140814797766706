import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 48px 0;
  margin-bottom: 160px;
  @media screen and (max-width: 900px) {
    margin-bottom: 80px;
  }
  h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 64px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
      font-size: 40px;
      line-height: 48px;
    }
  }
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
`;

export const WrapInner = styled.div`
  max-width: 760px;
  @media screen and (max-width: 900px) {
    max-width: 100%;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #111729;
    margin-bottom: 40px;
  }
  .priutSpecialText {
    color: rgba(218, 86, 151, 1);
    font-weight: 900;
  }
  img {
    margin-bottom: 40px;
    max-width: 100%;
  }
  .proiectTitle {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #111729;
  }
`;

export const WrapPingNumber = styled.div`
  border-radius: 12px;
  padding: 0;
  margin-bottom: 40px;
`;


export const InnerDivs = styled.div`
  padding: 24px 12px;
  display: inline-block;
  width: 46%;
  background: #FBF2F8;
  margin-bottom: 8px;
  border-radius: 12px;
  @media screen and (max-width: 900px) {
    width: 100%;
    max-width: 350px;
  }
  @media screen and (max-width: 480px) {
    max-width: fit-content;
  }
  span {
    display: block;
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 56px;
    color: #DA5697;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: #111729;
    margin-bottom: 0;
  }
`;

export const WrapInnerDivs = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const NeedHelpArea = styled.div`
  margin-top: 30px;
  max-width: 760px;
  background: #F3FAF9;
  border-radius: 12px;
  padding: 32px 40px;
  p {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 30px;
    color: #111729;
    margin-bottom: 16px;
  }
  a {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    padding: 7px 20px;
    background: #69BAC1;
    border-radius: 24px;
    &:hover {
      background: #7BCCD1;
    }
    &:active {
      background: #69BAC1;
    }
  }
`;

