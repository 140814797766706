import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 48px 0;
  margin-bottom: 160px;
  @media screen and (max-width: 900px) {
    margin-bottom: 80px;
  }
  h2  {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #111729;
    margin: 10px 0 24px 0;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
      font-size: 40px;
      line-height: 48px;
    }
  }
  input, textarea {
    &:hover {
      cursor: auto;
    }
  }
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
  .email-studenikina {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #69BAC1;
    display: block;
  }
  .photo-studenikina {
    max-width: 760px;
    margin: 40px 0;
    @media screen and (max-width: 900px) {
      max-width: 100%;
    }
  }
`;

export const WrapSevenSixZero = styled.div`
  max-width: 760px;
`;

export const Graytetxt = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 30px;
  text-transform: uppercase;
  color: #677489;
`;
export const TwelveYears = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #111729;
  margin-bottom: 40px;
`;
export const Istok = styled.div`
  background: #F8E8F2;
  border-radius: 12px;
  padding: 32px 40px;
  margin-bottom: 40px;
`;
export const IstokTitle = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 30px;
  color: #111729;
  ul {
    margin-top: 16px;
  }
  ul li {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #111729;
  }
`;
export const MoreText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #111729;
`;
export const Studies = styled.div`
  h5 {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    color: #111729;
    margin: 40px 0 24px 0;
  }
`;
export const StudiesTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #111729;
`;
export const Delo = styled.div`
  background: #F8E8F2;
  border-radius: 12px;
  padding: 32px 40px;
  margin: 40px 0;
`;
export const DeloTitle = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 30px;
  color: #111729;
  margin-bottom: 16px;
`;
export const DeloText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #111729;
`;

export const NowMaria = styled.div`
  padding-left: 30px;
  h6 {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    color: #111729;
    margin-bottom: 24px;
  }
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #111729;
  ul li {
    padding-bottom: 10px;
  }
`;

export const WrapBooks = styled.div`
  a {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #69BAC1;
    margin-bottom: 12px;
  }
`;

export const Deiatelinosti = styled.div`
  padding: 40px 32px;
  background: #F8E8F2;
  border-radius: 12px;
  ul {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #111729;
  }
  ul li {
    padding-bottom: 12px;
  }
`;
export const TitleD = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 30px;
  color: #111729;
  margin-bottom: 16px;
`;

export const ContactsMaria = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #111729;
  margin-top: 40px;
  .centr-tel {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 30px;
    color: #111729;
  }
  .email-studenikina-bottom {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #69BAC1;
  }
`;

