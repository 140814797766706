import React from "react";
import {Inner, Wrapper, WrapRoomImage} from "../rooms-styled";
import room from "../rooms-images/18.jpeg";
export const NineTeenRoom = () => {
  return (
    <Wrapper>
      <Inner>
        <h2>Комната отремонтирована на средства, собранные Натальей Ремиш</h2>
        <WrapRoomImage>
          <img src={room} alt="Дом для мамы"/>
        </WrapRoomImage>

        <br/>
        <br/>
        <p className="bold-sec-text">Наталья Ремиш:</p>
        <br/>
        <br/>
        <p className="norm-text">
          Спасибо вам за проект!
          <br/>
          <br/>
          Вы помогаете женщинам, пережившим домашнее насилие. Когда у нас, мам, есть дети, когда мама беременна, когда мама без поддержки близких, - ей очень нужна помощь. И вы даёте это. Вы даёте безопасность. Не только физическую, но и эмоциональную. Для начала новой жизни это бесконечно важный элемент. Без него женщина может навсегда остаться в плену тех, кто совершает над ней психологическое или физическое насилие.
        </p>
      </Inner>
    </Wrapper>
  )
}
