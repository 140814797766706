import React from 'react';
import { NewsViewItem } from "../components/newsViewItem";

export const NewsViewItemPage = () => {
  return (
    <>
      <NewsViewItem />
    </>
  );
};
