import React from "react";
import {Inner, Wrapper, WrapRoomImage} from "../rooms-styled";
import room from "../rooms-images/07.jpeg";
export const SevenRoom = () => {
  return (
    <Wrapper>
      <Inner>
       <h2>Комната отремонтирована на средства, собранные Медиториумскими китами </h2>
        <WrapRoomImage>
          <img src={room} alt="Дом для мамы"/>
        </WrapRoomImage>
        <div style={{textAlign: "center"}}>
          {/*<a href="https://novard.ru/" target="_blank" rel="noopener noreferrer">*/}
          {/*  <img src={novard} alt="Дом для мамы"/>*/}
          {/*</a>*/}
        </div>
        <br/>
        <br/>
        <p className="bold-sec-text">Анна:</p>
        <br/>
        <br/>

        <p className="norm-text">
          «Бог дал нам две руки: одна нужна нам, чтобы помогать себе, вторая - чтобы помогать другим».
          <br/>
          <br/>
          Однажды, благодаря чудесной певице Рите Дакоте, я узнала о Домике. А спустя время, благодаря команде Федора Белогая и Медиториуму, я поняла, что, даже не имея денег, я могу попробовать помогать людям. Так родилась идея. Потом - чат, и все неравнодушные люди стали присоединяться и привносить свои идеи. Иногда получается финансово, иногда руками, чаще мы собираем вещи по соседям, друзьям, своим и привозим в Домик.
          <br/>
          <br/>
          Я сама Мама, как и многие, кто помогает из нашего чата. У каждого уникальная судьба и свои трудности, и каждая женщина, девушка чувствует и понимает боль другого. Именно поэтому нам так близка идея фонда, его люди, работающие там и горящие своим делом.
          <br/>
          <br/>
          Нам бы хотелось, чтобы каждая женщина, попавшая в трудную ситуацию, знала, что ей есть, куда обратиться за помощью. Что есть место, где ее поймут и поддержат. Даже незнакомые ей женщины, девушки. В этом наша женская сила - в объединении. Чтобы как можно меньше девушек оставались в беде один на один с собой.
          <br/>
          <br/>
          Пока Мы небольшое сообщество дружных «китов». Но уверена, что это только начало. Принятие участия в ремонте приюта / комнаты для нас было самым невероятным сбором. Мысль о том, что уже одна мамочка будет в тепле и безопасности жить со своим малышом (малышами), грела и греет до сих пор.
          <br/>
          <br/>
          Хочется тому, кто читает это сообщение, сказать: «У тебя все получится, мы в тебя верим и крепко обнимаем всем сердцем!»<span style={{color: "#69BAC1", transform: "translate(6px, 1px)", display: "inline-block"}}>❤</span>
        </p>

      </Inner>
    </Wrapper>
  )
}
