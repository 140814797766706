import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 20px 0 20px 0;
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
`;

export const WrapLogo = styled.div`
  z-index: 2;
  max-width: 133px;
  display: flex;
  align-items: center;
  img {
    max-width: 100%;
  }
  @media screen and (max-width: 1100px) {
    max-width: 100px;
  }
`;

export const WrapRightPart = styled.div`
  
`;

export const StyledBurger = styled.button`
  position: absolute;
  top: 32px;
  right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 3px;
    background: ${({ open }) => open ? '#0D0C1D' : '#7BCCD1'};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;

export const StyledMenu = styled.nav`
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #EFFFFA;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
  height: 100vh;
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 900px) {
      width: 100%;
    }

  a {
    font-size: 16px;
    text-transform: uppercase;
    padding: 0.5rem 0 0.5rem 0;
    margin: 0 15px;
    font-weight: bold;
    letter-spacing: 0.2rem;
    color: #0D0C1D;
    text-decoration: none;
    transition: color 0.3s linear;
    border-bottom: 1px solid #69BAC1;
    @media (max-width: 900px) {
      font-size: 16px;
      text-align: left;
    }

    &:hover {
      color: #343078;
    }
  }
`;
export const WrapHeart = styled.div`
  z-index: 2;
  position: absolute;
  right: 75px;
  top: 20px;
  a {
    color: #fff;
    padding: 7px 20px 7px 44px;
    background: #DA5697;
    border-radius: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
  }
  img {
    position: absolute;
    width: 17.67px;
    height: 16.4px;
    left: 20px;
    top: 3px;
  }
`;

export const WrapMobPoddergati = styled.div`
  position: absolute;
  right: 75px;
  top: 60px;
  width: 160px;
  display: inherit;
  z-index: 2;
  a {
    color: #fff;
    padding: 7px 22px;
    background: #69BAC1;
    border-radius: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    &:hover {
      background: #7BCCD1;
    }
    &:active {
      background: #69BAC1;
    }
  }
`;

export const WrapTwoColumns = styled.div`
  display: flex;
  flex-wrap: wrap;
  a {
    width: 42%;
  }
`;
