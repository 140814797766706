import React from "react";
import {Inner, Wrapper, WrapRoomImage} from "../rooms-styled";
import room from "../rooms-images/13.jpeg";
// import insta from "../instagram.svg";
export const ThirteenRoom = () => {
  return (
    <Wrapper>
      <Inner>
        <h2>Комната отремонтирована на средства, собранные Беллуян Кристиной</h2>
        <WrapRoomImage>
          <img src={room} alt="Дом для мамы"/>
        </WrapRoomImage>

        <p className="bold-sec-text">Беллуян Кристина:</p>
        <br/>
        <br/>
        <p className="norm-text">
          Меня зовут Кристина, мне 21 год. Окончила МГИМО, пока не работаю - занимаюсь воспитанием своего малыша)

          <br/>
          <br/>
          Я считаю, что всегда надо помогать, если есть возможность. Стараюсь помогать регулярно)
          <br/>
          <br/>
          Я увидела у знакомой, что она привезла на Новый Год игрушки детишкам и заинтересовалась «Домом для мамы». Связалась с менеджером, поняла, какой колоссальный труд это всё и какие же вы молодцы, что помогаете людям, оказавшимся в таких непростых ситуациях, и сама тоже решила помочь вам!
          <br/>
          <br/>
          Очень рада, что есть такие люди, как Вы, которые протягивают руки помощи в трудный период жизни🙏🏽
        </p>
      </Inner>
    </Wrapper>
  )
}
