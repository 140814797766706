import React from 'react';
import { PreviewPage } from "../components/smiContent/previewPage";

export const SMI = () => {
  return (
    <div>
      <PreviewPage />
    </div>
  );
};
