import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Wrapper,
  Inner,
  WrapLogo,
  WrapNeedHelp,
  WrapSupport,
  HeartWrap,
  WrapPhone,
  WrapPages,
  TopPart,
  BottomPart,
  WrapRightPart,
  WrapperMobile,
  WrapFixedMenu,
  WrapProiekti,
  WrapProiektis,
  InnerProiecti,
} from "./menu-styled";
import logo from "./logo.svg";
import logoFixed from "./logo-fixed.png";
import heart from "./heart.svg";
import { MobileMenu } from "./MobileMenu";
import useOutsideClick from "../../helpers/outSideClick";

export const Menu = () => {
  const [showProiecti, setShowProiecti] = useState(false);
  const [showProiectiFix, setShowProiectiFix] = useState(false);
  const wrapperRef = useRef(null);
  const wrapperRefFixed = useRef(null);
  const { pathname } = useLocation();

  useOutsideClick(wrapperRef, () => setShowProiecti(false));

  useOutsideClick(wrapperRefFixed, () => setShowProiectiFix(false));

  useEffect(() => {
    setShowProiecti(false);
    setShowProiectiFix(false);
  }, [pathname]);

  return (
    <>
      <Wrapper className="showDesktop">
        <Inner>
          <WrapLogo>
            <a rel="noopener noreferrer" href="/" className="logo">
              <img src={logo} alt="ДомДляМамы" />
            </a>
          </WrapLogo>
          <WrapPages>
            <TopPart>
              <Link to="/otcheti">Отчеты</Link>
              <Link to="/partneri">Партнеры</Link>
              <Link to="/mivsmi">Мы в СМИ</Link>
            </TopPart>

            <BottomPart>
              <Link to="/onas">О нас </Link>
              <WrapProiekti
                ref={wrapperRef}
                className={showProiecti ? "active" : "notActive"}
                onClick={() => setShowProiecti(true)}
                onMouseOver={() => setShowProiecti(true)}
                onMouseOut={() => setShowProiecti(false)}
              >
                Проекты
                {showProiecti && (
                  <>
                    <InnerProiecti>
                      <Link to="/consulitationnii-tsentr">
                        Консультационный центр
                      </Link>
                      <Link to="/priut">Приют</Link>
                      <Link to="/cgp">Гуманитарная помощь</Link>
                      <Link to="/krizisnye-centry">Центры страны</Link>
                      <Link to="/metodicheskaia-pomoshi">
                        Методическая помощь
                      </Link>
                      <Link to="/napravlenia-deeatelinosti">
                        Направления деятельности
                      </Link>
                    </InnerProiecti>
                  </>
                )}
              </WrapProiekti>
              <Link to="/etapi">Этапы</Link>
              <Link to="/novosti">Новости</Link>
              <Link to="/volonteri">Волонтеры</Link>
              <Link to="/contacti">Контакты</Link>
            </BottomPart>
          </WrapPages>

          <WrapRightPart>
            <WrapNeedHelp>
              <Link to="/nugnapomosi">Нужна помощь</Link>
            </WrapNeedHelp>
            <WrapSupport>
              <WrapPhone>
                <a href="tel:+79652629878" style={{ whiteSpace: "nowrap" }}>
                  +7 965 262-98-78 (круглосуточно)
                </a>
              </WrapPhone>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://domdliamamy.ru/donation"
              >
                Поддержать
              </a>
              <HeartWrap>
                <img src={heart} alt="ДомДляМамы" />
              </HeartWrap>
            </WrapSupport>
          </WrapRightPart>
        </Inner>
      </Wrapper>

      <WrapFixedMenu>
        <Inner>
          <a rel="noopener noreferrer" href="/" className="logoMobile">
            <img src={logoFixed} alt="Дом для Мамы" />
          </a>
          <BottomPart className="top-bottom-part">
            <Link to="/onas">О нас </Link>
            <WrapProiektis
              ref={wrapperRefFixed}
              className={showProiectiFix ? "active" : "notActive"}
              onClick={() => setShowProiectiFix(true)}
              onMouseOver={() => setShowProiectiFix(true)}
              onMouseOut={() => setShowProiectiFix(false)}
            >
              Проекты
              {showProiectiFix && (
                <>
                  <InnerProiecti>
                    <Link to="/consulitationnii-tsentr">
                      Консультационный центр
                    </Link>
                    <Link to="/priut">Приют</Link>
                    <Link to="/cgp">Гуманитарная помощь</Link>
                    <Link to="/krizisnye-centry">Центры страны</Link>
                    <Link to="/metodicheskaia-pomoshi">
                      Методическая помощь
                    </Link>
                    <Link to="/napravlenia-deeatelinosti">
                      Направления деятельности
                    </Link>
                  </InnerProiecti>
                </>
              )}
            </WrapProiektis>
            <Link to="/etapi">Этапы</Link>
            <Link to="/novosti">Новости</Link>
            <Link to="/volonteri">Волонтеры</Link>
            <Link to="/contacti">Контакты</Link>
          </BottomPart>

          <WrapRightPart>
            <WrapNeedHelp>
              <Link to="/nugnapomosi">Нужна помощь</Link>
            </WrapNeedHelp>
            <WrapSupport>
              <WrapPhone style={{ display: "none" }}>
                <a href="tel:+79652629878">+7 965 262-98-78</a>
              </WrapPhone>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://domdliamamy.ru/donation"
              >
                Поддержать
              </a>
              <HeartWrap>
                <img src={heart} alt="ДомДляМамы" />
              </HeartWrap>
            </WrapSupport>
          </WrapRightPart>
        </Inner>
      </WrapFixedMenu>

      <WrapperMobile className="showMobile">
        <MobileMenu />
      </WrapperMobile>
    </>
  );
};
