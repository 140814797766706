import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Wrapper,
  Inner,
  WrapHeart,
  FirstSection,
  WrapImg,
  WhichHelp,
  PinkCounter,
  SevenHundred,
  TwoThousand,
  WrapperWhichHelpItems,
  GumHelp,
  InnerGumHelp,
  WrapItemsGum,
  FirsttGumItem,
  SecondGumItem,
  ThirdGumItem,
  OneGumItem,
  TwoGumItem,
  MapArea,
  InnerMapArea,
  WrapMapArea,
  FirstValueMap,
  SecondValueMap,
  OurMission,
  InnerOurMission,
  TextOurMission,
  WeInSmi,
  InnerWeInSmi,
  Rukovoditeli,
  RucRead,
  RukVideo,
  President,
  Patriarh,
  WrapNews,
  InnerNews,
  WrapItemsNewsOne,
  WrapVolunteers,
  InnerVolunteers,
  LeftVolunteers,
  RightVolunteers,
  WrapPartners,
  CentriStraniWrap,
} from "./MainPageContent-styled";
import heart from "./heart.svg";
import first from "./first.png";
import arrow from "./arrow.png";
import flowers from "./flowers.png";
import star from "./star.png";
import map from "./map.svg";
import maria from "./maria.png";
import flatArrow from "./flat-arrow.png";
import leaf from "./leaf.png";
import { SliderPartners } from "../partners-slider";

export const MainPageContent = () => {
  const [news, setNews] = useState([]);
  useEffect(() => {
    fetch("https://domdliamamy.ru/donation/wp-json/wp/v2/posts/?per_page=3")
      .then(function (data) {
        data
          .json()
          .then((value) => {
            setNews(value);
          })
          .catch((error) => {
            console.log("error", error);
          });
      })
      .catch(function (error) {
        return error;
      });
  }, []);
  // console.log('news', news);
  const isMobile = window.innerWidth > 900;
  return (
    <Wrapper>
      <Inner>
        <FirstSection>
          <h1>Дом для мамы</h1>
          <h2>
            Помощь мамам каждый день. Независимо от наличия прописки и
            документов.
          </h2>
          <div className="tenYears">Уже более 12 лет</div>
          <ul>
            <li>помогаем сохранить беременность</li>
            <li>не даем детям попасть в детский дом</li>
            <li>создаем и сохраняем семьи</li>
            <li>всегда становимся на защиту материнства и детства</li>
          </ul>
          <WrapHeart className="onMainPage">
            <img src={heart} alt="ДомДляМамы" />
            {/* Safe link to the own website */
            /* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://domdliamamy.ru/donation"
            >
              Поддержать
            </a>
          </WrapHeart>
          <WrapImg>
            <img src={first} alt="ДомДляМамы" />
          </WrapImg>
        </FirstSection>
        <WhichHelp>
          <h3>Консультационный центр</h3>
          <p>Оказывает помощь:</p>
          <WrapperWhichHelpItems>
            <ul className="specialTransform">
              <li>Психологическую</li>
              <li>Юридическую</li>
              <li>Финансовую</li>
            </ul>
            <ul>
              <li>В прохождении медицинских обследований</li>
              <li>В трудоустройстве</li>
              <li>В получении профессии</li>
            </ul>
            <PinkCounter>
              <SevenHundred>
                <span>16000+</span>
                женщин получили социально-
                <br />
                психологическую и духовную
                <span className="lastBRPink">
                  <br />
                </span>{" "}
                помощь
              </SevenHundred>
              <TwoThousand>
                <span>1400+</span>
                женщин и детей проживали
                <br />в Центре «Дом для мамы»
              </TwoThousand>
            </PinkCounter>
          </WrapperWhichHelpItems>
          <h3>Приют временного пребывания</h3>
          <p className="limitWidth">
            Приют для мам с детьми и беременных женщин открыт 7 февраля 2012
            года. Это значительно расширило и усилило возможности Кризисного
            центра. В 2022 году проведен капитальный ремонт здания. Средства на
            ремонт собраны друзьями, благотворителями и партнерами "Дома для
            мамы". В результате, вместимость приюта увеличилась в два раза.
            Теперь здесь могут одновременно проживать 18 женщин и их дети.
          </p>
        </WhichHelp>
        <GumHelp>
          <InnerGumHelp>
            <h4>Центр гуманитарной помощи</h4>
            <WrapItemsGum>
              <FirsttGumItem>
                <span>73 500+</span>
                женщин и детей получили гуманитарную помощь в виде одежды,
                детских колясок, кроваток, памперсов, предметов первой
                необходимости по уходу за детьми, продуктов питания.
              </FirsttGumItem>
              <SecondGumItem>
                <OneGumItem>
                  <span>60+</span>
                  регионов России получают помощь
                </OneGumItem>
                <TwoGumItem>
                  <span>1000+</span>
                  семей получают постоянную поддержку в Москве
                </TwoGumItem>
              </SecondGumItem>
              <ThirdGumItem>
                <img src={flowers} alt="ДомДлямамы" className="flowersGum" />
                <span>
                  <a href="/">
                    <img src={arrow} alt="ДомДлямамы" className="arrowGum" />
                    Подробнее
                  </a>
                </span>
              </ThirdGumItem>
              <img src={star} alt="ДомДлямамы" className="starGum" />
            </WrapItemsGum>
          </InnerGumHelp>
        </GumHelp>
        <MapArea>
          <InnerMapArea>
            <p>
              На 1 ноября 2022 года, при непосредственной материальной и
              методической поддержке специалистов московского Кризисного центра
              «Дом для мамы», в различных регионах России открылось и действует
            </p>
            <img src={map} alt="ДомДляМамы" className="mapArea" />
            <WrapMapArea>
              <FirstValueMap>
                <span>86</span>
                приютов
              </FirstValueMap>
              <SecondValueMap>
                <span>266</span>
                центра гуманитарной
                <br />
                помощи
              </SecondValueMap>
            </WrapMapArea>
          </InnerMapArea>
          <CentriStraniWrap>
            <Link to="/krizisnye-centry">Центры Страны</Link>
          </CentriStraniWrap>
        </MapArea>
      </Inner>
      <OurMission>
        <InnerOurMission>
          <p>Наша миссия</p>
          <div>
            <TextOurMission>
              Помогать беременным женщинам и мамам с детьми, попавшим в трудную
              жизненную ситуацию
            </TextOurMission>
            <Link to="/onas#special-element">Цели Центра</Link>
          </div>
        </InnerOurMission>
      </OurMission>
      <Inner>
        <WeInSmi>
          <InnerWeInSmi>
            <Rukovoditeli>
              <img src={maria} alt="ДомДляМамы" />
              <p className="ruc-cent">Руководитель центра</p>
              <p className="ruc-name">Студеникина Мария Михайловна</p>
              <RucRead>
                <img src={arrow} alt="ДомДляМамы" />
                <Link to="/studenikina">Читать подробнее</Link>
              </RucRead>
            </Rukovoditeli>
            <RukVideo>
              <President>
                <iframe
                  title="our page"
                  src="https://vk.com/video_ext.php?oid=-93739533&id=456240002&hash=360f0bd15ae41b34&hd=2"
                  width="260px"
                  height="146px"
                  frameBorder="0"
                  allowFullScreen
                />
                <div>Мария Студеникина заручилась поддержкой Президента РФ</div>
                <img src={star} alt="ДомДляМамы" className="prez-star" />
                <img src={star} alt="ДомДляМамы" className="prez-star-light" />
              </President>
              <Patriarh>
                <iframe
                  width="268"
                  height="146"
                  src="https://www.youtube.com/embed/jiW0CDcR0bA"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <div className="text-patriarh">
                  Патриарх Кирилл посетил «Дом для мамы» в Москве
                </div>
                <div className="button-to-smi">
                  <Link to="/mivsmi">Мы в СМИ: все видео</Link>
                  <img src={flatArrow} alt="ДомДляМамы" />
                </div>
              </Patriarh>
            </RukVideo>
          </InnerWeInSmi>
        </WeInSmi>
        <WrapNews>
          <h5>Новости</h5>
          <InnerNews>
            {news.map((val, index) => {
              const vremea = val.date.split("T")[0];
              return (
                <Fragment key={index}>
                  <a
                    href={`/novosti/${val.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <WrapItemsNewsOne key={val.id}>
                      <img src={val.fimg_url} alt="Дом для мамы" />
                      <p
                        className="TitleNewsMain"
                        dangerouslySetInnerHTML={{ __html: val.title.rendered }}
                      />
                      {/*<p className="SubtitleMain" dangerouslySetInnerHTML={{ __html: val.content.rendered }} />*/}
                      <p className="dateMain">{vremea}</p>
                    </WrapItemsNewsOne>
                  </a>
                </Fragment>
              );
            })}
          </InnerNews>
          <div className="mainAllNews">
            <Link to="/novosti">Все новости</Link>
            <img src={flatArrow} alt="ДомДляМамы" />
          </div>
        </WrapNews>
        <WrapVolunteers>
          <InnerVolunteers>
            <LeftVolunteers>
              <img src={leaf} alt="ДомДляМамы" className="leaf" />
              <div className="wrap-cont-volun">
                <p className="vol-one">Наши помощники — это волонтеры</p>
                <p className="vol-two">
                  <span>Заполни Анкету волонтера</span> и присоединяйся к нашей
                  дружной команде!
                </p>
                <Link className="vol-url" to="/volonteri/anketa">
                  Заполнить анкету
                </Link>
              </div>
            </LeftVolunteers>
            <RightVolunteers>
              <iframe
                width="100%"
                height={isMobile ? "533px" : "533px"}
                src="https://dzen.ru/embed/vECKSqnM6AC0?from_block=partner&from=zen&backoffice=1&mute=1&autoplay=1&tv=0"
                allow="autoplay; fullscreen; accelerometer; gyroscope; picture-in-picture; encrypted-media"
                frameBorder="0"
                scrolling="no"
                allowFullScreen=""
                title="Дом для мамы"
              ></iframe>
            </RightVolunteers>
          </InnerVolunteers>
        </WrapVolunteers>
        <WrapPartners>
          <h4>Наши партнеры</h4>
          <SliderPartners />
          <br />
          <div className="url-to-partn">
            <Link to="/partneri">Стать партнером Центра</Link>
          </div>
          <br />
          <br />
          <br />
        </WrapPartners>
      </Inner>
    </Wrapper>
  );
};
