import React from "react";
import {Link} from "react-router-dom";
import {
  Wrapper,
  Inner,
  WrapInner,
  WrapManyColors,
  WrappMapDet,
  WrapMapArea,
  FirstValueMap,
  SecondValueMap,
  WrapBook,
  CentriStraniWrap
} from "./metodPomoshiPage-styled";

import mapImg from "./map.svg";

import bookOne from "./bookone.png";
import bookTwo from "./booktwo.png";
import bookThree from "./bookthree.png";


export const MetodPomoshiPage = () => {
  return (
    <Wrapper>
      <Inner>
        <WrapInner>
          <h2>Методическая помощь</h2>
          <p>
            На 1 ноября 2022 года, при непосредственной материальной и методической поддержке специалистов московского Кризисного центра «Дом для мамы», в различных регионах России открылось и действует 258 Центра гуманитарной помощи и 86 приютов.
          </p>
          <WrapManyColors>
            Сеть <span className="greenSpan">Приютов</span> и <span className="pinkSpan">Центров гуманитарной помощи</span> #России
          </WrapManyColors>
        </WrapInner>
        <WrappMapDet>
          <WrapMapArea>
            <FirstValueMap>
              <span>86</span>
              приютов
            </FirstValueMap>
            <SecondValueMap>
              <span>266</span>
              центра гуманитарной<br/>помощи
            </SecondValueMap>
          </WrapMapArea>
          <img src={mapImg} alt="Дом для мамы" className="metod-map"/>
        </WrappMapDet>

        <WrapInner>
          <CentriStraniWrap>
            <Link to="/krizisnye-centry">
              Центры Страны
            </Link>
          </CentriStraniWrap>

          <h6>Наша литература</h6>
          <p>Практический опыт нашей команды, наработанный с февраля 2011 года, обобщен в серии книг. Их можно скачать прямо отсюда.</p>
          <WrapBook>
            <a href="https://domdliamamy.ru/donation/wp-content/uploads/2022/02/tsentr_gum_pomoshchi.pdf" download>
              <img src={bookOne} alt="Дом для Мамы"/>
            </a>
            <p className="greyTitleBook">Серия «Азбука милосердия»</p>
            <p className="mainTitleBook">Опыт создания церковного центра гуманитарной помощи</p>
            <p className="nameTtleBook">М.Студеникина, М.Можеева, И.Студеникин</p>
            <p className="descriptionBook">Пособие повествует о том, как правильно организовать Центр вещевой помощи для нуждающихся на основе опыта московского центра гуманитарной помощи православной службы «Милосердие»</p>
          </WrapBook>

          <WrapBook>
            <a href="https://domdliamamy.ru/donation/wp-content/uploads/2022/02/mama-home_block_1-224_new2.pdf" download>
              <img src={bookTwo} alt="Дом для Мамы"/>
            </a>
            <p className="greyTitleBook">Серия «Азбука милосердия»</p>
            <p className="mainTitleBook">Приют для будущих мам: от плана до воплощения</p>
            <p className="nameTtleBook">Мария Студеникина, Юрий Пущаев, Екатерина Новикова</p>
            <p className="descriptionBook">В книге все «проверено на себе», потому что в ее основе – реальный опыт построения московского кризисного центра «Дом для мамы», созданного в феврале 2012 года. Авторы постарались учесть ошибки, сделанные центром в начале работы, и выделить все ценное с учетом опыта и понимания, как должен жить такой проект.</p>
          </WrapBook>

          <WrapBook>
            <a href="https://domdliamamy.ru/donation/wp-content/uploads/2022/02/dom_dlya_mami_blok_to_site.pdf" download>
              <img src={bookThree} alt="Дом для Мамы"/>
            </a>
            <p className="greyTitleBook">Серия «Азбука милосердия»</p>
            <p className="mainTitleBook">Психолог помогает мамам: опыт психологической помощи беременным и матерям с детьми в кризисном центре «Дом для мамы»</p>
            <p className="nameTtleBook">Мария Студеникина, Диомид Бузулан, Инна Карпова</p>
            <p className="descriptionBook">В книге обобщен опыт психологической помощи женщинам в московском «Доме для мамы» православной службы «Милосердие». В новом издании представлены основные аспекты психологической помощи и поддержки женщины для преодоления кризиса. В пособии рассказывается, как помочь женщине восстановить отношения с близкими: мужем, родителями. В нем можно узнать и о том, как создать благоприятный психологический климат в самом центре. Также в книге даны практические методики, рекомендации и техники психологического консультирования, которые авторы многократно применяли в своей работе. Новое издание адресовано психологам, руководителям, сотрудникам и волонтерам кризисных центров и приютов.</p>
          </WrapBook>
        </WrapInner>

      </Inner>
    </Wrapper>
  )
};
