import React from "react";
import {Inner, Wrapper, WrapRoomImage} from "../rooms-styled";
import room from "../rooms-images/12.jpeg";
export const TwelveRoom = () => {
  return (
    <Wrapper>
      <Inner>
        <h2 className="twel-zag">Комната отремонтирована на средства, собранные "Клубом здорового питания Ю.Артемовой", блогером И.Бебениной, И. Бебениной и читателями портала <a href="https://pravoslavie.ru/" target="_blank" rel="noopener noreferrer">Pravoslavie.ru</a></h2>
        <WrapRoomImage>
          <img src={room} alt="Дом для мамы"/>
        </WrapRoomImage>
        <br/>
        <br/>
        <p className="bold-sec-text">Юлия Артемова:</p>
        <br/>
        <br/>
        <p className="norm-text">
          С Домиком для мамы я начала взаимодействовать с 2018 года. В 2016 году я впервые услышала о проекте. С 2016 по 2018 год мы жертвовали вещи, а в 2018 году я познакомилась с Марией Студеникиной, потому что сама делаю подобный проект.
          <br/> <br/>
          Мы построили с мужем дом 600 кв.м. рядом с метро Филатов луг и назвали его “Дом добрых сердец”. Вот уже четыре года мы взаимодействуем с Марией: иногда помогаем деньгами, иногда - вещами, поучаствовали нашей командой в ремонте.
          <br/> <br/>
          Для меня коммуникация с “Домом для мамы” - это поддержка и моему проекту, который только зарождается и тоже сейчас находится в стадии ремонта.
        </p>
        <br/>
        <br/>
        <p className="bold-sec-text">Ирина Бебенина:</p>
        <br/>
        <br/>
        <p className="norm-text">Я сама мама. Родила детей поздно в 38 и 40 лет) Всю жизнь работала в банках, занимала высокую должность. А когда ушла в декрет, организация вывела всех декретниц “за штат”, и меня в том числе. Дальше перспектива - увольнение.

        <br/>
        <br/>
          Сначала мне было грустно и обидно, ведь я так старалась для банка… А потом я решила, что нужно что-то делать и начала действовать. Организовала сначала свой небольшой бизнес. А потом он перерос в большой. Сначала мы шили фартуки для кормящих мам, потом - снуды.
          <br/>
          <br/>
          Это мамская тема, я стала мамой-блогером) Поддерживала грудное вскармливание, попала на программу Малахова, главной героиней. За годы моего материнства много изменилось и трансформировалось. Но весь мой бизнес, вся моя деятельность связана с мамами и с детьми.
          <br/>
          <br/>
          Также я раньше выступала против насилия над женщинами. На конкурсе “Миссис Вселенная” я выступала с речью, посвященной этой теме.

          <br/>
          <br/>
          Всё, что связано с мамами, с детьми, с поддержкой - это безусловно откликается в моем сердце. И когда встал вопрос по поводу благотворительности, то я точно знала, кому буду помогать. Подписчики скинули информацию про “Дом для мамы”. Сначала я сама стала перечислять деньги. А потом как-то я приехала, привезла вещи. Мы познакомились с Марией. После этого я стала оказывать помощь как блогер.

          <br/>
          <br/>
          Я постоянно участвую в благотворительности. Если где-то что-то просит, я всегда помогаю. Хоть я из среднего класса, но что могу, я делаю. Это для меня всегда норма, абсолютная норма - помогать людям, если им нужна помощь.
<br/>
<br/>
          Дому для мамы я решила помогать потому, что мне очень жалко женщин, я топлю всю жизнь за женщин. Я считаю, что на женскую долю очень много всего возлагается. Женщины очень стойкие существа, которые могут найти в себе нечеловеческие ресурсы, особенно на то, что касается детей. Поэтому я всегда за женщин, вопросов на этот счет не возникает. Миссия моя такая - за женщин!

        </p>
      </Inner>
    </Wrapper>
  )
}
