import React, { useRef, useState } from "react";
import {
  Wrapper,
  Inner,
  WrapForm,
  Info,
  FormParners,
  TextInfo,
  BottoomText,
  WrapTwoUl,
  TextForm,
  TitleForm,
  ThankMessage
} from "./partneri-styled";

import emailjs from "@emailjs/browser";
import {BounceAnimation} from "../../animation/BounceAnimation";
import { SliderPartners } from "../partners-slider";



export const Partneri = () => {
  const [successMessage, setSuccessMessage] = useState(false);
  const [failMessage, setFailedMessage] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const formPartners = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    setShowAnimation(true);
    emailjs.sendForm('service_703tt8s', 'template_456d63y', formPartners.current, 'user_vtAgdf3zaqK3Mf8SVKA8o')
      .then((result) => {
        setShowAnimation(false);
        setSuccessMessage(true);
      },  (error) => {
        setShowAnimation(false);
        setFailedMessage(true)
      });
  };

  return (
    <Wrapper>
      {showAnimation && <BounceAnimation />}
      {failMessage && <p>Что то пошло не так! Попробуйте еще раз!</p>}
      <Inner>
        <h2>Партнеры</h2>

        <SliderPartners />
        <br/>
        <br/>
        <WrapForm>
          <Info>
            <h5>Партнерство и поддержка</h5>
            <TextInfo>Мы создаем уникальные программы и акции, взаимоинтересные нам и партнерам</TextInfo>
            <WrapTwoUl>
              <div>
                <ul>
                  <li>Финансовая и ресурная</li>
                  <li>Информационная</li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>Профессиональная</li>
                  <li>Волонтерская</li>
                </ul>
              </div>
            </WrapTwoUl>
            <BottoomText>Присоединяйтесь к сообществу друзей «Дома для мамы».</BottoomText>
          </Info>
          <FormParners>
            {!successMessage ?
              <form ref={formPartners} onSubmit={sendEmail}>
                <TitleForm>Стать партнером проекта</TitleForm>
                <input id="companyPart" name="companyPart" type="text" required placeholder="Компания"/>
                <input id="statusPart" name="statusPart" type="text" required placeholder="Должность"/>
                <input id="namePart" name="namePart" type="text" required placeholder="ФИО"/>
                <input id="phonePart" name="phonePart" type="number" placeholder="Телефон для связи" required/>
                <input type="text" name="emailPart" pattern=".*@.*\..*" placeholder="Электронная почта"
                       title="Невалидный email"/>
                <TextForm>
                  Принимаю <a href="/">публичную оферту</a> и даю согласие на <a href="/">обработку персональных данных</a>
                </TextForm>
                <button>Отправить</button>
              </form>
              :
              <ThankMessage>
                Спасибо за заявку! Мы с вами свяжемся в ближайшее время!
              </ThankMessage>
            }
          </FormParners>
        </WrapForm>
      </Inner>
    </Wrapper>
  )
};
