import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import {
  Wrapper,
  Inner,
  WrapLines,
  FirstLine,
  SecondLine,
  ThirdLine,
  Line,
  WrapContacts,
  WrapNames,
  WrapDatePicker,
  WrapMobMail,
  WrapAbout,
  WrapButtonNext,
  WrapVariantHelps,
  WrapDaysToHelp,
  WrapHowExact,
  InnerItemsExact,
  WrapProfesstional,
  WrapItemRate,
  TextRate,
  Nmb,
  WrapAboutYou,
  WrapNumb,
  WhatAtract,
  WrapActiviType,
  WrapHowToKnow,
  WrapAdreessMetro,
  WrapWorkorStudy,
  WrapConsult,
  WrapPrivlekati,
  WrapMestoRaboti,
} from "./styled-anketa";
import { TextForm, ThankMessage } from "../partneri/partneri-styled";
import { BounceAnimation } from "../../animation/BounceAnimation";

export const Anketa = () => {
  const [currentLine, setCurrentLine] = useState(1);

  const [familia, setFamilia] = useState("");
  const [name, setName] = useState("");
  const [otchestvo, setOtchestvo] = useState("");
  const [adressString, setAdressString] = useState("");
  const [consult, setConsult] = useState("");
  const [confirmAll, setconfirmAll] = useState(true);
  const [mestoRaboti, setMestoRaboti] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "familia":
        setFamilia(value);
        break;
      case "name":
        setName(value);
        break;
      case "otchestvo":
        setOtchestvo(value);
        break;
      case "exactAdress":
        setAdressString(value);
        break;
      case "mestoraboti":
        setMestoRaboti(value);
        break;
      default:
        break;
    }
  };

  const [selectedDate, setSelectedDate] = useState("");
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({ mobile: "", email: "" });
  const validateMobile = (value) => {
    const mobileRegex = /^[0-9+]{8,14}$/;
    setErrors((prevErrors) => ({
      ...prevErrors,
      mobile: mobileRegex.test(value) ? "" : "Мобильный телефон недействителен",
    }));
  };

  const handleInputChangeMobEm = (e) => {
    const { name, value } = e.target;
    if (name === "mobile") {
      setMobile(value);
      validateMobile(value);
    } else if (name === "email") {
      setEmail(value);
      validateEmail(value);
    }
  };

  const validateEmail = (value) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: emailRegex.test(value) ? "" : "Неверный адрес электронной почты",
    }));
  };
  const [hasCar, setHasCar] = useState(false);
  const [carType, setCarType] = useState("");
  const handleCarCheckboxChange = (e) => {
    setHasCar(e.target.checked);
  };
  const handleCarTypeChange = (e) => {
    setCarType(e.target.value);
  };

  const handleNext = () => {
    if (currentLine === 1) {
      // Check if required fields are empty
      if (!name || !mobile) {
        alert(
          "Пожалуйста, заполните все обязательные поля: Имя, номер телефона",
        );
      } else {
        setCurrentLine((val) => val + 1);
      }
    } else {
      setCurrentLine((val) => val + 1);
    }
  };

  const handleBack = () => {
    setCurrentLine((val) => val - 1);
  };
  const [showAnimation, setShowAnimation] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [failMessage, setFailedMessage] = useState(false);
  const [specialDays, setSpecialDays] = useState("");
  const handleSend = () => {
    setShowAnimation(true);
    // Send the form using emailjs
    const templateParams = {
      // Define your email template parameters here
      familia, // Фамилия
      name, // Имя
      otchestvo, // Отчество
      selectedDate, // Дата рождения
      mobile, // Телефон
      email, // email
      adressString, // точный адресс
      mestoRaboti, // Место работы
      selectedOption, // Образование (другое)
      customInput, // Образование
      hasCar, // Если ли машина
      carType, // Тип машины
      selectedFrequency, // Когда Я могу помогать
      specialDays, // Другое когда  я могу помонать
      selectedOptions, // чем Я могу помогать
      selectedOptionsProf, // Бесплатно предоставлять профессиональные услуги:
      consult, // Дать профессиональную консультацию (какую именно)
      privlekati, // Привлекать
      drugoePrivlekati, // Другое Привлекать
      // otvetstven, // Ответственность
      // comunic, // Коммуникабельность
      // stress, // Стрессоустойчивость
      // coflict, // Конфликтность
      selectedOptionsAtract, // Что именно привлекает Вас в волонтерской деятельности?
      selectedOptionsActivity, // Какие виды деятельности Вы предпочитаете?
      selectedOptionsToKnow, // Как Вы узнали о нашем волонтерском проекте?
    };
    if (selectedFrequency === "чаще двух раз в неделю") {
      templateParams.selectedDays = selectedDays;
    }
    templateParams.hasCar = hasCar ? "Есть машина" : "Нету машины";

    emailjs
      .send(
        "service_703tt8s",
        "template_cimtrxd",
        templateParams,
        "user_vtAgdf3zaqK3Mf8SVKA8o",
      )
      .then((response) => {
        console.log("Email sent:", response);
        setShowAnimation(false);
        setSuccessMessage(true);
      })
      .catch((error) => {
        setShowAnimation(false);
        console.error("Email error:", error);
        setFailedMessage(true);
      });
  };
  const [selectedFrequency, setSelectedFrequency] = useState(
    "чаще двух раз в неделю",
  );
  const [selectedDays, setSelectedDays] = useState([]);
  const handleFrequencyChange = (e) => {
    setSpecialDays("");
    setSelectedFrequency(e.target.value);
  };
  const handleDayChange = (e) => {
    const day = e.target.value;
    setSelectedDays((prevDays) => {
      if (prevDays.includes(day)) {
        return prevDays.filter((d) => d !== day);
      } else {
        return [...prevDays, day];
      }
    });
  };
  const [privlekati, setPrivlekati] = useState("");
  const [drugoePrivlekati, setDugoePrivlekati] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsProf, setSelectedOptionsProf] = useState([]);
  const [selectedOptionsAtract, setSelectedOptionsAtract] = useState([]);
  const [selectedOptionsActivity, setSelectedOptionsActivity] = useState([]);
  const [selectedOptionsToKnow, setSelectedOptionsToKnow] = useState([]);
  const handleOptionChange = (option) => {
    if (selectedOptions.includes(option)) {
      // If the option is already selected, remove it
      setSelectedOptions((prevOptions) =>
        prevOptions.filter((item) => item !== option),
      );
    } else {
      // If the option is not selected, add it
      setSelectedOptions((prevOptions) => [...prevOptions, option]);
    }
  };

  const handleOptionChangeProf = (option) => {
    if (selectedOptionsProf.includes(option)) {
      // If the option is already selected, remove it
      setSelectedOptionsProf((prevOptions) =>
        prevOptions.filter((item) => item !== option),
      );
    } else {
      // If the option is not selected, add it
      setSelectedOptionsProf((prevOptions) => [...prevOptions, option]);
    }
  };

  const handleOptionChangeAtract = (option) => {
    if (selectedOptionsAtract.includes(option)) {
      // If the option is already selected, remove it
      setSelectedOptionsAtract((prevOptions) =>
        prevOptions.filter((item) => item !== option),
      );
    } else {
      // If the option is not selected, add it
      setSelectedOptionsAtract((prevOptions) => [...prevOptions, option]);
    }
  };
  const handleOptionChangeActivity = (option) => {
    if (selectedOptionsActivity.includes(option)) {
      // If the option is already selected, remove it
      setSelectedOptionsActivity((prevOptions) =>
        prevOptions.filter((item) => item !== option),
      );
    } else {
      // If the option is not selected, add it
      setSelectedOptionsActivity((prevOptions) => [...prevOptions, option]);
    }
  };
  const handleOptionChangeToKnow = (option) => {
    if (selectedOptionsToKnow.includes(option)) {
      // If the option is already selected, remove it
      setSelectedOptionsToKnow((prevOptions) =>
        prevOptions.filter((item) => item !== option),
      );
    } else {
      // If the option is not selected, add it
      setSelectedOptionsToKnow((prevOptions) => [...prevOptions, option]);
    }
  };

  const [otvetstven, setOtvetstven] = useState(0);
  const [comunic, setComunic] = useState(0);
  const [stress, setStress] = useState(0);
  const [coflict, setCoflict] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentLine]);

  const [selectedOption, setSelectedOption] = useState("");
  const [customInput, setCustomInput] = useState("");

  const handleOptionChangeObr = (event) => {
    setSelectedOption(event.target.value);
    setCustomInput("");
  };

  const handleInputChangeObr = (event) => {
    setCustomInput(event.target.value);
  };

  return (
    <Wrapper>
      {showAnimation && <BounceAnimation />}
      <Inner>
        <h2>Анкета для добровольных помощников</h2>
        {failMessage && (
          <p style={{ fontSize: "50px", color: "red", margin: "50px 0" }}>
            Что то пошло не так! Попробуйте еще раз илисообщите нам об этом!
          </p>
        )}
        {!successMessage ? (
          <>
            <WrapLines>
              <FirstLine className={currentLine === 1 ? "roz-set" : "roz-none"}>
                <Line className="add-roz" />
                <div className="roz-num">01</div>
                <div style={{ fontSize: "17px", fontWeight: 700 }}>
                  Основная информация
                </div>
              </FirstLine>
              <SecondLine
                className={currentLine === 1 ? "roz-set" : "roz-none"}
              >
                <Line className={currentLine >= 2 ? "add-roz" : "nooo-roz"} />
                <div className={currentLine >= 2 ? "roz-num" : "no-roz-num"}>
                  02
                </div>
                <div style={{ fontSize: "17px", fontWeight: 700 }}>
                  Варианты помощи
                </div>
              </SecondLine>
              <ThirdLine className={currentLine === 1 ? "roz-set" : "roz-none"}>
                <Line className={currentLine === 3 ? "add-roz" : "nooo-roz"} />
                <div className={currentLine === 3 ? "roz-num" : "no-roz-num"}>
                  03
                </div>
                <div style={{ fontSize: "17px", fontWeight: 700 }}>
                  Подробнее о себе
                </div>
              </ThirdLine>
            </WrapLines>
            {currentLine === 1 && (
              <>
                <WrapContacts>
                  <h6>Контактная информация</h6>
                  <WrapNames>
                    <input
                      type="text"
                      placeholder="Фамилия"
                      name="familia"
                      value={familia}
                      onChange={handleInputChange}
                    />
                    <input
                      type="text"
                      placeholder="Имя"
                      name="name"
                      value={name}
                      onChange={handleInputChange}
                    />
                    <input
                      type="text"
                      placeholder="Отчество"
                      name="otchestvo"
                      value={otchestvo}
                      onChange={handleInputChange}
                    />
                  </WrapNames>
                  <WrapDatePicker>
                    <label
                      htmlFor="datePicker"
                      className="date-label"
                      style={{ marginRight: "10px" }}
                    >
                      Дата Рождения:
                    </label>
                    <input
                      type="date"
                      id="datePicker"
                      value={selectedDate}
                      onChange={handleDateChange}
                      className="date-picker"
                      lang="ru"
                    />
                  </WrapDatePicker>
                  <WrapMobMail>
                    <input
                      type="text"
                      placeholder="Мобильный телефон"
                      name="mobile"
                      value={mobile}
                      onChange={handleInputChangeMobEm}
                    />
                    {errors.mobile && (
                      <span className="error-mob">{errors.mobile}</span>
                    )}
                    <input
                      type="text"
                      placeholder="Электронная почта"
                      name="email"
                      value={email}
                      onChange={handleInputChangeMobEm}
                    />
                    {errors.email && (
                      <span className="error-mob-em">{errors.email}</span>
                    )}
                  </WrapMobMail>
                  <WrapAdreessMetro>
                    <input
                      className="input-adress"
                      type="text"
                      placeholder="Адрес проживания (город, улица, ближайшая станция МЕТРО)"
                      name="exactAdress"
                      value={adressString}
                      onChange={handleInputChange}
                    />
                  </WrapAdreessMetro>
                  <WrapMestoRaboti>
                    <input
                      className="input-rabota"
                      type="text"
                      placeholder="Место работы/учебы, должность, сфера деятельности"
                      name="mestoraboti"
                      value={mestoRaboti}
                      onChange={handleInputChange}
                    />
                  </WrapMestoRaboti>
                  <span style={{ marginTop: "30px", display: "inline-block" }}>
                    Образование:
                  </span>
                  <WrapWorkorStudy>
                    <br />
                    <div className="wrap-obraz-inner">
                      <label>
                        <input
                          type="radio"
                          value="Среднее общее"
                          checked={selectedOption === "Среднее общее"}
                          onChange={handleOptionChangeObr}
                        />
                        Среднее общее
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="Среднее профессиональное"
                          checked={
                            selectedOption === "Среднее профессиональное"
                          }
                          onChange={handleOptionChangeObr}
                        />
                        Среднее профессиональное
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="Высшее"
                          checked={selectedOption === "Высшее"}
                          onChange={handleOptionChangeObr}
                        />
                        Высшее
                      </label>

                      <label>
                        <input
                          type="radio"
                          value="Другое"
                          checked={selectedOption === "Другое"}
                          onChange={handleOptionChangeObr}
                        />
                        Другое
                      </label>

                      {selectedOption === "Другое" && (
                        <div style={{ marginTop: "20px" }}>
                          <input
                            type="text"
                            value={customInput}
                            onChange={handleInputChangeObr}
                            placeholder="Введите что-либо"
                          />
                        </div>
                      )}
                    </div>
                  </WrapWorkorStudy>
                </WrapContacts>
              </>
            )}
            {currentLine === 2 && (
              <>
                <WrapVariantHelps>
                  <WrapAbout style={{ marginBottom: "30px" }}>
                    <h6>Наличие автомобиля</h6>
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          checked={hasCar}
                          onChange={handleCarCheckboxChange}
                          style={{ marginRight: "5px" }}
                        />
                        У меня есть автомобиль
                      </label>
                      <div style={{ marginTop: "10px" }}>
                        {hasCar && (
                          <select
                            value={carType}
                            onChange={handleCarTypeChange}
                            className="car-type-select"
                          >
                            <option value="">Выберите тип автомобиля</option>
                            <option value="легковой">Легковой</option>
                            <option value="грузовой">Грузовой</option>
                            <option value="микроавтобус">Микроавтобус</option>
                          </select>
                        )}
                      </div>
                    </div>
                  </WrapAbout>
                  <h6>Когда Я могу помогать</h6>

                  <div className="time-help">
                    <input
                      type="radio"
                      name="frequency"
                      value="В выходные дни"
                      checked={selectedFrequency === "В выходные дни"}
                      onChange={handleFrequencyChange}
                    />
                    <label>В выходные дни</label>
                  </div>
                  <div className="time-help">
                    <input
                      type="radio"
                      name="frequency"
                      value="В будние дни - утро/день"
                      checked={selectedFrequency === "В будние дни - утро/день"}
                      onChange={handleFrequencyChange}
                    />
                    <label>В будние дни - утро/день</label>
                  </div>
                  <div className="time-help">
                    <input
                      type="radio"
                      name="frequency"
                      value="В будние дни - вечер"
                      checked={selectedFrequency === "В будние дни - вечер"}
                      onChange={handleFrequencyChange}
                    />
                    <label>В будние дни - вечер</label>
                  </div>
                  <div className="time-help">
                    <input
                      type="radio"
                      name="frequency"
                      value="Другое"
                      checked={selectedFrequency === "Другое"}
                      onChange={handleFrequencyChange}
                    />
                    <label>Другое</label>
                    {selectedFrequency === "Другое" && (
                      <>
                        <input
                          className="spc-days"
                          type="text"
                          name="otherDays"
                          placeholder="Введите что-либо"
                          onChange={(e) => setSpecialDays(e.target.value)}
                        />
                      </>
                    )}
                  </div>
                </WrapVariantHelps>
                <WrapHowExact>
                  <h6>чем Я могу помогать</h6>
                  <InnerItemsExact>
                    <div>
                      {[
                        "Посидеть с детьми в Доме",
                        "Сопроводить маму по Москве",
                        "Отвезти маме памперсы и питание",
                        "Навестить, почитать, побеседовать",
                        "Собирать и сортировать вещи на складе",
                        "Участвовать в мероприятиях",
                        "Проводить творческие мастер-классы",
                      ].map((option) => (
                        <div key={option} style={{ marginBottom: "12px" }}>
                          <input
                            type="checkbox"
                            id={option}
                            name={option}
                            checked={selectedOptions.includes(option)}
                            onChange={() => handleOptionChange(option)}
                            style={{ marginRight: "12px", cursor: "pointer" }}
                          />
                          <label htmlFor={option} style={{ fontSize: "17px" }}>
                            {option}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div>
                      {[
                        "Обзвонить добровольцев",
                        "Помочь оформить документы справки",
                        "Координировать конкретный проект, акцию, группу волонтеров",
                        "Освещать работу Кризисного центра в СМИ (для сотрудников СМИ)",
                        "Делать репортажи, писать статьи о деятельности Центра",
                        "На собственной машине отвезти помощь по Москве",
                        "На собственной машине ездить в другие города Подмосковья",
                      ].map((option) => (
                        <div key={option} style={{ marginBottom: "12px" }}>
                          <input
                            type="checkbox"
                            id={option}
                            name={option}
                            checked={selectedOptions.includes(option)}
                            onChange={() => handleOptionChange(option)}
                            style={{ marginRight: "12px", cursor: "pointer" }}
                          />
                          <label htmlFor={option} style={{ fontSize: "17px" }}>
                            {option}
                          </label>
                        </div>
                      ))}
                    </div>
                  </InnerItemsExact>
                </WrapHowExact>
                <WrapProfesstional>
                  <h6>Бесплатно предоставлять профессиональные услуги:</h6>
                  <InnerItemsExact>
                    <div className="fifteen-procent">
                      {[
                        "Фото-видео съемки",
                        "Видеомонтажа",
                        "Верстки",
                        "Дизайна",
                        "Водителя",
                        "Промоутера",
                      ].map((option) => (
                        <div key={option} style={{ marginBottom: "12px" }}>
                          <input
                            type="checkbox"
                            id={option}
                            name={option}
                            checked={selectedOptionsProf.includes(option)}
                            onChange={() => handleOptionChangeProf(option)}
                            style={{ marginRight: "12px", cursor: "pointer" }}
                          />
                          <label htmlFor={option} style={{ fontSize: "17px" }}>
                            {option}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="fifteen-procent">
                      {[
                        "Психолога",
                        "Юриста",
                        "Врача",
                        "Мастера маникюра",
                        "Стилиста",
                        "Парикмахера",
                      ].map((option) => (
                        <div key={option} style={{ marginBottom: "12px" }}>
                          <input
                            type="checkbox"
                            id={option}
                            name={option}
                            checked={selectedOptionsProf.includes(option)}
                            onChange={() => handleOptionChangeProf(option)}
                            style={{ marginRight: "12px", cursor: "pointer" }}
                          />
                          <label htmlFor={option} style={{ fontSize: "17px" }}>
                            {option}
                          </label>
                        </div>
                      ))}
                    </div>
                  </InnerItemsExact>
                </WrapProfesstional>
                <WrapConsult>
                  <h6>Дать профессиональную консультацию (какую именно):</h6>{" "}
                  <input
                    type="text"
                    name="consult"
                    value={consult}
                    onChange={(e) => setConsult(e.target.value)}
                  />
                </WrapConsult>
                <WrapPrivlekati>
                  <h6>Привлекать </h6>
                  <div>
                    <input
                      type="radio"
                      name="priv"
                      value="Спонсоров под конкретные программы"
                      checked={
                        privlekati === "Спонсоров под конкретные программы"
                      }
                      onChange={(e) => {
                        setPrivlekati(e.target.value);
                        setDugoePrivlekati("");
                      }}
                    />
                    <label>Спонсоров под конкретные программы</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="priv"
                      value="Административные ресурсы для решения задач Кризисного Центра"
                      checked={
                        privlekati ===
                        "Административные ресурсы для решения задач Кризисного Центра"
                      }
                      onChange={(e) => {
                        setPrivlekati(e.target.value);
                        setDugoePrivlekati("");
                      }}
                    />
                    <label>
                      Административные ресурсы для решения задач Кризисного
                      Центра
                    </label>
                  </div>
                  <div className="time-help">
                    <input
                      type="radio"
                      name="priv"
                      value="Другое"
                      checked={privlekati === "Другое"}
                      onChange={(e) => {
                        setPrivlekati(e.target.value);
                      }}
                    />
                    <label>Другое</label>
                  </div>
                  {privlekati === "Другое" && (
                    <>
                      <input
                        className="drugoe-help"
                        type="text"
                        placeholder="Введите что-либо"
                        value={drugoePrivlekati}
                        onChange={(e) => setDugoePrivlekati(e.target.value)}
                      />
                    </>
                  )}
                </WrapPrivlekati>
              </>
            )}
            {currentLine === 3 && (
              <>
                <WrapAboutYou style={{ display: "none" }}>
                  <h6>РАССКАЖИТЕ О СЕБЕ</h6>
                  <p className="jepl-rate-text">
                    Оцените себя по шкале от 1 до 10 по личностным качествам
                  </p>
                  <WrapItemRate className="first-nump-wrap">
                    <TextRate>Ответственность</TextRate>
                    <WrapNumb>
                      <Nmb
                        onClick={() => setOtvetstven(1)}
                        className={otvetstven >= 1 ? "podsv" : "nepod"}
                      >
                        1
                      </Nmb>
                      <Nmb
                        onClick={() => setOtvetstven(2)}
                        className={otvetstven >= 2 ? "podsv" : "nepod"}
                      >
                        2
                      </Nmb>
                      <Nmb
                        onClick={() => setOtvetstven(3)}
                        className={otvetstven >= 3 ? "podsv" : "nepod"}
                      >
                        3
                      </Nmb>
                      <Nmb
                        onClick={() => setOtvetstven(4)}
                        className={otvetstven >= 4 ? "podsv" : "nepod"}
                      >
                        4
                      </Nmb>
                      <Nmb
                        onClick={() => setOtvetstven(5)}
                        className={otvetstven >= 5 ? "podsv" : "nepod"}
                      >
                        5
                      </Nmb>
                      <Nmb
                        onClick={() => setOtvetstven(6)}
                        className={otvetstven >= 6 ? "podsv" : "nepod"}
                      >
                        6
                      </Nmb>
                      <Nmb
                        onClick={() => setOtvetstven(7)}
                        className={otvetstven >= 7 ? "podsv" : "nepod"}
                      >
                        7
                      </Nmb>
                      <Nmb
                        onClick={() => setOtvetstven(8)}
                        className={otvetstven >= 8 ? "podsv" : "nepod"}
                      >
                        8
                      </Nmb>
                      <Nmb
                        onClick={() => setOtvetstven(9)}
                        className={otvetstven >= 9 ? "podsv" : "nepod"}
                      >
                        9
                      </Nmb>
                      <Nmb
                        onClick={() => setOtvetstven(10)}
                        className={otvetstven >= 10 ? "podsv" : "nepod"}
                      >
                        10
                      </Nmb>
                    </WrapNumb>
                  </WrapItemRate>

                  <WrapItemRate>
                    <TextRate>Коммуникабельность</TextRate>
                    <WrapNumb>
                      <Nmb
                        onClick={() => setComunic(1)}
                        className={comunic >= 1 ? "podsv" : "nepod"}
                      >
                        1
                      </Nmb>
                      <Nmb
                        onClick={() => setComunic(2)}
                        className={comunic >= 2 ? "podsv" : "nepod"}
                      >
                        2
                      </Nmb>
                      <Nmb
                        onClick={() => setComunic(3)}
                        className={comunic >= 3 ? "podsv" : "nepod"}
                      >
                        3
                      </Nmb>
                      <Nmb
                        onClick={() => setComunic(4)}
                        className={comunic >= 4 ? "podsv" : "nepod"}
                      >
                        4
                      </Nmb>
                      <Nmb
                        onClick={() => setComunic(5)}
                        className={comunic >= 5 ? "podsv" : "nepod"}
                      >
                        5
                      </Nmb>
                      <Nmb
                        onClick={() => setComunic(6)}
                        className={comunic >= 6 ? "podsv" : "nepod"}
                      >
                        6
                      </Nmb>
                      <Nmb
                        onClick={() => setComunic(7)}
                        className={comunic >= 7 ? "podsv" : "nepod"}
                      >
                        7
                      </Nmb>
                      <Nmb
                        onClick={() => setComunic(8)}
                        className={comunic >= 8 ? "podsv" : "nepod"}
                      >
                        8
                      </Nmb>
                      <Nmb
                        onClick={() => setComunic(9)}
                        className={comunic >= 9 ? "podsv" : "nepod"}
                      >
                        9
                      </Nmb>
                      <Nmb
                        onClick={() => setComunic(10)}
                        className={comunic >= 10 ? "podsv" : "nepod"}
                      >
                        10
                      </Nmb>
                    </WrapNumb>
                  </WrapItemRate>
                  {/* stress, setStress*/}
                  <WrapItemRate>
                    <TextRate>Стрессоустойчивость</TextRate>
                    <WrapNumb>
                      <Nmb
                        onClick={() => setStress(1)}
                        className={stress >= 1 ? "podsv" : "nepod"}
                      >
                        1
                      </Nmb>
                      <Nmb
                        onClick={() => setStress(2)}
                        className={stress >= 2 ? "podsv" : "nepod"}
                      >
                        2
                      </Nmb>
                      <Nmb
                        onClick={() => setStress(3)}
                        className={stress >= 3 ? "podsv" : "nepod"}
                      >
                        3
                      </Nmb>
                      <Nmb
                        onClick={() => setStress(4)}
                        className={stress >= 4 ? "podsv" : "nepod"}
                      >
                        4
                      </Nmb>
                      <Nmb
                        onClick={() => setStress(5)}
                        className={stress >= 5 ? "podsv" : "nepod"}
                      >
                        5
                      </Nmb>
                      <Nmb
                        onClick={() => setStress(6)}
                        className={stress >= 6 ? "podsv" : "nepod"}
                      >
                        6
                      </Nmb>
                      <Nmb
                        onClick={() => setStress(7)}
                        className={stress >= 7 ? "podsv" : "nepod"}
                      >
                        7
                      </Nmb>
                      <Nmb
                        onClick={() => setStress(8)}
                        className={stress >= 8 ? "podsv" : "nepod"}
                      >
                        8
                      </Nmb>
                      <Nmb
                        onClick={() => setStress(9)}
                        className={stress >= 9 ? "podsv" : "nepod"}
                      >
                        9
                      </Nmb>
                      <Nmb
                        onClick={() => setStress(10)}
                        className={stress >= 10 ? "podsv" : "nepod"}
                      >
                        10
                      </Nmb>
                    </WrapNumb>
                  </WrapItemRate>
                  {/*coflict, setCoflict*/}
                  <WrapItemRate>
                    <TextRate>Конфликтность</TextRate>
                    <WrapNumb>
                      <Nmb
                        onClick={() => setCoflict(1)}
                        className={coflict >= 1 ? "podsv" : "nepod"}
                      >
                        1
                      </Nmb>
                      <Nmb
                        onClick={() => setCoflict(2)}
                        className={coflict >= 2 ? "podsv" : "nepod"}
                      >
                        2
                      </Nmb>
                      <Nmb
                        onClick={() => setCoflict(3)}
                        className={coflict >= 3 ? "podsv" : "nepod"}
                      >
                        3
                      </Nmb>
                      <Nmb
                        onClick={() => setCoflict(4)}
                        className={coflict >= 4 ? "podsv" : "nepod"}
                      >
                        4
                      </Nmb>
                      <Nmb
                        onClick={() => setCoflict(5)}
                        className={coflict >= 5 ? "podsv" : "nepod"}
                      >
                        5
                      </Nmb>
                      <Nmb
                        onClick={() => setCoflict(6)}
                        className={coflict >= 6 ? "podsv" : "nepod"}
                      >
                        6
                      </Nmb>
                      <Nmb
                        onClick={() => setCoflict(7)}
                        className={coflict >= 7 ? "podsv" : "nepod"}
                      >
                        7
                      </Nmb>
                      <Nmb
                        onClick={() => setCoflict(8)}
                        className={coflict >= 8 ? "podsv" : "nepod"}
                      >
                        8
                      </Nmb>
                      <Nmb
                        onClick={() => setCoflict(9)}
                        className={coflict >= 9 ? "podsv" : "nepod"}
                      >
                        9
                      </Nmb>
                      <Nmb
                        onClick={() => setCoflict(10)}
                        className={coflict >= 10 ? "podsv" : "nepod"}
                      >
                        10
                      </Nmb>
                    </WrapNumb>
                  </WrapItemRate>
                </WrapAboutYou>
                <WhatAtract>
                  <h6>
                    Что именно привлекает Вас в волонтерской деятельности?
                  </h6>
                  <div>
                    {[
                      "Получение нового опыта",
                      "Встречи с новыми людьми",
                      "Возможность помочь другим",
                      "Вклад в общее дело",
                      "Новые интересы",
                      "Открытие для себя новых сфер",
                    ].map((option) => (
                      <div key={option} style={{ marginBottom: "12px" }}>
                        <input
                          type="checkbox"
                          id={option}
                          name={option}
                          checked={selectedOptionsAtract.includes(option)}
                          onChange={() => handleOptionChangeAtract(option)}
                          style={{ marginRight: "12px", cursor: "pointer" }}
                        />
                        <label htmlFor={option} style={{ fontSize: "17px" }}>
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                </WhatAtract>
                <WrapActiviType>
                  <h6>Какие виды деятельности Вы предпочитаете?</h6>
                  <div>
                    {[
                      "Связанные непосредственно с людьми",
                      "Не связанные с общением",
                      "Коллективную деятельность (в группе с другими людьми – единомышленниками)",
                      "Индивидуальную деятельность (делать что-то самому)",
                    ].map((option) => (
                      <div key={option} style={{ marginBottom: "12px" }}>
                        <input
                          type="checkbox"
                          id={option}
                          name={option}
                          checked={selectedOptionsActivity.includes(option)}
                          onChange={() => handleOptionChangeActivity(option)}
                          style={{ marginRight: "12px", cursor: "pointer" }}
                        />
                        <label htmlFor={option} style={{ fontSize: "17px" }}>
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                </WrapActiviType>
                <WrapHowToKnow>
                  <h6>Как Вы узнали о нашем волонтерском проекте?</h6>
                  {[
                    "Телевидение",
                    "Интернет",
                    "Социальные сети",
                    "Статья в газете, журнале",
                    "Видео в СМИ",
                    "От друзей, знакомых",
                  ].map((option) => (
                    <div key={option} style={{ marginBottom: "12px" }}>
                      <input
                        type="checkbox"
                        id={option}
                        name={option}
                        checked={selectedOptionsToKnow.includes(option)}
                        onChange={() => handleOptionChangeToKnow(option)}
                        style={{ marginRight: "12px", cursor: "pointer" }}
                      />
                      <label htmlFor={option} style={{ fontSize: "17px" }}>
                        {option}
                      </label>
                    </div>
                  ))}
                </WrapHowToKnow>
                <TextForm>
                  <input
                    checked={confirmAll}
                    type="checkbox"
                    name="agreeAll"
                    onChange={() => setconfirmAll(!confirmAll)}
                  />
                  Принимаю{" "}
                  <a
                    href="https://domdliamamy.ru/donation/wp-content/uploads/2021/09/oferta-1.png"
                    target="_blank"
                    rel="noreferrer"
                  >
                    публичную оферту
                  </a>{" "}
                  и даю согласие на{" "}
                  <a
                    href="https://domdliamamy.ru/donation/personal-data-usage-terms/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    обработку персональных данных
                  </a>
                </TextForm>
              </>
            )}

            <WrapButtonNext>
              {(currentLine === 2 || currentLine === 3) && (
                <button onClick={handleBack} style={{ marginRight: "10px" }}>
                  Назад
                </button>
              )}
              {(currentLine === 1 || currentLine === 2) && (
                <button onClick={handleNext}>Далее</button>
              )}
              {currentLine === 3 && (
                <button
                  className={confirmAll ? "all-agree" : "no-agree"}
                  onClick={handleSend}
                >
                  Отправить
                </button>
              )}
            </WrapButtonNext>
          </>
        ) : (
          <>
            {" "}
            <ThankMessage>
              <div style={{ textAlign: "left" }}>
                Дорогой волонтер!
                <br />
                <br />
                Ваши данные успешно отправлены и скоро координатор волонтеров
                свяжется с вами!
                <br />
                <br />
                Благодарим за ваше желание помочь и стать частью нашей команды!
                <br />
                <br />С уважением, <Link to={"/"}>Дом для мамы!</Link>
              </div>
            </ThankMessage>
          </>
        )}
      </Inner>
    </Wrapper>
  );
};
