import React from "react";
import Slider from "react-slick";

import { WrapLogoPartners } from "./styled-partneri-slider";

import ozerozero from "./img/00.png";
import ozero from "./img/0.png";
import onep from "./img/vacation.png";
// import twop from "./img/2.png";
import threep from "./img/3.png";
import blife from "./img/blife.png";
// import fourp from "./img/4.png";
// import fivep from "./img/5.png";
import sixp from "./img/6.png";
// import sevenp from "./img/7.png";
// import eightp from "./img/8.png";
// import ninep from "./img/9.png";
import tenp from "./img/10.png";
// import elevenp from "./img/11.png";
// import twelvep from "./img/12.png";
import thirtenp from "./img/13.png";
import fourteenp from "./img/14.png";
// import fifteenp from "./img/15.png";
import sixteenp from "./img/16.png";
// import seventeenp from "./img/17.png";
// import eightteenp from "./img/18.png";
import nineteenp from "./img/19.png";
// import twentteenp from "./img/20.png";
import twentoneteenp from "./img/21.png";
// import twenttwoteenp from "./img/22.png";
// import twentthreep from "./img/23.png";
import parpa from "./img/parpa.jpeg";
// import twentyfivep from "./img/25.png";
import twentysixp from "./img/26.png";
// import twentysevenp from "./img/27.png";
import twentyeightp from "./img/28.jpeg";
import twentynine from "./img/29.jpg";
import thirteen from "./img/30.png";
import rutecutor from "./img/rutekutor.png";
import stomkkom from "./img/stomkkom.png";
import seven from "./img/seven.svg";

export const SliderPartners = () => {
  const settings = {
    controls: true,
    dots: false,
    infinite: true,
    speed: 3000,
    autoplaySpeed: 15000,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: true,
    responsive: [
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <>
      <WrapLogoPartners>
        <Slider {...settings}>
          <div>
            <a
              href="https://www.ozon.ru/special/care/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={thirteen} alt="ДомДляМамы" className="firstImgSl" />
            </a>
            <a
              href="https://novard.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={threep} alt="ДомДляМамы" className="secondImgSl" />
            </a>
          </div>
          {/*<div>*/}
          {/*  /!*<a href="https://www.metro-cc.ru/" target="_blank" rel="noopener noreferrer">*!/*/}
          {/*  /!*  <img src={twop} alt="ДомДляМамы"/>*!/*/}
          {/*  /!*</a>*!/*/}
          {/*  /!*<a href="https://www.auchan.ru/" target="_blank" rel="noopener noreferrer">*!/*/}
          {/*  /!*  <img src={fourp} alt="ДомДляМамы"/>*!/*/}
          {/*  /!*</a>*!/*/}
          {/*</div>*/}
          {/*<div>*/}
          {/*  /!*<a href="https://dobrayamoskva.ru/" target="_blank" rel="noopener noreferrer">*!/*/}
          {/*  /!*  <img src={fivep} alt="ДомДляМамы"/>*!/*/}
          {/*  /!*</a>*!/*/}
          {/*  /!*<a href="https://www.chitai-gorod.ru/" target="_blank" rel="noopener noreferrer">*!/*/}
          {/*  /!*  <img src={elevenp} alt="ДомДляМамы"/>*!/*/}
          {/*  /!*</a>*!/*/}
          {/*  */}
          {/*</div>*/}
          <div>
            {/* <a href="https://www.instagram.com/la_mare_home/">
              <img src={sevenp} alt="ДомДляМамы"/>
            </a> */}
            <a href="/" target="_blank" rel="noopener noreferrer">
              <img src={sixteenp} alt="ДомДляМамы" />
            </a>
            <a
              href="https://www.superjob.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tenp} alt="ДомДляМамы" />
            </a>
          </div>
          <div>
            <a
              href="https://parpa.it/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ transform: "translate(0px, 34px)" }}
            >
              <img src={parpa} alt="ДомДляМамы" />
            </a>
            <a
              href="https://hoff.ru/promo/hoffwithyou/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ transform: "translate(0px, 43px)" }}
            >
              <img src={fourteenp} alt="ДомДляМамы" />
            </a>
          </div>
          {/*<div>*/}
          {/*  /!*<a href="https://www.mos.ru/" target="_blank" rel="noopener noreferrer">*!/*/}
          {/*  /!*  <img src={fifteenp} alt="ДомДляМамы"/>*!/*/}
          {/*  /!*</a>*!/*/}
          {/*  /!*<a href="https://lenta.com/" target="_blank" rel="noopener noreferrer">*!/*/}
          {/*  /!*  <img src={seventeenp} alt="ДомДляМамы"/>*!/*/}
          {/*  /!*</a>*!/*/}

          {/*</div>*/}
          {/* <div> */}
          {/* <a href="/" target="_blank" rel="noopener noreferrer">
              <img src={sixteenp} alt="ДомДляМамы"/>
            </a> */}
          {/* <a href="http://ludanikishina.ru/" target="_blank" rel="noopener noreferrer">
              <img src={eightteenp} alt="ДомДляМамы"/>
            </a> */}
          {/* </div> */}
          <div>
            <a
              href="http://ludanikishina.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={nineteenp} alt="ДомДляМамы" />
            </a>
            <a
              href="https://www.ucandles.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twentysixp} alt="ДомДляМамы" />
            </a>
          </div>
          <div>
            <a
              href="https://foodbankrus.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twentoneteenp} alt="ДомДляМамы" />
            </a>

            <a
              href="https://sevenhillslegal.com/ru"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img style={{ width: "100px" }} src={seven} alt="ДомДляМамы" />
            </a>
          </div>
          {/*<div>*/}
          {/*  <a href="https://sberbank-service.ru/" target="_blank" rel="noopener noreferrer">*/}
          {/*    <img src={twentthreep} alt="ДомДляМамы"/>*/}
          {/*  </a>*/}
          {/*  <a href="https://sberbank-service.ru/" target="_blank" rel="noopener noreferrer">*/}
          {/*    <img src={twentyfourp} alt="ДомДляМамы"/>*/}
          {/*  </a>*/}
          {/*</div>*/}
          {/*<div>*/}
          {/*  <a href="https://www.vtb.ru/" target="_blank" rel="noopener noreferrer">*/}
          {/*    <img src={twentysevenp} alt="ДомДляМамы"/>*/}
          {/*  </a>*/}
          {/*  <a href="https://www.absolute-help.ru/" target="_blank" rel="noopener noreferrer">*/}
          {/*    <img src={twentyeightp} alt="ДомДляМамы" className="forAbsoluteLabel" />*/}
          {/*  </a>*/}
          {/*</div>*/}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a
              href="https://www.unisender.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ transform: "translate(0px, 23px)" }}
            >
              <img src={ozero} alt="ДомДляМамы" />
            </a>
            <a
              href="https://fenomenflowers.ru/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ transform: "translate(0px, 32px)" }}
            >
              <img src={ozerozero} alt="ДомДляМамы" />
            </a>
          </div>
          <div>
            <a
              href="https://rutector.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={rutecutor} alt="ДомДляМамы" />
            </a>
            <a
              href="https://stom-kom.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={stomkkom} alt="ДомДляМамы" />
            </a>
          </div>

          <div>
            <a
              href="https://miloserdie.help/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={sixp} alt="ДомДляМамы" />
            </a>
            <a href="" target="_blank" rel="noopener noreferrer">
              <img
                src={onep}
                alt="ДомДляМамы"
                style={{ transform: "translateY(21px)" }}
              />
            </a>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a
              href="http://www.akademia-razvitiya.ru/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ transform: "translate(0px, -44px)" }}
            >
              <img src={twentynine} alt="ДомДляМамы" />
            </a>
            <a
              href="https://xn--80afcdbalict6afooklqi5o.xn--p1ai/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ transform: "translate(0px, -90px)" }}
            >
              <img src={thirtenp} alt="ДомДляМамы" />
            </a>
          </div>
          <div>
            <a
              href="https://babylifeexpo.ru/%F0%9F%99%8F"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={blife} alt="ДомДляМамы" />
            </a>
            <a
              href="https://www.absolute-help.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={twentyeightp}
                alt="ДомДляМамы"
                className="forAbsoluteLabel"
              />
            </a>
          </div>
        </Slider>
      </WrapLogoPartners>
    </>
  );
};
