import React from "react";
import { Studenikina } from "../components/studenikina";

export const StudenikinaPage = () => {
  return (
    <>
      <Studenikina />
    </>
  )
};


