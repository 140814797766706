import React from "react";
import {Inner, Wrapper, WrapRoomImage} from "../rooms-styled";
import room from "../rooms-images/03.jpeg";

export const ThirdRoom = () => {
  return (
    <Wrapper>
      <Inner>
       <h2>Комната отремонтирована на средства, собранные Натальей П.</h2>
        <WrapRoomImage>
          <img src={room} alt="Дом для мамы"/>
        </WrapRoomImage>
        <br/>
        <br/>
        <p className="bold-sec-text"> Наталья:</p>
        <br/>
        <br/>

        <p className="norm-text">
          Я являюсь партнером юридической компании и мамой троих мальчиков. Несмотря на то, что в моей жизни, к счастью, не было ситуаций, которые случаются с подопечными Домика для мамы, мне очень близка миссия помощи женщинам, оказавшимся в трудных ситуациях.
          <br/>
          <br/>
          Я увидела этот проект в социальных сетях. В тот момент мне уже хотелось как-то делиться своей помощью. Я познакомилась с основателем фонда и приехала в гости. С тех пор, я помогаю Домику для мамы. И, конечно, когда я увидела сбор на ремонт Домика, с удовольствием приняла в этом участие вместе с моими друзьями, которым рассказала о проекте.

        </p>

      </Inner>
    </Wrapper>
  )
}
