import React from "react";
import {Link} from "react-router-dom";
import {
  Inner,
  Wrapper,
  VideoView,
  WrapButton,
  ThreeItemsVideo,
  Pagination
} from "./video-youtube-styled";
import arrow from "./arrow.png";
import { InnerVideo } from "../previewPage/previewPage-styled";
import YouTube from "react-youtube";

export const VideoYoutube = () => {
  const opts = {
    height: '314',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      // autoplay: 1,
    },
  };

  const optsTwo = {
    height: '300',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      // autoplay: 1,
    },
  };
  const videoArray = [
    {vid: "NngT5OQ0Yts", text: "Шоу \"Пацанки\" в Доме для мамы"}, {vid: "AiN_tpOq4Ag",text: "Ярослав Брин в гостях у \"Дома для мамы\""},
    {vid: "KKRo0XUEq20", text: "Мария Студеникина — о своем участии в Конкурсе «Лидеры России. Политика»"},
    {vid: "rdVLdXDGFTU", text: "Урок для кризисного центра \"Дом для мамы\""}, {vid: "zlMbocm0ipY", text: "В гостях Мария Студеникина, руководитель \"Дом для мамы\" и \"Спаси жизнь\""},
    {vid: "a2sUaffO0jM", text: "Новогодний фестиваль в Звенигороде"}, {vid: "n2Qx7lbSmTA", text: "Мария Студеникина — о своих впечатлениях от финала Конкурса «Лидеры России. Политика»"},
    {vid: "lY8F3hWsi7Y", text: "Проект про Добро доставил пасхальные куличи в \"Дом для мамы\""}, {vid: "4rs1uP_nC7Y", text: "ПРОЕКТ «ДОМ ДЛЯ МАМЫ» ПОМОГАЕТ ЖЕНЩИНАМ С ДЕТЬМИ, КОТОРЫЕ ПОПАЛИ В ТРУДНУЮ СИТУАЦИЮ"}
  ];
  return (
    <Wrapper>
      <Inner>
        <VideoView>
          <h2>Мы в СМИ</h2>
          <WrapButton>
            <img src={arrow} alt="ДомДляМамы"/>
            <Link to="/mivsmi">вернуться</Link>
          </WrapButton>
          <p className="v-video">Видео</p>
          <div>
            <InnerVideo>
              <div className="vid-prev">
                <iframe title="president" src="https://vk.com/video_ext.php?oid=-93739533&id=456240002&hash=360f0bd15ae41b34&hd=2" width="100%"
                        height="314px" frameBorder="0" allowFullScreen/>
                <h5>Мария Студеникина заручилась поддержкой Президента РФ: </h5>
              </div>
              <div className="vid-prev">
                <YouTube videoId='jiW0CDcR0bA' opts={opts} />
                <h5>Патриарх Кирилл посетил «Дом для мамы» в Москве: </h5>
              </div>
            </InnerVideo>
            <InnerVideo>
              <div>
                <iframe title="1tv" width="100%" height="314" src="//www.1tv.ru/embed/212638:12" allow="encrypted-media" frameBorder="0"
                        allowFullScreen />
                <h5>Первый канал в гостях у Домика: </h5>
              </div>
              <div>
                <YouTube videoId='b5spL1RnjDc' opts={opts} />
                <h5>Рита Дакота в гостях у "Дома для мамы":</h5>
              </div>
            </InnerVideo>
            <ThreeItemsVideo>
              {videoArray.map((value, index) => (
                <div className="videoArrayOne" key={index}>
                  <YouTube videoId={value.vid} opts={optsTwo} />
                  <p>{value.text}</p>
                </div>
              ))}
            </ThreeItemsVideo>
          </div>
        </VideoView>
        <Pagination>
          <div>
            <Link to='/video/1' className="inactive-pagination">
              1
            </Link>
          </div>
          <div>
            <Link to='/video/2'>
              2
            </Link>
          </div>
          <div>
            <Link to='/video/3'>
              3
            </Link>
          </div>
        </Pagination>
      </Inner>
    </Wrapper>
  )
};
