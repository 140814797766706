import React from 'react';
import { NeedHelpPage } from "../components/NeedHelpPage";

export const NeedHelp = () => {
  return (
    <div>
      <NeedHelpPage />
    </div>
  );
};
