import React from "react";
import {Inner, Wrapper, WrapContent, WrapSocial} from "./social-specialist-styled";
import spe from "./spcialist.jpeg";

export const SocialSpecialist = () => {
  return (
    <Wrapper>
      <Inner>
        <WrapContent>

          <h2>Савинская Любовь - наш специалист по работе с внешними подопечными</h2>

          <WrapSocial>
            <a href="mailto:help@domdliamamy.ru" className="cont-email">help@domdliamamy.ru</a>
          </WrapSocial>

          <img src={spe} alt="Дом для мамы"/>

          <p>Имеет опыт работы с людьми и документами — более 25 лет, высшее экономическое образование.</p>
          <p>Ответственно относится к подопечным и результатам работы с ними. Часто задерживается на рабочем месте, стараясь поскорее помочь семьям.</p>
          <p className="green-block">В нашем Центре Любовь Анатольевна с самого-самого начала, уже более 12 лет! Знает всю историю «Дома для мамы» от начала до сегодняшнего дня.</p>
          <p>Долгое время помогала как волонтер, готовила приют к первому открытию. </p>
          <p>Люди, которые знают Любовь Анатольевну, характеризуют ее как улыбающегося, светлого человека.</p>
          <p className="title-about-pages">Какие задачи специалист по работе с внешними подопечными решает в Центре:</p>
          <ul>
            <li>
              адресная помощь подопечным семьям по индивидуальным нуждам (питание, одежда, билеты и пр.)
            </li>
            <li>
              приобретение подарков к праздникам для детей-инвалидов из подопечных семей
            </li>
            <li>
              ведение базы данных, документооборот по внешним подопечным
            </li>
            <li>
              организация и проведение тематических мероприятий, праздников для мам и детей
            </li>
          </ul>
          <p className="pink-block">За 12 лет существования Центра 11000 женщин и детей получили гуманитарную помощь в виде одежды, детских колясок, кроваток, памперсов, предметов первой необходимости по уходу за детьми, продуктов питания.</p>
          <p>
            Если вам необходима консультация администратора приюта, вы можете обратиться в наш Центр по тел.<a href="tel:+79652629878" className="phon-black">+7 (965) 262-98-78</a> или написать на адрес электронной почты <a href="mailto:cat@domdliamamy.ru" className="cont-emil">cat@domdliamamy.ru</a>
          </p>
        </WrapContent>
      </Inner>
    </Wrapper>
  )
};
