import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 48px 0;
  margin-bottom: 40px;
  @media screen and (max-width: 900px) {
    margin-bottom: 80px;
  }
  h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 64px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
      font-size: 40px;
      line-height: 48px;
    }
  }
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;

  .help-grey-text {
    font-size: 12px;
    color: #605c5c;
    transform: translateY(-20px);
  }
`;

export const HelpTypes = styled.div`
  ul {
    margin-left: 20px;
  }
  li {
    list-style: disc;
  }
  li a {
    color: #69BAC1;
    &:hover {
      color: #356D74;
    }
  }
`;

export const WrapNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  button {
    background: #D7F8F5;
    border-radius: 24px;
    padding: 7px 20px 9px 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #438A92;
    border: 1px solid #D7F8F5;
    &:hover {
      border: 1px solid #438A92;
      cursor: pointer;
    }
  }
`;

export const WrapSearch = styled.div`

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }
  
  input {
    margin-bottom: 20px;
    border: 1px solid #CDD5E0;
    border-radius: 6px;
    padding: 14px 23px;
    width: 600px;
    margin-right: 30px;
    @media screen and (max-width: 768px) {
      width: auto;
      margin-right: 0px;
    }
  }
  button {
    color: #fff;
    padding: 9px 20px;
    background: #69BAC1;
    border-radius: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    border-color: transparent;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const WrapCentriView = styled.div`
  margin-top: 30px;
  max-width: 760px;
  background: #F3FAF9;
  border-radius: 12px;
  padding: 32px 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
`;

export const WrapMap = styled.div`
  
`;


export const WrapButtonShowHide = styled.div`
  display: flex;
  justify-content: end;
`;
export const HideShowButton = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: rgb(105, 186, 193);
  border-bottom: 1px dashed rgb(105, 186, 193);
  background: transparent;
    margin: 10px 0;
    &:hover {
      cursor: pointer;
      color: rgb(53, 109, 116);
      border-bottom: 1px dashed rgb(53, 109, 116);
    }
`;

export const HideShowButtonRed = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: rgb(193, 105, 126);
  border-bottom: 1px dashed rgb(193, 105, 115);
  background: transparent;
  margin: 10px 20px 10px 0px;

  &:hover {
    cursor: pointer;
    color: rgb(255, 0, 0);
    border-bottom: 1px dashed rgb(116, 53, 53);
  }
`;

export const WrapShowOnMap = styled.div`
  display: flex;
  justify-content: end;
  color: rgb(105, 186, 193);
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    font-size: 14px;
    margin-top: 25px;
  }
`;