import React from "react";
import {Inner, Wrapper, WrapRoomImage} from "../rooms-styled";
import room from "../rooms-images/15.jpeg";
export const FifTeenRoom = () => {
  return (
    <Wrapper>
      <Inner>
        <h2>Комната отремонтирована на средства, собранные Варварой Б., Ольгой З., Марией К. и еще 444 друзьями "Дома для мамы"</h2>
        <WrapRoomImage>
          <img src={room} alt="Дом для мамы"/>
        </WrapRoomImage>
        <br/>
        <br/>
        <p className="norm-text">
          Мы искренне благодарим за помощь в ремонте комнаты приюта!❤
          <br/>
          <br/>
          Пока что про наших друзей здесь ничего нет. Но мы скоро это исправим😉
          <br/>
          <br/>
           </p>
      </Inner>
    </Wrapper>
  )
}
