import React from "react";
import {Inner, Wrapper, WrapRoomImage} from "../rooms-styled";
import room from "../rooms-images/02.jpeg";

export const SecondRoom = () => {
  return (
    <Wrapper>
      <Inner>
       <h2>Комната отремонтирована на средства, собранные Фондом "Миру нужен человек" и проектом <a href="https://paperplanes.ru/" target="_blank" rel="noopener noreferrer">Paperplanes.ru</a></h2>
        <WrapRoomImage>
          <img src={room} alt="Дом для мамы"/>
        </WrapRoomImage>
        <br/>
        <br/>
        <p className="bold-sec-text">Мариам:</p>
        <br/>
        <br/>

        <p className="norm-text">
          Меня зовут Мариам, я основатель фонда «Миру нужен человек». Мы помогаем  семьям нуждающимся в помощи, психологической и материальной, уже больше 14 лет. Мы помогаем детским домам, больницам, роддомам и семьям.
          <br/> <br/>
          Мы делаем маленькие добрые поступки)
          <br/> <br/>
          Уважаю и очень ценю ваш труд! Спасибо, что занимаетесь таким сложным вопросом и поддерживаете девушек в такой трудной ситуации.
          <br/>
          <br/>
          Мы решили помочь сами и организовать сбор на ремонт в приюте, чтобы поскорее вы справились с такой глобальной, важной задачей и открыли двери для будущих мам, которые смогут жить там в комфорте.

        </p>

      </Inner>
    </Wrapper>
  )
}
