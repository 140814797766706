import React from 'react';
import { Contacti } from "../components/contacti";

export const Contacts = () => {
  return (
    <>
      <Contacti />
    </>
  );
};
