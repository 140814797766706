import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 48px 0;
  margin-bottom: 40px;
  @media screen and (max-width: 900px) {
    margin-bottom: 80px;
  }
  h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 64px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
      font-size: 24px;
      line-height: 48px;
    }
  }
  h3 {
    font-size: 27px;
    font-weight: 900;
    margin-bottom: 11px;
    @media screen and (max-width: 900px) {
      font-size: 20px;
    }
  }
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
`;

export const WrapInner = styled.div`
  max-width: 760px;
  @media screen and (max-width: 900px) {
    max-width: 100%;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #111729;
    margin-bottom: 40px;
  }
  img {
    margin-bottom: 40px;
    max-width: 100%;
  }
`;

export const WrapGreenNumber = styled.div`
  background: #F3FAF9;
  border-radius: 12px;
  padding: 12px 24px;
  span {
    display: block;
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 56px;
    color: #438A92;
  }
  p {
    margin-bottom: 0;
  }
`;

export const WrapDoubleGreen = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 0 40px 0;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const OneDouble = styled.div`
  background: #F3FAF9;
  border-radius: 12px;
  padding: 12px 24px;
  width: 42%;
  @media screen and (max-width: 900px) {
    width: calc(100% - 50px);
    margin-bottom: 8px;
  }
  span {
    display: block;
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 56px;
    color: #438A92;
  }
  p {
    margin-bottom: 0;
  }
`;


