import React from "react";
import {Inner, Wrapper, WrapRoomImage} from "../rooms-styled";
import room from "../rooms-images/08.jpeg";
export const EightRoom = () => {
  return (
    <Wrapper>
      <Inner>
       <h2>Комната отремонтирована на средства, собранные Екатериной С.</h2>
        <WrapRoomImage>
          <img src={room} alt="Дом для мамы"/>
        </WrapRoomImage>
        <br/>
        <br/>
        <p className="bold-sec-text">Екатерина:</p>
        <br/>
        <br/>
        <p className="norm-text">
          Для меня важно помогать “Дому для мамы”, потому что я считаю, что сохранение жизни – это самое важное, что может быть в нашем существовании на нашей планете.
        <br/>
        <br/>
        Сохранить жизнь маленькому существу, неважно в какой ситуации он появится на свет, неважно, кто его мама, кто его родители, - это огромная миссия. Потому что наша природа, эволюция вложила миллиарды лет, миллиарды усилий для того, чтобы собрать из миллионов минералов, микроэлементов то, что мы сейчас называем человеческим телом, человеческим существом. Нет ничего важнее этого. И то, что мы можем помочь этим молодым мамам, которые, быть может, бессознательно еще сейчас могут запутаться, принять неправильное решение, – это единственное важное, что мы можем сделать.
        <br/>
        <br/>
          Я очень хочу, чтобы эти женщины чувствовали поддержку, чувствовали себя принятыми, чувствовали себя в безопасности. Чувствовали, что в их жизни всё будет приходить от других людей, от других ситуаций, неважно откуда. Им нужно просто довериться и просто привести этого ребенка в этот мир. И дальше всё получится, всё сложится.
          <br/>
          <br/>
          И я верю, что каждая мама, которая все-таки приняла решение родить ребенка, быть с ним, она будет благодарна каждую минуту потом, после этой небольшой поддержки, этим дням, этим месяцам, которые она провела в этом Доме для мамы. Это будет самое главное её решение и самый главный в жизни момент.
        </p>
      </Inner>
    </Wrapper>
  )
}
