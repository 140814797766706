import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 48px 0;
  margin-bottom: 40px;
  @media screen and (max-width: 900px) {
    margin-bottom: 80px;
  }
  h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 64px;
    color: #111729;
    padding-bottom: 40px;
    white-space: nowrap;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
      font-size: 40px;
      line-height: 48px;
      white-space: inherit;
    }
  }
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
`;

export const WrapInner = styled.div`
  max-width: 760px;
  @media screen and (max-width: 900px) {
    max-width: 100%;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #111729;
    margin-bottom: 40px;
  }
  img {
    margin-bottom: 40px;
    max-width: 100%;
  }
`;

export const WrapListText = styled.div`
  display: flex;
  margin-bottom: 30px;
  span {
    min-width: 40px;
    margin-right: 16px;
    height: 40px;
    background: #F4D1E7;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #111729;
    margin: 0;
  }
`;

export const RezultTitle = styled.h6`
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  color: #111729;
  margin: 40px 0;
  max-width: 760px;
`;

export const WrapRezult = styled.div`
  display: flex;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const PinkPart = styled.div`
  width: 70%;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;
export const GreenPart = styled.div`
  width: 28%;
  margin-left: 2%;
  height: 423px;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    width: 100%;
    max-width: 350px;
  }
`;

export const InnerDivs = styled.div`
  padding: 24px 12px;
  display: inline-block;
  width: 46%;
  background: #FBF2F8;
  margin-bottom: 8px;
  border-radius: 12px;
  @media screen and (max-width: 900px) {
    width: 100%;
    max-width: 350px;
  }
  @media screen and (max-width: 480px) {
    max-width: fit-content;
  }
  span {
    display: block;
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 56px;
    color: #DA5697;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: #111729;
    margin-bottom: 0;
  }
`;

export const WrapInnerDivs = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const InnerDivsFullWidth = styled.div`
  padding: 24px 12px;
  background: #FBF2F8;
  margin-bottom: 8px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: inherit;
    width: 100%;
    max-width: 350px;
    padding: 0;
    min-width: 100%;
  }
  span {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 56px;
    color: #DA5697;
    white-space: nowrap;
    @media screen and (max-width: 900px) {
      white-space: inherit;
      padding: 12px 24px 0 12px ;
    }
  }
  p {
    display: inline-block;
    margin-left: 30px;
    @media screen and (max-width: 900px) {
      margin-left: 0;
      display: block;
      padding: 0 12px 24px 12px;
    }
  }
`;

export const InnerGreen = styled.div`
  background: #F3FAF9;
  border-radius: 12px;
  padding: 12px 24px 170px;
  img {
    margin-bottom: 24px;
  }
`;

export const WrapCounters = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export const FirstCount = styled.div`
  span {
    font-weight: 900;
    font-size: 56px;
    line-height: 56px;
    color: #438A92;
  }
  p {
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    color: #438A92;
  }
`;
export const SecondCount = styled.div`
  margin-left: 40px;
  span {
    font-weight: 900;
    font-size: 56px;
    line-height: 56px;
    color: #438A92;
  }

  p {
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    color: #438A92;
  }

\` ;
`;
export const BottomText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #111729;
`;

export const ShowHideSection = styled.div`
  margin-top: 50px;
  border-top: 1px solid #CDD5E0;
  border-bottom: 1px solid #CDD5E0;
  padding: 24px;
  @media screen and (max-width: 900px) {
  }
  .zagHideS {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #111729;
    margin-bottom: 4px;
  }
  .subZagS {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: #677489;
    margin: 0;
  }
`;

export const WrapTopHide = styled.div`
  position: relative;
  .pr-mobile {
    @media screen and (max-width: 900px) {
      padding-right: 30px;
    }
  }
`;

export const WrapButton = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 30px;
  right: 0;
  svg {
    width: 100%;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const WrapListShowed = styled.div`
  .list-title-nap {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 30px;
    color: #111729;
    margin-top: 40px;
  }
  .list-item-nap {
    font-style: normal;
    font-size: 17px;
    line-height: 24px;
    color: #111729;
    margin-top: 12px;
  }
  p {
    margin: 0;
    span {
      font-weight: 900;
    }
  }
`;

export const CentriStraniWrap = styled.div`
  transform: translateY(30px);
  @media screen and (max-width: 900px) {
    margin: 40px 0 20px 0;
    text-align: center;
    transform: none;
  }
   a {
    color: #fff;
    padding: 9px 20px;
    background: #69BAC1;
    border-radius: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    &:hover {
      background: #7BCCD1;
    }
  }  .cetri-strani {
       color: #fff;
       padding: 9px 20px;
       background: #69BAC1;
       border-radius: 24px;
       font-style: normal;
       font-weight: 700;
       font-size: 15px;
       &:hover {
         background: #7BCCD1;
       }
     }
`;
