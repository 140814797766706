import React from "react";
import { Link } from "react-router-dom";
import { Inner, Wrapper } from "./notFound-styled";

export const NotFound = () => {
  return (
    <Wrapper>
      <Inner>
        <div className="page-404">404</div>
        <div>Страница не найдена</div>
        <br/>
        <br/>
        <div>К сожалению, эта страница отсутствует! Загляните к нам на <Link to="/"> Главную</Link></div>
      </Inner>
    </Wrapper>
  )
}
