import styled from "styled-components";

export const Wrapper = styled.div`

`;

export const InnerThumbs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  padding: 10px;
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const WrapThumbs = styled.div`
  background: #F3FCFB;
  border-radius: 6px;
  width: 260px;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  span {
    position: absolute;
    top: 265px;
    left: 50%;
    transform: translateX(-50%);
    width: 250px;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: #111729;
  }
`;

export const WrapThumbsPink = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 6px;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const InnerLetterThumbs = styled.div`
  position: relative;
  background: #FBF2F8;
  padding: 30px 0;
`;

export const WrapImg = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  display: flex; 
  justify-content: center;
  align-items: center;
  padding: 40px;
  @media screen and (max-width: 900px) {
    padding: 20px;
  }
  img {
    width: auto;
    height: calc(100% - 80px);
    max-width: 100%;
    @media screen and (max-width: 900px) {
      height: auto;
      width: calc(100% - 40px);
    }
  }
  span {
    position: relative;
    height: 100%;
    @media screen and (max-width: 900px) {
      height: auto;
    }
  }
  .closeIconPisima {
    position: absolute;
    right: -20px;
    top: -20px;
    width: 40px;
    height: 40px;
    background: #fff;
    padding: 5px;
    border-radius: 50%;
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 900px) {
      right: 20px;
    }
  }
`;