import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 48px 0;
  margin-bottom: 40px;
  @media screen and (max-width: 900px) {
    margin-bottom: 80px;
  }
  h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 64px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
      font-size: 40px;
      line-height: 48px;
    }
  }
  input,
  textarea {
    &:hover {
      cursor: auto;
    }
  }
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const WrapNews = styled.div`
  h5 {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
    }
  }
  .mainAllNews {
    position: relative;
    display: inline-block;
    margin-top: 50px;
    @media screen and (max-width: 900px) {
      margin-top: 10px;
    }
    a {
      background: #d7f8f5;
      border-radius: 24px;
      padding: 7px 54px 9px 20px;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      color: #356d74;
      border: 1px solid #d7f8f5;
      &:hover {
        background: #d7f8f5;
        border: 1px solid #69bac1;
      }
      &:active {
        background: #b4eeea;
        border: 1px solid #69bac1;
      }
    }
    img {
      position: absolute;
      width: 16px;
      height: 15.56px;
      left: 127px;
      top: 4.5px;
    }
  }
`;
export const InnerNews = styled.div`
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: initial;
  }
  .TitleNewsMain {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    margin-top: 16px;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    color: #111729;
  }
  .SubtitleMain {
    margin-top: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #111729;
  }
  .dateMain {
    margin-top: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: #677489;
    @media screen and (max-width: 900px) {
      margin-bottom: 30px;
    }
  }
  a {
    width: 33%;
    display: inline-block;
    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 900px) {
      width: 100%;
    }
  }
`;
export const WrapItemsNews = styled.div`
  padding: 20px;
  display: block;
  max-width: 100%;

  img {
    margin: 0 auto;
    max-width: 240px;
    max-height: 192px;
    display: block;
  }
  @media screen and (max-width: 900px) {
    max-width: 500px;
    width: 100%;
    text-align: center;
    padding: 0;
  }
  &:hover {
    cursor: pointer;
  }
  p,
  img {
    &:hover {
      cursor: pointer;
    }
  }
  &.skeletonPreloader {
    a {
      pointer-events: none;
      padding: 20px;
      width: calc(33% - 40px);
      @media screen and (max-width: 900px) {
        padding: 0;
        width: 100%;
      }
    }
  }
`;

export const WrapNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  button {
    background: #d7f8f5;
    border-radius: 24px;
    padding: 7px 20px 9px 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #438a92;
    border: 1px solid #d7f8f5;
    &:hover {
      border: 1px solid #438a92;
      cursor: pointer;
    }
  }
`;
