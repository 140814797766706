import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { BounceAnimation } from "../../animation/BounceAnimation";
import {
  Wrapper,
  Inner,
  WrapTwoParts,
  FormAndDescription,
  ContactsNeedHelp,
  WrapNamePhone,
  WrapPhone,
  WrapName,
  TextBottomHelp,
  OnMap,
  WrapHelpButton,
  WrapEmail
} from "./needhhelpage-style";
import marker from "./marker.png"

export const NeedHelpPage = () => {
  const [successMessage, setSuccessMessage] = useState(false);
  const [failMessage, setFailedMessage] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setShowAnimation(true);
    emailjs.sendForm('service_703tt8s', 'template_jcyiedj', form.current, 'user_vtAgdf3zaqK3Mf8SVKA8o')
      .then((result) => {
        setShowAnimation(false);
        setSuccessMessage(true);
      },  (error) => {
      setShowAnimation(false);
      setFailedMessage(true)
    });
  };
  if (successMessage) {
    return (
      <div>
        <TextBottomHelp>
          В ближайшее время тебе ответят по email или перезвонят!
        <br/> Помни, что ты не одна. Здесь тебе помогут!
        </TextBottomHelp>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    )
  }

  if (failMessage) {
    return (
      <div>
        <TextBottomHelp>
          Что то пошло не так! Попробуйте еще раз!
        </TextBottomHelp>
      </div>
    )
  }

  return (
    <Wrapper>
      {showAnimation && <BounceAnimation />}
      <Inner>
        <form ref={form} onSubmit={sendEmail}>
          <h2>Нужна помощь</h2>
          <WrapTwoParts>
            <FormAndDescription>
              <p className="main-help-description">Мы бесплатно оказываем помощь беременным женщинам и женщинам с детьми, попавшим в трудную жизненную ситуацию, независимо от наличия документов и регистрации, независимо от национальности и вероисповедания.</p>
              <p className="describe-help">Опишите свою ситуацию. Какая помощь вам нужна?</p>
              <textarea name="texthelp" id="" cols="30" rows="10"></textarea>
              <WrapNamePhone>
                <WrapName>
                  <p>Ваше имя</p>
                  <input type="text" name="namehelp"/>
                </WrapName>
                <WrapPhone>
                  <p>Телефон для связи</p>
                  <input id="phone" name="phonehelp" type="number" required />
                </WrapPhone>
              </WrapNamePhone>
              <div>
                <WrapEmail>
                  <p>Ваш email</p>
                  <input id="herEmail" name="currentEmailHelp" type="email" required />
                </WrapEmail>
              </div>
              <WrapHelpButton>
                <button>Отправить</button>
              </WrapHelpButton>
              <TextBottomHelp>
                Помни, что ты не одна. Здесь тебе помогут!
              </TextBottomHelp>
            </FormAndDescription>

            <ContactsNeedHelp>
              <p className="gray-help-text">Кризисный центр</p>
              <p><a href="tel:+796526298789"> +7 (965) 262-98-78</a></p>
             <p><a href="tel:+79661367557">+7 (966) 136-75-57</a></p>
              <p className="gray-help-text">Наш адрес</p>
              <p>г. Москва,<br/>Николоямская улица, д. 52 стр. 2</p>
              <OnMap>
                {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
                <a href="https://yandex.ru/maps/org/224261856966" target="_blank" rel="noopener nofollow">
                  <img src={marker} alt="ДомДляМамы"/>
                  <p>На карте</p>
                </a>
              </OnMap>

            </ContactsNeedHelp>
          </WrapTwoParts>
        </form>
      </Inner>
    </Wrapper>
  );
};
