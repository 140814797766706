import React, { useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton';
import { Link } from "react-router-dom";
import Axios from "axios";

import {
  Inner,
  Wrapper,
  WrapNews,
  InnerNews,
  WrapItemsNews,
  WrapNavigation
} from "./styled-newsView";

export const NewsList = () => {
  const [hidePreloader, setHidePreloader] = useState(true);
  // Track state for posts, current page and number of pages
  const [posts, setPosts] = useState();
  const [page, setPage] = useState(1);
  const [nrofpages, setNumberofpage] = useState(1);

  // When the page number changes call the api for posts.
  useEffect(() => {
    Axios.get("https://domdliamamy.ru/donation/wp-json/wp/v2/posts", {
      params: { page: page }
    }).then(response => {
      // Store the number of posible pages.
      setNumberofpage(response.headers["x-wp-totalpages"]);
      // Store the posts from the response.
      setPosts(response.data);
      setHidePreloader(false);
    });
  }, [page, setPosts]);

  // Event handler: Decrease page count no lower then 1.
  const handlePrevPage = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto' // You can change this to 'auto' for instant scrolling
    });
    setPage(page - 1 ? page - 1 : 1);
  }
  // Event handler: Increase page count no higher then nrofpages.
  const handleNextPage = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto' // You can change this to 'auto' for instant scrolling
    });
    setPage(page < nrofpages ? page + 1 : nrofpages);
  }

  return (
    <Wrapper>
      <Inner>
        <WrapNews>
          <h5>Новости</h5>
          <InnerNews>
            {hidePreloader &&
              <>
                <WrapItemsNews className="skeletonPreloader">
                  <a href="/">
                    <Skeleton height={370} />
                    <p className="TitleNewsMain"><Skeleton /></p>
                    <p className="dateMain"><Skeleton /></p>
                  </a>
                  <a href="/">
                    <Skeleton height={370} />
                    <p className="TitleNewsMain"><Skeleton /></p>
                    <p className="dateMain"><Skeleton /></p>
                  </a>
                  <a href="/">
                    <Skeleton height={370} />
                    <p className="TitleNewsMain"><Skeleton /></p>
                    <p className="dateMain"><Skeleton /></p>
                  </a>
                  <a href="/">
                    <Skeleton height={370} />
                    <p className="TitleNewsMain"><Skeleton /></p>
                    <p className="dateMain"><Skeleton /></p>
                  </a>
                  <a href="/">
                    <Skeleton height={370} />
                    <p className="TitleNewsMain"><Skeleton /></p>
                    <p className="dateMain"><Skeleton /></p>
                  </a>
                  <a href="/">
                    <Skeleton height={370} />
                    <p className="TitleNewsMain"><Skeleton /></p>
                    <p className="dateMain"><Skeleton /></p>
                  </a>
                  <a href="/">
                    <Skeleton height={370} />
                    <p className="TitleNewsMain"><Skeleton /></p>
                    <p className="dateMain"><Skeleton /></p>
                  </a>
                  <a href="/">
                    <Skeleton height={370} />
                    <p className="TitleNewsMain"><Skeleton /></p>
                    <p className="dateMain"><Skeleton /></p>
                  </a>
                  <a href="/">
                    <Skeleton height={370} />
                    <p className="TitleNewsMain"><Skeleton /></p>
                    <p className="dateMain"><Skeleton /></p>
                  </a>
                </WrapItemsNews>
              </>
            }
            {posts?.map((val, index) => {
              const vremea = (val.date).split('T')[0];
              const newVremea = vremea.split("-").reverse().join("-");
              return (
                <Link to={`/novosti/${val.id}`} key={index}>
                  <WrapItemsNews key={val.id}>
                    <img src={val.fimg_url} alt="Дом для мамы"/>
                    <p className="TitleNewsMain" dangerouslySetInnerHTML={{ __html: val.title.rendered }} />
                    {/*<p className="SubtitleMain" dangerouslySetInnerHTML={{ __html: val.content.rendered }} />*/}
                    <p className="dateMain">{newVremea}</p>
                  </WrapItemsNews>
                </Link>
              )
            })
            }
          </InnerNews>
          <WrapNavigation>
            <button onClick={handlePrevPage}>Назад</button>
            <p>Страница {page} из {nrofpages}</p>
            <button onClick={handleNextPage}>Вперед</button>
          </WrapNavigation>
        </WrapNews>
      </Inner>
    </Wrapper>
  )
};
