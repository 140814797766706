import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 48px 0;
  margin-bottom: 40px;
  @media screen and (max-width: 900px) {
    margin-bottom: 80px;
  }
  h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 64px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
      font-size: 34px;
      line-height: 48px;
    }
    &.twel-zag {
      font-size: 40px;
      line-height: 48px;
      @media screen and (max-width: 900px) {
        font-size: 24px;
        line-height: 34px;
      }
    }
  }
  .norm-text {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: rgb(17, 23, 41);
  }
  .bold-sec-text {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: rgb(103, 116, 137);
  }
  a {
    color: #69BAC1;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
`;

export const WrapRoomImage = styled.div`
text-align: center;
  margin-bottom: 40px;
  img {
    max-width: 100%;
    border-radius: 6px;
  }
`;
