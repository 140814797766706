import styled from "styled-components";
import triangle from "./triangle.png";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 48px 0;
  margin-bottom: 160px;
  @media screen and (max-width: 900px) {
    margin-bottom: 80px;
  }
  h2  {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 64px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
      font-size: 40px;
      line-height: 48px;
    }
  }
  input, textarea {
    &:hover {
      cursor: auto;
    }
  }
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const Start = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #111729;
  .start-title {
    padding-bottom: 18px;
  }
  ul {
    padding-left: 32px;
  }
  ul li {
    padding-bottom: 10px;
  }
  img {
    margin: 10px auto 0 auto;
    max-width: 960px;
    display: inherit;
    @media screen and (max-width: 1000px) {
      max-width: 100%;
    }
  }
  p {
    text-align: center;
    margin-top: 40px;
    background: #D7F8F5;
    border-radius: 12px;
    padding: 40px 23px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #111729;
    margin-bottom: 30px;
  }
`;

export const Doors = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #111729;
  max-width: 760px;
  margin: 40px 0;
`;

export const WrapBottomImg = styled.div`
  margin-top: 40px;
  img {
    margin: 10px auto 0 auto;
    max-width: 960px;
    display: inherit;
    @media screen and (max-width: 1000px) {
      max-width: 100%;
    }
  }
`;

export const WrapSteps = styled.div`
  background: #FBF2F8;
  border-radius: 20px;
  padding: 40px 100px;
  @media screen and (max-width: 1000px) {
    padding: 40px 15px;
  }
`;

export const InnerItem = styled.div`
  position: relative;
  background: #FFFFFF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 24px;
  margin-bottom: 25px;
  &:after {
    position: absolute;
    content: "";
    bottom: -25px;
    left: 36px;
    background:url(${triangle});
    width: 12px;
    height: 25px;
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
`;

export const WrapNumber = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F4D1E7;
  color: #DA5697;
  border-radius: 50%;
  position: absolute;
  top: 24px;
  left: 24px;
`;

export const TitleSteps = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  color: #111729;
  padding-left: 84px;
`;

export const ContentSteps = styled.div`
  margin-top: 21px;
  padding-left: 200px;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #111729;
  span {
    color: #DA5697;
  }
  @media screen and (max-width: 1000px) {
    padding-left: 0;
  }
  ul {
    padding-left: 40px;
    li {
      padding: 10px 0;
    }
  }
`;