import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 48px 0;
  h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 64px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
      font-size: 30px;
      line-height: 38px;
    }
  }
  h6 {
    text-transform: uppercase;
    color: #677489;
    font-family: Mulish;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 16px;
  }
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const WrapLines = styled.div`
  display: flex;
  .roz-set {
    .num-roz {
      color: #da5697;
    }
  }
  .roz-num {
    color: #da5697;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
  }
  .no-roz-num {
    color: rgba(151, 163, 182, 1);
  }
`;

export const FirstLine = styled.div`
  flex: 1;
  padding: 0 4px 0 0;
`;
export const SecondLine = styled.div`
  padding: 0 4px;
  flex: 1;
`;
export const ThirdLine = styled.div`
  padding: 0 0 0 4px;
  flex: 1;
`;

export const Line = styled.div`
  width: 100%;
  height: 6px;
  border-radius: 3px;
  background: rgba(227, 232, 239, 1);
  margin-bottom: 8px;
  &.add-roz {
    background: #da5697;
  }
`;

export const WrapContacts = styled.div`
  margin-top: 40px;
`;

export const WrapNames = styled.div`
  display: flex;
  @media screen and (max-width: 900px) {
    display: block;
  }
  input {
    border: 1px solid rgba(151, 163, 182, 1);
    border-radius: 6px;
    padding: 16px;
    flex: 1;
    @media screen and (max-width: 900px) {
      margin-bottom: 5px;
      width: calc(100% - 30px);
    }
  }
  input:nth-child(2) {
    margin: 0 8px;
    @media screen and (max-width: 900px) {
      margin: 0 0 5px 0;
    }
  }
`;

export const WrapDatePicker = styled.div`
  margin: 16px 0;
  .date-picker {
    border: 1px solid rgba(151, 163, 182, 1);
    border-radius: 6px;
    padding: 16px;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const WrapMobMail = styled.div`
  display: flex;
  position: relative;
  @media screen and (max-width: 900px) {
    display: block;
  }
  input {
    border: 1px solid rgba(151, 163, 182, 1);
    border-radius: 6px;
    padding: 16px;
    flex: 1;
    @media screen and (max-width: 900px) {
      margin-bottom: 5px;
      width: calc(100% - 30px);
    }
  }
  input:nth-child(2) {
    margin: 0 8px;
    @media screen and (max-width: 900px) {
      margin: 0;
    }
  }
  .error-mob {
    position: absolute;
    bottom: -40px;
  }
  .error-mob-em {
    position: absolute;
    bottom: -20px;
  }
`;

export const WrapAbout = styled.div`
  margin-top: 40px;
  .car-type-select {
    border-radius: 6px;
    border: 1px solid #cdd5e0;
    background: #f8fafc;
    padding: 16px 20px;
    &:hover {
      cursor: pointer;
    }
  }
  input[type="checkbox"] {
    cursor: pointer;
  }
`;

export const WrapButtonNext = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    border-radius: 24px;
    background: #da5697;
    padding: 10px 24px 14px 24px;
    color: #fff;
    font-family: Mulish;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    border: none;
    &:hover {
      background: rgb(228, 122, 179);
      cursor: pointer;
    }
    @media screen and (max-width: 900px) {
      margin-top: 40px;
    }
  }
  .no-agree {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const WrapVariantHelps = styled.div`
  margin-top: 40px;
  .time-help {
    padding: 16px 0;
    border-bottom: 1px solid rgba(227, 232, 239, 1);
    label {
      margin-left: 12px;
      font-size: 17px;
    }
    input {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .top-border-help {
    border-top: 1px solid rgba(227, 232, 239, 1);
    @media screen and (max-width: 900px) {
      padding-top: 70px;
    }
  }
  .spc-days {
    margin-left: 20px;
    border: 1px solid rgba(151, 163, 182, 1);
    border-radius: 6px;
    padding: 16px;
    width: calc(100% - 30px);
    margin-top: 20px;
    @media screen and (max-width: 900px) {
      margin-bottom: 5px;
      margin-left: 0;
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

export const WrapDaysToHelp = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: 16px;
  label {
    margin-left: 0 !important;
    margin-right: 10px;
  }
`;

export const WrapHowExact = styled.div`
  margin-top: 40px;
  .checkbox-container-exact {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
`;

export const InnerItemsExact = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    display: block;
  }
  .fifteen-procent {
    width: 50%;
    @media screen and (max-width: 900px) {
      width: 100%;
    }
  }
`;

export const WrapProfesstional = styled.div`
  margin-top: 30px;
`;
export const WrapAboutYou = styled.div`
  margin-top: 40px;
  h6 {
    margin-bottom: 5px;
  }
  .jepl-rate-text {
    color: rgba(17, 23, 41, 1);
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 20px;
  }
`;
export const WrapItemRate = styled.div`
  display: flex;
  position: relative;
  padding: 28px;
  border-bottom: 1px solid rgba(227, 232, 239, 1);
  &.first-nump-wrap {
    border-top: 1px solid rgba(227, 232, 239, 1);
  }
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    padding: 28px 0px;
  }
`;

export const TextRate = styled.div`
  width: 300px;
  @media screen and (max-width: 900px) {
    width: auto;
    margin-bottom: 10px;
  }
`;
export const Nmb = styled.span`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #cdd5e0;
  margin-right: 5px;
  &:hover {
    cursor: pointer;
  }
  &.podsv {
    border-radius: 4px;
    border: 1px solid #69bac1;
    color: #438a92;
    background: #d7f8f5;
  }
  @media screen and (max-width: 900px) {
    width: 30px;
    height: 30px;
  }
`;
export const WrapNumb = styled.div`
  display: flex;
  position: absolute;
  left: 310px;
  top: 14px;
  @media screen and (max-width: 900px) {
    position: relative;
    left: 0;
    top: inherit;
  }
`;

export const WhatAtract = styled.div`
  margin-top: 30px;
`;
export const WrapActiviType = styled.div`
  margin-top: 30px;
`;
export const WrapHowToKnow = styled.div`
  margin-top: 30px;
`;

export const WrapAdreessMetro = styled.div`
  margin-top: 30px;
  display: flex;
  .input-adress {
    border: 1px solid rgba(151, 163, 182, 1);
    border-radius: 6px;
    padding: 16px;
    flex: 1;
    @media screen and (max-width: 900px) {
      margin-bottom: 5px;
      width: calc(100% - 30px);
    }
  }
`;

export const WrapWorkorStudy = styled.div`
  margin-top: 30px;
  display: flex;
  span {
    padding-bottom: 20px;
    display: inline-block;
  }
  input {
    margin-right: 10px;
  }
  label {
    margin: 0 10px 10px 0;
  }
  input {
    border: 1px solid rgba(151, 163, 182, 1);
    border-radius: 6px;
    padding: 16px;
    @media screen and (max-width: 900px) {
      margin-bottom: 5px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .wrap-obraz-inner {
    display: flex;
    flex-direction: column;
  }
`;

export const WrapConsult = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  input {
    width: 100%;
    border: 1px solid rgba(151, 163, 182, 1);
    border-radius: 6px;
    padding: 16px;
    @media screen and (max-width: 900px) {
      margin-bottom: 5px;
      width: calc(100% - 30px);
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

export const WrapPrivlekati = styled.div`
  margin-top: 30px;
  label {
    margin: 0 10px 10px 20px;
  }
  input {
    margin-top: 20px;
    border: 1px solid rgba(151, 163, 182, 1);
    border-radius: 6px;
    padding: 16px;
    @media screen and (max-width: 900px) {
      margin-bottom: 5px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .drugoe-help {
    width: 100%;
    margin-bottom: 30px;
    @media screen and (max-width: 900px) {
      width: calc(100% - 30px);
    }
  }
`;

export const WrapMestoRaboti = styled.div`
  margin-top: 30px;
  display: flex;
  .input-rabota {
    border: 1px solid rgba(151, 163, 182, 1);
    border-radius: 6px;
    padding: 16px;
    flex: 1;
    @media screen and (max-width: 900px) {
      margin-bottom: 5px;
      width: calc(100% - 30px);
    }
  }
`;
