import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 48px 0;
  margin-bottom: 160px;
  @media screen and (max-width: 900px) {
    margin-bottom: 80px;
  }
  h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 64px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
      font-size: 40px;
      line-height: 48px;
    }
  }
  input,
  textarea {
    &:hover {
      cursor: auto;
    }
  }
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const WrapMain = styled.div`
  display: flex;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const LeftOne = styled.div`
  width: 70%;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const WrapMainDescr = styled.div`
  padding-right: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #111729;
  @media screen and (max-width: 900px) {
    padding-right: 0;
    font-size: 17px;
    line-height: 27px;
  }
`;

export const RightOne = styled.div`
  width: 30%;
  margin-top: 107px;
  img {
    position: absolute;
    border-radius: 50%;
    width: 95px;
  }
  @media screen and (max-width: 900px) {
    margin-top: 50px;
    max-width: 310px;
    width: inherit;
  }
`;

export const Wrapname = styled.div`
  font-weight: 900;
  font-size: 17px;
  line-height: 24px;
  color: #111729;
  padding-left: 120px;
`;

export const Wrapdescr = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #677489;
  padding-left: 120px;
`;

export const WrapSecond = styled.div`
  margin: 40px 0 20px 0;
  h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #111729;
    margin-bottom: 23px;
    @media screen and (max-width: 900px) {
      font-size: 28px;
      line-height: 42px;
      text-align: center;
    }
  }
  width: 70%;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;
export const SecondText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #111729;
  margin-bottom: 40px;
  @media screen and (max-width: 900px) {
    font-size: 17px;
    line-height: 27px;
  }
`;

export const WrapPhoto = styled.div``;

export const InnerItemtext = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  color: #111729;
  margin-top: 40px;
  margin-bottom: 24px;
  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;

export const InnerList = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #111729;
  ul {
    max-width: 45%;
  }
  li {
    margin-bottom: 15px;
    padding-left: 20px;
    margin-left: 15px;
  }
  @media screen and (max-width: 900px) {
    ul {
      max-width: 100%;
      margin-top: 40px;
    }
    li {
      font-size: 17px;
      line-height: 27px;
      padding-left: 0;
    }
  }
`;

export const InnerItem = styled.div`
  position: relative;
  border-top: 1px solid #e3e8ef;
  img {
    max-width: 45%;
    position: absolute;
    right: 0;
    top: 40px;
  }
  @media screen and (max-width: 900px) {
    img {
      position: relative;
      top: inherit;
      right: inherit;
      margin: 0 auto;
      display: inherit;
      max-width: 100%;
    }
  }
  .hide-it-vol {
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  &.mb50 {
    @media screen and (max-width: 900px) {
      margin-bottom: 50px;
    }
  }
`;

export const GrayText = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #677489;
  margin-bottom: 10px;
`;

export const WrapAnketa = styled.div`
  position: relative;
  margin-top: 80px;
  border: 1px solid #e3e8ef;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 24px 32px;
  h4 {
    max-width: 740px;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #69bac1;
    span {
      color: rgba(17, 23, 41, 1);
      display: block;
    }
    @media screen and (max-width: 1150px) {
      font-size: 30px;
      line-height: 38px;
      max-width: 690px;
    }
    @media screen and (max-width: 900px) {
      max-width: inherit;
    }
  }
  p {
    padding-top: 20px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #111729;
  }
  button {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    background: #69bac1;
    border-radius: 24px;
    padding: 10px 24px;
    a {
      color: #ffffff;
    }
    border: 0;
    &:hover {
      cursor: pointer;
      background: #7bccd1;
    }
    @media screen and (max-width: 900px) {
      position: relative;
      top: inherit;
      right: inherit;
      margin-top: 50px;
    }
  }
  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;

export const SveaziPhoto = styled.div`
  margin-top: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #111729;
  max-width: 740px;
  a {
    display: inline-block;
    font-weight: bolder;
    font-style: normal;
    font-size: 20px;
    line-height: 30px;
    color: rgb(105, 186, 193);
    @media screen and (max-width: 900px) {
      display: block;
      text-align: center;
    }
  }
`;
