import React from "react";
import {Inner, Wrapper, WrapRoomImage} from "../rooms-styled";
import room from "../rooms-images/10.jpeg";
export const TenRoom = () => {
  return (
    <Wrapper>
      <Inner>
        <h2>Комната отремонтирована на средства, собранные Светланой Лебедевой</h2>
        <WrapRoomImage>
          <img src={room} alt="Дом для мамы"/>
        </WrapRoomImage>
        <br/>
        <br/>
        <p className="norm-text">Светлана - скромный человек с большим сердцем!</p>
        <br/>
        <br/>
        <p className="norm-text">
          Мы искренне благодарим Светлану за помощь в ремонте приюта “Дома для мамы”<span style={{color: "#69BAC1", transform: "translate(6px, 1px)", display: "inline-block"}}>❤</span>
        </p>
      </Inner>
    </Wrapper>
  )
}
