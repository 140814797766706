import React from 'react';
import { CgpStrani } from "../components/cgpPage/cgpStrani";

export const CgpStraniPage = () => {
  return (
    <div>
      <CgpStrani />
    </div>
  );
};
