import React from "react";
import {
  Inner,
  Wrapper,
  Start,
  Doors,
  WrapBottomImg,
  WrapSteps,
  InnerItem,
  WrapNumber,
  TitleSteps,
  ContentSteps
} from "./styled-etapi";
import bg from "./bg.png";
import bgBottom from "./bg-bottom.png";

export const Etapi = () => {
  return(
    <Wrapper>
      <Inner>
        <h2>Этапы работы</h2>
        <Start>
          <div className="start-title">Часто у женщин возникают трудности, которые они не могут преодолеть самостоятельно:</div>
          <ul>
            <li>теряют поддержку близких,</li>
            <li>лишаются крыши над головой и средств к существованию,</li>
            <li>сталкиваются с нарушением своих прав и прав их детей,</li>
            <li>встают перед вопросом сохранения беременности.</li>
          </ul>
          <img src={bg} alt="Дом для Мамы"/>
          <p>"Наш Центр помогает всем женщинам, обратившимся за помощью, бесплатно, независимо от наличия документов и регистрации, национальности и вероисповедания"</p>
          <Doors>
            Двери «Дома для мамы» открыты для всех, кому надо решить подобные проблемы. Помощь оказывается в несколько этапов:
          </Doors>
        </Start>
        <WrapSteps>
          <InnerItem>
            <WrapNumber>1</WrapNumber>
            <TitleSteps>Собеседование</TitleSteps>
            <ContentSteps>
              Это первый шаг к разрешению ситуации. В Центре работают опытные специалисты: социальный работник, психолог, юрист. На собеседовании могут присутствовать администратор приюта и директор «Дома для мамы». Специалисты изучают проблему, выбирают направление помощи, вместе с женщиной намечают шаги, которые необходимо сделать в первую очередь.
              <ul>
                <li>
                  Часто решение, казалось бы, неразрешимых проблем, находится прямо в процессе обсуждения с сотрудниками консультационного центра и ограничивается самой консультацией у психолога или юриста.
                </li>
                <li>
                  Иногда достаточно купить билет для возвращения на родину или помочь с размещением в хостеле на одну-две ночи.
                </li>
                <li>
                  Часть женщин мы направляем в ЦГП (Центр гуманитарной помощи), где они разово или на регулярной основе будут получать продукты питания, одежду, другую гуманитарную помощь.
                </li>
                <li>
                  Для некоторых женщин этого недостаточно, и они направляются в приют.
                </li>
              </ul>
            </ContentSteps>
          </InnerItem>
          <InnerItem>
            <WrapNumber>2</WrapNumber>
            <TitleSteps>Приют. Разработка индивидуальной программы</TitleSteps>
            <ContentSteps>
              Для каждой Женщины, обратившейся за помощью в «Дом для мамы», разрабатывается особый план действий, которые помогут выйти из кризисной ситуации, станут фундаментом обновленной уверенной, счастливой жизни. На этом этапе важно, чтобы женщина не перекладывала ответственность за свою жизнь и жизнь ребенка на других и сотрудничала в поиске выхода из кризисной ситуации со специалистами Центра – психологами, юристами, социальными работниками.
            </ContentSteps>
          </InnerItem>
          <InnerItem>
            <WrapNumber>3</WrapNumber>
            <TitleSteps>Работа со специалистами</TitleSteps>
            <ContentSteps>
              В процессе взаимодействия женщины со специалистами Центра регулярно проводятся консилиумы — обсуждение успехов, возникающих трудностей и дальнейших шагов. Основная задача на этом этапе заключается в том, чтобы помочь женщине социализироваться, выкарабкаться из сложившихся обстоятельств и встать на ноги.
            </ContentSteps>
          </InnerItem>
          <InnerItem>
            <WrapNumber>4</WrapNumber>
            <TitleSteps>Обучение, профориентация</TitleSteps>
            <ContentSteps>
              За время пребывания в «Доме для мамы» женщины получают новые или совершенствуют ранее приобретенные профессиональные навыки. Это могут быть полноценные курсы профессиональной подготовки, творческие и кулинарные мастер-классы, уроки красоты и пр. На данном этапе маме важно почувствовать себя уверенной в том, что она сможет в будущем обеспечивать себя и ребенка.
              За последний год <span>51 женщина</span>, обратившаяся в «Дом для мамы», прошла обучение по профессии или повышению квалификации.
            </ContentSteps>
          </InnerItem>
          <InnerItem>
            <WrapNumber>5</WrapNumber>
            <TitleSteps>Подготовка к самостоятельной жизни</TitleSteps>
            <ContentSteps>
              Команда «Дома для мамы» помогает женщине почувствовать ответственность за свою жизнь, пробудить волю к достижению цели, научиться выполнять необходимые ежедневные дела без посторонней помощи. На этом этапе у мамы формируется правильное, здоровое отношение к себе, детям и к своей жизни.
            </ContentSteps>
          </InnerItem>
        </WrapSteps>
        <WrapBottomImg>
          <img src={bgBottom} alt="Дом для Мамы"/>
        </WrapBottomImg>
      </Inner>
    </Wrapper>
  )
};
