import React from "react";
import {Inner, Wrapper, WrapContent, WrapSocial} from "./psyholog-styled";
import psy from "./psy.jpeg";

export const Psyholog = () => {
  return (
    <Wrapper>
      <Inner>
        <WrapContent>

          <h2>Бондуровская Наталья - наш психолог</h2>

          <WrapSocial>
            <a href="mailto:nat@domdliamamy.ru" className="cont-email">nat@domdliamamy.ru</a>
          </WrapSocial>

          <img src={psy} alt="Дом для мамы"/>

          <p>Имеет высшее образование по специальности «Психология», дополнительное образование по программе «Дошкольная практическая психология», закончила курсы повышения квалификации по программе «Клинико-психологическая профилактика искусственного прерывания беременности».</p>
          <p>Помогает каждой маме найти собственные ресурсы, на которые та сможет опереться.</p>
          <p className="green-block">«Безвыходных ситуаций не бывает, есть ошибки мышления, которые приводят к подобным выводам»</p>
          <p>Впервые узнала о нашем Центре 12 лет назад — увидела сюжет об его открытии и сразу прониклась идеей помощи женщинам с маленькими детьми, оказавшимися в кризисной ситуации. Увидев Марию Михайловну, была потрясена, что такая молодая девушка ведет такой ответственный проект.</p>
          <p>В 2017 году более близко познакомилась с приютом. Организация, в которой работала Наталья Павловна, сотрудничала с «Домом для мамы». Бывая в «домике» (как мы любя называем приют), с порога погружалась в атмосферу любви, принятия и спокойствия, из которой не хотелось уходить. Так и случилось — в 2020 году она стала членом нашей дружной команды.</p>

          <p className="title-about-pages">Какие задачи психолог решает в Центре:</p>
          <ul>
            <li>
              психологическое сопровождение мам и детей
            </li>
            <li>
              участие в первичном собеседовании при поступлении мамы в центр
            </li>
            <li>
              диагностика психологического состояния проживающих в Центре
            </li>
            <li>
              индивидуальное консультирование мам и детей (разовое и долгосрочное)
            </li>
            <li>
              организация групповых тематических, терапевтических занятий
            </li>
            <li>
              участие в работе консилиума специалистов
            </li>
            <li>
              помощь в составлении индивидуальных планов помощи женщинам
            </li>
            <li>
              привлечение к работе волонтеров (психологов, дефектологов и др.), которые могут проводить индивидуальные занятия с ребенком, семьей
            </li>
            <li>
              регулирование отношений между подопечными, предотвращение и улаживание конфликтных ситуаций
            </li>
            <li>
              содействие в установлении контакта и налаживании отношений между мамой и ребенком
            </li>
            <li>
              помощь женщинам в социальной адаптации и профориентации (совместно со специалистом по социальной работе)
            </li>
          </ul>
          <p className="pink-block">338 человек получили психологическую консультацию в Центре за последний год</p>
          <p>
            Если вам необходима консультация администратора приюта, вы можете обратиться в наш Центр по тел.<a href="tel:+79652629878" className="phon-black">+7 (965) 262-98-78</a> или написать на адрес электронной почты <a href="mailto:cat@domdliamamy.ru" className="cont-emil">cat@domdliamamy.ru</a>
          </p>
        </WrapContent>
      </Inner>
    </Wrapper>
  )
};
