import React from 'react';
import { NewsList } from "../components/newsList";

export const News = () => {
  return (
    <div>
      <NewsList />
    </div>
  );
};
