import styled from "styled-components";
import arrow from "./arrow.png";


export const WrapLogoPartners = styled.div`
  a {
    max-width: 200px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    display: inline-block;
    width: 100%;
    margin: 0 10px 40px 10px ;
    @media screen and (max-width: 900px) {
      max-width: 150px;
    }
  }
  margin-bottom: 10px;
  @media screen and (max-width: 900px) {
    margin-bottom: 40px;
  };
  .slick-prev:before {
    content: url(${arrow});
    filter: grayscale(1);
    font-size: 30px;
    opacity: 0.6;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%) rotate(90deg);
    z-index: 98;
    padding: 10px;
    left: -5px;
    &:hover {
      cursor: pointer;
    }
  }
  .slick-next:before {
    content: url(${arrow});
    filter: grayscale(1);
    font-size: 30px;
    opacity: 0.6;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%) rotate(-90deg);
    z-index: 98;
    padding: 10px;
    right: -5px;
  }
  @media screen and (max-width: 900px) {
    padding: 0 10px;
  }
  .forAbsoluteLabel {
    padding-top: 40px;
    @media screen and (max-width: 900px) {
      padding-top: 30px;
    }
  }
  .firstImgSl {
    transform: translateY(49px);
    @media screen and (max-width: 900px) {
      transform: translateY(36px);
    }
  }
  .secondImgSl {
    transform: translateY(90px);
    @media screen and (max-width: 900px) {
      transform: translateY(72px);
    }
  }
`;