import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 0px 0;
  @media screen and (max-width: 900px) {
    margin-bottom: 80px;
  }
  h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 64px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
      font-size: 40px;
      line-height: 48px;
    }
  }
  input,
  textarea {
    &:hover {
      cursor: auto;
    }
  }
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const WrapForm = styled.div`
  background: #f8fafc;
  padding: 40px 0 55px 0;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
  position: relative;
  &:after {
    position: absolute;
    z-index: -1;
    content: "";
    height: 100%;
    width: 100vw;
    background: #f8fafc;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Info = styled.div`
  max-width: 45%;
  h5 {
    white-space: nowrap;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #111729;
    margin-bottom: 24px;
    @media screen and (max-width: 1150px) {
      white-space: inherit;
    }
    @media screen and (max-width: 900px) {
      text-align: center;
      white-space: inherit;
    }
  }
  @media screen and (max-width: 900px) {
    max-width: 100%;
  }
`;

export const FormParners = styled.div`
  form {
    background: #ffffff;
    padding: 32px 24px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 461px;
    border-radius: 20px;
    @media screen and (max-width: 446px) {
      max-width: 100%;
    }
  }
  input {
    width: calc(100% - 48px);
    margin-bottom: 20px;
    border: 1px solid #cdd5e0;
    border-radius: 6px;
    padding: 14px 23px;
    &:focus {
      outline: none !important;
      border: 1px solid #69bac1;
    }
  }
  button {
    color: #fff;
    padding: 14px 20px;
    background: #da5697;
    border-radius: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    border: none;
    width: 100%;
    margin-top: 34px;
    &:hover {
      cursor: pointer;
      background: #e47ab3;
    }
    &:active {
      background: #c93c76;
    }
  }
  transform: translateY(-100px);
  @media screen and (max-width: 900px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin-top: 40px;
    transform: translateY(0px);
  }
  overflow: hidden;
`;

export const WrapTwoUl = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 24px;
  div {
    max-width: 45%;
    @media screen and (max-width: 480px) {
      max-width: 49%;
    }
  }
  @media screen and (max-width: 1150px) {
    padding: 0 20px;
  }
  ul li {
    white-space: nowrap;
    font-size: 20px;
    line-height: 30px;
    &:nth-child(1) {
      padding-bottom: 10px;
    }
    @media screen and (max-width: 1150px) {
      white-space: inherit;
    }
    @media screen and (max-width: 900px) {
      font-size: 18px;
    }
  }
`;
export const TextInfo = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 56px;
  color: #111729;
  margin-bottom: 24px;
  @media screen and (max-width: 1150px) {
    font-size: 30px;
    line-height: 46px;
  }
  @media screen and (max-width: 1150px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const BottoomText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #111729;
  white-space: nowrap;
  @media screen and (max-width: 1150px) {
    white-space: inherit;
    text-align: center;
  }
`;

export const TextForm = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #111729;
  a {
    color: rgba(105, 186, 193, 1);
  }
  &:hover {
    cursor: pointer;
  }
`;

export const TitleForm = styled.div`
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  color: #111729;
  margin-bottom: 17px;
`;

export const ThankMessage = styled.div`
  padding: 60px 0;
  font-size: 30px;
  font-style: italic;
  line-height: 40px;
  color: #69bac1;
  margin-bottom: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  a {
    color: #69bac1;
    text-decoration: underline;
  }
  @media screen and (max-width: 900px) {
    padding: 0;
    font-size: 24px;
    line-height: 30px;
  }
`;
