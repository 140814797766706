import React from "react";
import {
  Wrapper,
  Inner,
  WrapContacts,
  WrapRecvizits,
  LeftPart,
  RightPart, LeftSpan
} from "./contacti-styled";



export const Contacti = () => {
  return (
    <Wrapper>
      <Inner>
       <h2>Контакты</h2>
        <WrapContacts>
          <LeftPart>
            <a href="tel:+79652629878" className="cont-green">+7 (965) 262-98-78</a><br/>
            <LeftSpan>Круглосуточно</LeftSpan><br/><br/>
            <a href="tel:+79661367557" className="cont-black mb12">+7 (966) 136-75-57</a>
            <a href="mailto:info@domdliamamy.ru" className="cont-email">info@domdliamamy.ru</a>




            <p className="cont-gray">Адрес Офиса</p>
            <p className="cont-details">109004, г. Москва, Николоямская улица, д. 52, стр. 2</p>


            <p className="cont-gray">Время работы</p>
            <p className="cont-details">Рабочие дни 09:00–19:00</p>

            <p className="cont-gray">Юридический адрес</p>
            <p className="cont-details">109004, г. Москва, ул. Станиславского, д. 22, стр.1</p>
          </LeftPart>
          <RightPart>
            <div style={{ position: "relative", overflow: "hidden" }}><a
              href="https://yandex.ru/maps/213/moscow/?utm_medium=mapframe&utm_source=maps"
              style={{ color: "#eee", fontSize: "12px", position: "absolute", top: "0"}}>Москва</a><a
              href="https://yandex.ru/maps/213/moscow/house/nikoloyamskaya_ulitsa_52s2/Z04YcAFiTUIHQFtvfXt1d3tlYQ==/?ll=37.662470%2C55.746766&utm_medium=mapframe&utm_source=maps&z=17.05"
              style={{color: "#eee", fontSize: "12px", position: "absolute", top: "14px"}}>Николоямская улица, 52с2 — Яндекс Карты</a>
              <iframe src="https://yandex.ru/map-widget/v1/-/CCUZ4Bg-KD" width="100%" height="400" frameBorder="1"
                      allowFullScreen style={{ position: "relative" }} title="ДомдляМамы"></iframe>
            </div>
          </RightPart>
        </WrapContacts>

        <WrapRecvizits>
            <h3>Реквизиты</h3>
          <p>Сокращенное наименование: АНО «Дом для мамы»</p>
            <p>Юридический адрес: 109004, г. Москва, ул. Станиславского, д. 22, стр.1</p>
            <p>Фактический адрес: 109004, г. Москва, ул. Станиславского, д. 22, стр.1</p>
            <p>Контактный телефон: 8 (499) 350-52-77</p>
            <p>Учетный номер: 7714058899,</p>
            <p>ИНН: 9709050791,</p>
            <p>КПП: 770901001,</p>
            <p>ОГРН: 1197700009396,</p>
            <p>Р/с 40703810338000014560</p>
            <p>в Дополнительном офисе № 01775 Московского банка ПАО Сбербанк,</p>
            <p>К/с 30101810400000000225 в ГУ Банка России по ЦФО,</p>
            <p>БИК 044525225</p>
            <p>ОКВЭД 87.90</p>
        </WrapRecvizits>
      </Inner>
    </Wrapper>
  )
};
