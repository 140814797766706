import React from "react";
import {
  Wrapper,
  Inner,
  WrapInner,
} from "./consulitatsionniiTsentrPage-styled";
import {Link} from "react-router-dom";
import {InnerDivs, NeedHelpArea, WrapInnerDivs, WrapPingNumber} from "../priutPage/priutPage-styled";
import consult from "./cons_tsentr.jpg";


export const ConsulitatsionniiTsentrPage = () => {
  return (
    <Wrapper>
      <Inner>
        <WrapInner>
          <h2>Консультационный центр</h2>
          <img src={consult} alt="Домдлямамы" style={{ borderRadius: "12px" }} />
          <p>В «Доме для мамы» создан Консультационный центр, в который входят социальный работник, психолог, юрист. На разных этапах к его работе подключаются директор Центра помощи и администратор приюта. При первичном собеседовании, специалисты изучают проблему, выбирают направление помощи, вместе с женщиной намечают шаги, которые необходимо сделать в первую очередь.</p>
          <h3>Каждый месяц в среднем:</h3>
          <br/>
          <WrapPingNumber>
            <WrapInnerDivs>
              <InnerDivs>
                <span>90+</span>
                <p>человек получают консультацию специального работкника</p>
              </InnerDivs>
              <InnerDivs>
                <span>35+</span>
                <p>человек получают консультацию психолога</p>
              </InnerDivs>
            </WrapInnerDivs>
            <WrapInnerDivs>
              <InnerDivs>
                <span>800+</span>
                <p>звонков принимают дежурные центра</p>
              </InnerDivs>
              <InnerDivs>
                <span>45+</span>
                <p>человек получают консультацию юриста</p>
              </InnerDivs>
            </WrapInnerDivs>
          </WrapPingNumber>

          <p>Часто решение, казалось бы, неразрешимых проблем, находится прямо в процессе обсуждения с сотрудниками консультационного центра и ограничивается самой консультацией у психолога или юриста.</p>
          <p>При нахождении в приюте, для каждой женщины разрабатывается особый план действий, который поможет выйти из кризисной ситуации, станет фундаментом обновленной уверенной, счастливой жизни…</p>
          <p>Специалисты Консультационного центра вместе с женщинами регулярно проводятся консилиумы — обсуждение успехов,  возникающих трудностей и дальнейших шагов. Это помогает женщинам социализироваться, встать на ноги.</p>
          <p>Команда «Дома для мамы» помогает женщине почувствовать ответственность за свою жизнь, пробудить волю к достижению цели, научиться выполнять необходимые ежедневные дела без посторонней помощи. При этом,  у мамы формируется правильное, здоровое отношение к себе, детям и к своей жизни.</p>
        </WrapInner>
        <NeedHelpArea>
          <p>Помни, что ты не одна. Здесь тебе помогут!</p>
          <Link to="/nugnapomosi">
            Нужна помощь
          </Link>
        </NeedHelpArea>
      </Inner>
    </Wrapper>
  )
};
