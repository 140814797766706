import React from "react";
import { Inner,
  Wrapper,
  WrapSocial,
  WrapContent
} from "./zamDir-styled";
import zamdir from "./zamdirectora.jpeg";

export const ZamDir = () => {
  return (
    <Wrapper>
      <Inner>
        <WrapContent>

          <h2>Фоминцева Екатерина - наш заместитель директора, администратор приюта</h2>

          <WrapSocial>
            <a href="mailto:cat@domdliamamy.ru" className="cont-email">cat@domdliamamy.ru</a>
          </WrapSocial>

          <img src={zamdir} alt="Дом для мамы"/>

          <p>Имеет колоссальный опыт работы с людьми. Была руководителем муниципальной аптечной сети, 30 лет трудилась в сфере здравоохранения. Всегда проявляла участие к людям в сложных ситуациях, помогала. Принимала активное участие в общественной жизни района, где проживала (Томская область).</p>
          <p>В работе требовательна, с большим желанием оказать качественную помощь относится к каждой женщине, обратившейся в «Дом для мамы».</p>
          <p className="green-block">«Помни, что ты не одна в этом мире! Всегда найдётся поддержка и помощь!»</p>
          <p>В 2020 году, во время пандемии, пришла работать на должность администратора приюта. С центром познакомилась как волонтер, проводила мастер-классы для проживающих в приюте мам и детей.</p>
          <p>Екатерина Юрьевна — человек с активной жизненной позицией.</p>
          <p className="title-about-pages">Какие задачи решает администратор Центра:</p>
          <ul>
            <li>
              внутренняя жизнь приюта (все бытовые и организационные вопросы)
            </li>
            <li>
              участие в консилиумах, принятии решений о заселении в приют мамы
            </li>
            <li>
              ведение документации, заключение договоров с подопечными
            </li>
            <li>
              контроль за соблюдением санитарных норм и правил внутреннего распорядка приюта
            </li>
            <li>
              проведение ежедневных утренних встреч со всеми проживающими (обсуждение планов на день, обязанностей, текущих вопросов, расписания дня и т.п.)
            </li>
            <li>
              организация приема и выдачи гуманитарной помощи, заказ и распределение продуктов, средств гигиены между мамами
            </li>
            <li>
              регулирование отношений между подопечными, предотвращение и улаживание конфликтных ситуаций (совместно с психологом)
            </li>
            <li>
              организация мастер-классов, выездов подопечных из Центра, прием гостей в Центре
            </li>
          </ul>
          <p className="pink-block">В коллективе Екатерину Юрьевну называют «Большая мама для всех»</p>
          <p>
            Если вам необходима консультация администратора приюта, вы можете обратиться в наш Центр по тел.<a href="tel:+79652629878" className="phon-black">+7 (965) 262-98-78</a> или написать на адрес электронной почты <a href="mailto:cat@domdliamamy.ru" className="cont-emil">cat@domdliamamy.ru</a>
          </p>
        </WrapContent>
      </Inner>
    </Wrapper>
  )
};
