import React from "react";
import {Inner, Wrapper, WrapRoomImage} from "../rooms-styled";
import novard from "../firstRoom/novard.svg";
import room from "../rooms-images/06.jpeg";
export const SixRoom = () => {
  return (
    <Wrapper>
      <Inner>
        <h2>Комната отремонтирована на средства ГК «Новард»</h2>
        <WrapRoomImage>
          <img src={room} alt="Дом для мамы"/>
        </WrapRoomImage>
        <div style={{textAlign: "center"}}>
          <a href="https://novard.ru/" target="_blank" rel="noopener noreferrer">
            <img src={novard} alt="Дом для мамы"/>
          </a>
        </div>
        <br/>
        <br/>
        <p className="bold-sec-text">Группа компаний «Новард».<br/>
          Российский бизнес 1989 года создания.
        </p>
        <br/>
        <br/>

        <p className="norm-text">
          Милосердие – одна из ценностей компании, а благотворительность – возможность продвижения корпоративных ценностей, как среди сотрудников, так и в обществе.<br/><br/>

          С момента создания «Дома для мамы» Группа компаний перечисляет средства на проживание в комнате для одной мамы с ребенком. Наши сотрудники хорошо знакомы с проектом: приезжают сюда волонтерами для проведения мастер-классов, передают гуманитарные посылки.<br/><br/>

          Компания внесла вклад в ремонт приюта, так как считает Кризисный центр «Дом для мамы» одним из самых прозрачных, авторитетных, эффективных проектов в стране в поддержку женщин с детьми.<br/><br/>
          Рады быть причастными большому доброму делу. Узнать о компании больше: <br/> <a href="https://novard.ru/" target="_blank" rel="noopener noreferrer">https://novard.ru/</a>
        </p>

      </Inner>
    </Wrapper>
  )
}
