import React from "react";
import {Inner, Wrapper, WrapRoomImage} from "../rooms-styled";
import room from "../rooms-images/05.jpeg";
// import insta from "../instagram.svg";
export const FiveRoom = () => {
  return (
    <Wrapper>
      <Inner>
       <h2>Комната отремонтирована на средства, собранные Никой Кидман и ее командой</h2>
        <WrapRoomImage>
          <img src={room} alt="Дом для мамы"/>
        </WrapRoomImage>
        {/*<div style={{textAlign: "center"}}>*/}
        {/*  <a href="https://www.instagram.com/nikakidman/" target="_blank" rel="noreferrer" style={{width: "80px"}}>*/}
        {/*    <img src={insta} alt="Дом для мамы" style={{width: "80px"}}/>*/}
        {/*  </a>*/}
        {/*</div>*/}
        <br/>
        <br/>
        <p className="bold-sec-text">Ника Кидман:</p>
        <br/>
        <br/>

        <p className="norm-text">
          Я не являюсь человеком, который при любой ситуации тут же бежит кого-то спасать… тем, кто первый приходит, когда кто-то в беде. Я не такой человек. Но я очень хорошо знаю и понимаю ситуации, где невозможно справиться самому порой, или когда очень очень трудно, с очень большими потерями пройти какой-то участок жизни.

        <br/>
        <br/>

          И мне очень важно поддерживать мам с детьми. Несмотря на то, что у меня нет детей, я не работаю с детьми, у меня даже среди окружения мало мам. Но мне очень важно поддерживать именно их, потому что это такой очень недооцененный труд - быть мамой. И дети - это, кажется, так легко. На самом деле - нет.

        <br/>
        <br/>
          Это для меня такие мамы, которые не бросают детей, которые несмотря ни на что борются за то, чтобы им было хорошо, и детям, и мамам, стараются, прикладывают все возможные силы… Вот это те, кому хочется помочь. Те, кому хочется немного облегчить путь, который итак очень очень трудный. Даже без многих трагичных историй, которые случаются с подопечными Домика для мамы. Путь сам по себе трудный, а если наложить все препятствия, которые могут возникнуть…



          <br/>
          <br/>
          Мне важно помогать. Немного хотя бы облегчить часть пути. Чтобы женщины, оказавшиеся в беде, могли найти поддержку в приюте, могли уходить из мест, где им плохо, получать помощь и двигаться в сторону места, где им хорошо и безопасно.

        </p>



      </Inner>
    </Wrapper>
  )
}
