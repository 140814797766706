import React, {useEffect, useState} from "react";
import {HelpTypes, Inner, WrapCentriView, WrapNavigation, Wrapper, WrapSearch, WrapShowOnMap} from "./styled-centri";
import Axios from "axios";
import {MapCenter} from "./ymap";


export const CentrStrani = () => {
  const [centri, setCentri] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showMap, setShowMap] = useState(false);
  const [rerun, setRerun] = useState(false);

  useEffect(() => {
    // fetch("https://domdliamamy.ru/donation/wp-json/wp/v2/custom-post/?per_page=12")
    fetch("https://domdliamamy.ru/donation/wp-json/wp/v2/custom-post")
      .then((response) => response.json())
      .then((data) => setCentri(data))
      .catch((error) => console.log(error));
    setRerun(false);
  }, [rerun]);


  // Pagination
  const [page, setPage] = useState(1);
  const [nrofpages, setNumberofpage] = useState(1);

  useEffect(() => {
    if (searchQuery.length === 0) {
      Axios.get("https://domdliamamy.ru/donation/wp-json/wp/v2/custom-post", {
        params: {page: page}
      }).then(response => {
        // Store the number of posible pages.
        setNumberofpage(response.headers["x-wp-totalpages"]);
        // Store the posts from the response.
        setCentri(response.data);
      });
    }
  }, [page, setCentri, searchQuery]);

  // Event handler: Decrease page count no lower then 1.
  const handlePrevPage = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto' // You can change this to 'auto' for instant scrolling
    });
    setPage(page - 1 ? page - 1 : 1);
  }
  // Event handler: Increase page count no higher then nrofpages.
  const handleNextPage = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto' // You can change this to 'auto' for instant scrolling
    });
    setPage(page < nrofpages ? page + 1 : nrofpages);
  }

  //  /pagination

  // Search


  const handleSearch = () => {
    fetch(`https://domdliamamy.ru/donation/wp-json/wp/v2/custom-post?per_page=400`)
      .then(response => response.json())
      .then(data => {

        const filterArrayByLocation = (array, searchString) => {
          return array.filter(obj =>
            obj.location.toLowerCase().includes(searchString.toLowerCase())
          );
        }

        const searchResult = filterArrayByLocation(data, searchQuery);
        setCentri(searchResult);
      })
      .catch(error => console.error('Error fetching data:', error));
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // Perform search or any other action here
      handleSearch();
    }
  };

  // /search

  const handleShowMap = (index) => {
    console.log('index', index);
    window.scrollTo({
      top: 0,
      behavior: 'auto' // You can change this to 'auto' for instant scrolling
    });
    !showMap && setShowMap(true);
    setCentri([centri[index]]);
  }
  // console.log('centri', centri);
  return (
    <Wrapper>
      <Inner>

        <div><h2>Центры Страны</h2></div>
        <br/>
        <br/>
        <h3>Страница находится в разработке</h3>
        <br/>
        <br/>
        <div style={{display: "none"}}>
          <p className="help-grey-text" style={{opacity: 0}}>* Список центров дополняется</p>
          <MapCenter rerun={rerun} setRerun={setRerun} sendCentOne={centri?.length === 1 ? centri : []}
                     searchQuery={searchQuery} searchedCentri={centri} showMap={showMap} setShowMap={setShowMap}/>
          <br/>
          <br/>
          <WrapSearch>
            <input
                type="text"
                placeholder="Ведите регион или название центра"
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            <button onClick={handleSearch}>Поиск организации</button>
          </WrapSearch>
          <>
            {
                centri.length === 0 && <>
                  <p>По вашему запросу нет результатов.</p>
                  <p>Старайтесь использовать только ключевые слова: Область / город / улица</p>
                </>
            }
          </>
          {
            centri?.map((item, index) => {
              return (
                  <WrapCentriView key={index}>
                    <p>Название организации: {item.org_name}</p>
                    <p>Адрес: {item.location}</p>
                    <HelpTypes>
                      <p>Направления помощи: </p>
                      <ul>
                        {item.help_types.split(',').map((help_type, index) => (
                            <li key={index}>{help_type.trim()}</li>
                        ))}
                      </ul>
                    </HelpTypes>
                    {item?.phone?.length !== 0 && <>
                      <HelpTypes>
                        <p>Телефон: </p>
                        <ul>
                          {item.phone.split(',').map((help_type, index) => (
                              <li key={index}>{help_type.trim()}</li>
                          ))}
                        </ul>
                      </HelpTypes>
                    </>}

                    {item?.email?.length !== 0 && <>
                      <HelpTypes>
                        <p>Email: </p>
                        <ul>
                          {item.email.split(',').map((help_type, index) => (
                              <li key={index}>{help_type.trim()}</li>
                          ))}
                        </ul>
                      </HelpTypes>
                    </>}

                    {item?.website_org?.length !== 0 && <>
                      <HelpTypes>
                        <p>Веб-сайт:</p>
                        <ul>
                          {item.website_org.split(',').map((help_type, index) => (
                              <li key={index}><a href={help_type.trim()} target="_blank"
                                                 rel="noopener noreferrer">{help_type.trim()}</a></li>
                          ))}
                        </ul>
                      </HelpTypes>
                    </>}

                    <WrapShowOnMap>
                      <div onClick={() => handleShowMap(index)}>Показать на карте</div>
                    </WrapShowOnMap>
                  </WrapCentriView>
              )
            })
          }
          {
              searchQuery.length === 0 &&
              <WrapNavigation>
                <button onClick={handlePrevPage}>Назад</button>
                <p>Страница {page} из {nrofpages}</p>
                <button onClick={handleNextPage}>Вперед</button>
              </WrapNavigation>

          }
        </div>
      </Inner>
    </Wrapper>
  )

}
