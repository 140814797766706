import React from 'react';
import { Volonteri } from "../components/volonteri";

export const Volunteers = () => {
  return (
    <>
      <Volonteri />
    </>
  );
};
