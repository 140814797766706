import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0 48px 0;
  margin-bottom: 160px;
  @media screen and (max-width: 900px) {
    margin-bottom: 80px;
  }
  h2  {
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 64px;
    color: #111729;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
      margin-top: 40px;
      font-size: 40px;
      line-height: 48px;
    }
  }
  input, textarea {
    &:hover {
      cursor: auto;
    }
  }
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const VideoView = styled.div`
  .v-video {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: #111729;
    padding-top: 35px;
    @media screen and (max-width: 900px) {
      font-size: 20px;
      line-height: 28px;
      margin: 28px 0 20px 0;
      padding-top: 0;
    }
  }
`;

export const WrapButton = styled.div`
  a {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: #69BAC1;
  }
  img {
    transform: translate(-2px, 3px);
    width: 16px;
  }
`;

export const ThreeItemsVideo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  
  .videoArrayOne {
      width: 30%;
      margin-bottom: 40px;
  }
  p {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    color: #111729;
    margin-top: 12px;
  }
  @media screen and (max-width: 900px) {
    flex-wrap: inherit;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    .videoArrayOne {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;

export const Pagination = styled.div`
  text-align: center;
  margin-top: 0;
  div {
    display: inline-block;
    padding-bottom: 20px;
  }

  a {
    color: #000;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid transparent;
    margin: 0 10px;
  }
  a:hover {
    border-bottom: 2px solid #69BAC1;
  }
  .inactive-pagination {
    pointer-events: none;
    border-bottom: 2px solid #69BAC1;
    color: #69BAC1;
    &:hover {
      cursor: default;
    }
  }
`;