import React, { useState } from "react";
import {
  Wrapper,
  Inner,
  WrapInner,
  WrapListText,
  WrapRezult,
  RezultTitle,
  PinkPart,
  GreenPart,
  InnerDivs,
  WrapInnerDivs,
  InnerDivsFullWidth,
  InnerGreen,
  WrapCounters,
  FirstCount,
  SecondCount,
  BottomText,
  ShowHideSection,
  WrapTopHide,
  WrapButton,
  WrapListShowed,
  CentriStraniWrap
} from "./napravlenia-styled";

import gray from "./deeatelinosti.jpg";
import smallMap from "./smallMap.png";
import {Link} from "react-router-dom";


export const NapravleniaPage = () => {
  const [showSection, setShowSection] = useState(false);
  return (
    <Wrapper>
      <Inner>
        <WrapInner>
          <h2>Направления деятельности</h2>
          <WrapListText>
            <span>1</span>
            <p>Социальная поддержка и защита беременных женщин и женщин с детьми, оказавшихся в трудной жизненной ситуации.</p>
          </WrapListText>
          <WrapListText>
            <span>2</span>
            <p>Срочная и долговременная помощь мамам: материальная, юридическая,  психологическая медицинская и др.</p>
          </WrapListText>
          <WrapListText>
            <span>3</span>
            <p>Сбор средств: денежных, продуктов питания, одежды и пр., для помощи нуждающимся женщинам, детям и семьям.</p>
          </WrapListText>
          <WrapListText>
            <span>4</span>
            <p>Оказание гуманитарной помощи нуждающимся.</p>
          </WrapListText>


          <img src={gray} alt="Дом для мамы" style={{borderRadius: "6px"}} />


          <WrapListText>
            <span>5</span>
            <p>Организация добровольческой (волонтерской) помощи.</p>
          </WrapListText>
          <WrapListText>
            <span>6</span>
            <p>Содействие укреплению престижа и роли семьи в обществе; защите материнства, детства и отцовства; профилактике социально опасных форм поведения и охране здоровья граждан.</p>
          </WrapListText>
          <WrapListText>
            <span>7</span>
            <p>Оказание методической, информационной поддержки и содействие в открытии реабилитационных центров для оказания социальной и иной помощи беременным женщинам и матерям с детьми, попавшим в трудную жизненную ситуацию.</p>
          </WrapListText>
        </WrapInner>
        <RezultTitle>
          Результаты 12 лет работы нашего Центра
        </RezultTitle>
        <WrapRezult>
          <PinkPart>
            <WrapInnerDivs>
              <InnerDivs>
                <span>73 500+</span>
                <p>человек получили гуманитарную помощь</p>
              </InnerDivs>
              <InnerDivs>
                <span>16 846</span>
                <p>жизней спасено в результате работы по профилактике абортов</p>
              </InnerDivs>
            </WrapInnerDivs>
            <WrapInnerDivs>
              <InnerDivs>
                <span>217</span>
                <p>детей родилось во время проживания женщин в Центре</p>
              </InnerDivs>
              <InnerDivs>
                <span>850+</span>
                <p>мамы и ребёнка проживали в Центре «Дом для мамы»</p>
              </InnerDivs>
            </WrapInnerDivs>
            <WrapInnerDivs>
              <InnerDivsFullWidth>
                <span>120-125</span>
                <p>женщин ежемесячно получают социально-психологическую, юридическую и духовную помощь</p>
              </InnerDivsFullWidth>
            </WrapInnerDivs>
          </PinkPart>
          <GreenPart>
            <InnerGreen>
              <img src={smallMap} alt="Дом для мамы"/>
              <WrapCounters>
                <FirstCount>
                  <span>86</span>
                  <p>приютов</p>
                </FirstCount>
                <SecondCount>
                  <span>266</span>
                  <p>центра гуманитарной помощи</p>
                </SecondCount>
              </WrapCounters>
              <BottomText>открыто по всей России при поддержке Центра</BottomText>
              <CentriStraniWrap>
                <Link to="/krizisnye-centry">
                  Центры Страны
                </Link>
              </CentriStraniWrap>
            </InnerGreen>
          </GreenPart>
        </WrapRezult>
        <WrapInner>
          <ShowHideSection>
            <WrapTopHide>

              <p className="zagHideS pr-mobile">Те же направления, которыми занимается наш Кризисный центр</p>
              <p className="subZagS">в соответствии с формулировками классификатора направлений деятельности некоммерческих организаций</p>

              <WrapButton onClick = {() => setShowSection(!showSection)}>
                {!showSection ?
                  <svg fill="none" viewBox="0 0 24 24">
                    <g clipPath="url(#a-dfdfd)">
                      <path fill="#69BAC1" d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2h6Z"/>
                    </g>
                    <defs>
                      <clipPath id="a-dfdfd">
                        <path fill="#fff" d="M0 0h24v24H0z"/>
                      </clipPath>
                    </defs>
                  </svg>
                  :
                  <svg fill="none" viewBox="0 0 24 24">
                    <path transform="translate(5, 11)" fill="#69BAC1" d="M0 0h14v2H0V0Z"/>
                  </svg>
                }
              </WrapButton>

              {showSection &&
                <WrapListShowed>

                  <p className="list-title-nap">1. Социальное обслуживание, социальная поддержка и защита граждан</p>
                  <p className="list-item-nap"><span>1.15.</span> содействие развитию профессиональных компетенций и поддержанию уровня вовлеченности работников и добровольцев организаций, осуществляющих деятельность в социальной сфере;</p>
                  <p className="list-item-nap"><span>1.16.</span> информационная, консультационная, методическая, образовательная поддержка социально ориентированных некоммерческих организаций, предоставляющих услуги в социальной сфере, по вопросам, связанным с оказанием таких услуг;</p>
                  <p className="list-item-nap"><span>1.17.</span> развитие сети некоммерческих организаций, предоставляющих услуги в социальной сфере, в том числе с масштабированием успешных практик;</p>

                  <p className="list-title-nap">2. Охрана здоровья граждан, пропаганда здорового образа жизни</p>
                  <p className="list-item-nap"><span>2.2.</span>профилактика курения, алкоголизма, наркомании и иных опасных для человека зависимостей, содействие снижению количества людей, подверженных таким зависимостям;</p>
                  <p className="list-item-nap"><span>2.9.</span> поддержка и пропаганда практик здорового образа жизни, правильного питания и сбережения здоровья; </p>

                  <p className="list-title-nap">3. Поддержка семьи, материнства, отцовства и детства</p>
                  <p className="list-item-nap"><span>3.1.</span> укрепление института семьи и семейных ценностей; </p>
                  <p className="list-item-nap"><span>3.2.</span> профилактика социального сиротства, в том числе раннее выявление семейного неблагополучия и организация оказания всесторонней помощи; </p>
                  <p className="list-item-nap"><span>3.3.</span> социальная адаптация детей-инвалидов, поддержка семей с детьми-инвалидами, родителей с ограниченными возможностями здоровья; </p>
                  <p className="list-item-nap"><span>3.6.</span> профилактика домашнего насилия, жестокого обращения с детьми; </p>
                  <p className="list-item-nap"><span>3.12.</span> реализация партнерских проектов по предотвращению семейного неблагополучия, защите прав и интересов детей; </p>

                  <p className="list-title-nap">13. Развитие институтов гражданского общества</p>
                  <p className="list-item-nap"><span>13.2.</span> выявление, обобщение и распространение лучших практик деятельности некоммерческих организаций, популяризация такой деятельности, масштабирование успешных социальных технологий;</p>
                  <p className="list-item-nap"><span>13.3.</span> расширение практики взаимодействия государственных органов, органов местного самоуправления и некоммерческих неправительственных организаций;</p>
                  <p className="list-item-nap"><span>13.4.</span> развитие благотворительности;</p>
                  <p className="list-item-nap"><span>13.5.</span> развитие добровольчества (волонтерства);</p>
                  <p className="list-item-nap"><span>13.7.</span> развитие некоммерческих неправительственных организаций, оказывающих финансовую, имущественную, информационную, консультационную, образовательную, методическую и иную поддержку деятельности других некоммерческих организаций;</p>
                  <p className="list-item-nap"><span>13.9.</span> содействие деятельности по производству и распространению социальной рекламы.</p>

                </WrapListShowed>}
            </WrapTopHide>

          </ShowHideSection>
        </WrapInner>
      </Inner>
    </Wrapper>
  )
};
