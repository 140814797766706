import React from "react";
import {
  Inner,
  Wrapper
} from "./video-youtube-two-styled";
import {Pagination, ThreeItemsVideo, VideoView, WrapButton} from "../VideoYoutube/video-youtube-styled";
import arrow from "../VideoYoutube/arrow.png";
import {Link} from "react-router-dom";
import YouTube from "react-youtube";

export const VideoYoutubeTwo = () => {
  const optsTwo = {
    height: '300',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      // autoplay: 1,
    },
  };
  const videoArray = [
    {vid: 'sCP67USLmS0', text: "Анна Кузнецова побывала в московском \"Доме для мамы\""},
    {vid: 'mLUVYOw80nM', text: "Дом для мамы: Катя Архипкина"},
    {vid: '3gVZBDroc90', text: "Выставка фотографий Дома для мамы на Поклонной горе"},
    {vid: 'oSqO2fEgrlA', text:"ДОМ ДЛЯ МАТЕРИ . Кризисный Центр помощи беременным женщинам и матерям с детьми"},
    { vid: 'kYHQRoVDkm4', text: "ДОМ ДЛЯ МАТЕРИ . Кризисный Центр помощи беременным женщинам и матерям с детьми"},
    { vid:'YCLzefeoHVQ', text: "«Дом для мамы» отмечает три года с момента своего открытия"},
    { vid:'U3I4At-npbE', text: "Создание центра помощи для нуждающихся с участием добровольцев"},
    { vid:'iTZKNWN-w08', text: "Организация помощи кризисным беременным и молодым мамам (Мария Студеникина)"},
    { vid:'8RWLVHs-pYc', text: "ТБН: Дом для мамы"},
    { vid:'8RWLVHs-pYc', text: "ТБН: Дом для мамы"},
    { vid:'dg6UYXtp28k', text: "miloserdie.ru: Дом для мамы"},
    { vid:'J3iYmLsxLRs', text: "Поздравление от проекта \"Дом для мамы\""},
    { vid:'COB3m5T65Uc', text: "Дом для мам с детьми в кризисной ситуации"},
    { vid:'9vgu63eusoU', text: "Кулинарное паломничество. Кризисный центр \"Дом для мамы\" в Москве. Рождественский выпуск"},
    { vid:'O-3BXdGKj6I', text: "\"Дом для мамы.\" Помочь обрести себя"},
    { vid:'qLnUTxjUqVQ', text: "Дом для мамы в программе Сергея Шаргунова \"Двенадцать\""},
    { vid:'XK9VHk8EMz8', text: "Новая жизнь отчаявшейся женщины начинается в Доме для мамы"},
    { vid:'INd0JxqNLsc', text: "Евгения Назарова руководитель центра поддержки семьи, материнства и детства «ДОМ ДЛЯ МАМЫ»"}];
  return (
    <Wrapper>
      <Inner>
        <VideoView>
          <h2>Мы в СМИ</h2>
          <WrapButton>
            <img src={arrow} alt="ДомДляМамы"/>
            <Link to="/mivsmi">вернуться</Link>
          </WrapButton>
          <p className="v-video">Видео</p>
          <ThreeItemsVideo>
            {videoArray.map((value, index) => (
              <div className="videoArrayOne" key={index}>
                <YouTube videoId={value.vid} opts={optsTwo} />
                <p>{value.text}</p>
              </div>
            ))}
          </ThreeItemsVideo>
        </VideoView>
        <Pagination>
          <div>
            <Link to='/video/1'>
              1
            </Link>
          </div>
          <div>
            <Link to='/video/2' className="inactive-pagination">
              2
            </Link>
          </div>
          <div>
            <Link to='/video/3'>
              3
            </Link>
          </div>
        </Pagination>
      </Inner>
    </Wrapper>
  )
}
