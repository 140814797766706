import React from "react";
import {
  Wrapper,
  Inner,
  WrapInner,
  WrapContent,
  // WrapSearch
} from "./cgpStrani-styled";



export const CgpStrani = () => {
  // useEffect(() => {
  //
  //   const search = () => {
  //
  //     const name = document.getElementById("searchForm").elements["searchItem"].value;
  //     const pattern = name.toLowerCase();
  //     let targetId = "";
  //
  //     const divs = document.getElementsByTagName("p");
  //     for (let i = 0; i < divs.length; i++) {
  //       const para = divs[i].getElementsByTagName("p");
  //       const index = para[0].innerText.toLowerCase().indexOf(pattern);
  //       if (index !== -1) {
  //         targetId = divs[i].parentNode.id;
  //         document.getElementById(targetId).scrollIntoView();
  //         break;
  //       }
  //     }
  //   }
  //
  // }, []);

  return (
    <Wrapper>
      <Inner>
        <WrapInner>
          <h2>Центры Страны</h2>

          {/*<WrapSearch>*/}
          {/*  <form>*/}
          {/*    <input type='text' name='Search' id='Search' />*/}
          {/*    <input type='submit' name='submit' value='Search' />*/}
          {/*  </form>*/}
          {/*</WrapSearch>*/}

          <WrapContent>
            <p>Республика Башкортостан — Уфа.</p>
            <p>1. Название организации: Центр гуманитарной помощи</p>
            <p>2. Область, город: Республика Башкортостан</p>
            <p>3. Направления помощи: вещевая помощь</p>
            <p>4.Адрес: г. Давлеканово, храм иконы Божией Матери «Почаевская»
            <p></p>5.Контакты::  8 987 246 96 45</p>
            <p>Электронная почта: avdeev-1989@yandex.ru</p>

            <br/>
            <hr/>
            <br/>

            <p>1. Название организации: АНО "Центр помощи семье и детям "Ярослава"</p>
            <p>2. Область, город:  Республика  Башкортостан</p>
            <p>3 . Направления помощи:</p>
            <p>Проживание в приюте временного пребывания,</p>
            <p>помощь с трудоустройством,</p>
            <p>гуманитарная помощь</p>
            <p>4. Адрес: Республика Башкортостан, Аургазинский район, пос. Толбазы, ул. Тукаево 2 Б</p>
            <p>5. Контакты: 8-917-750-09-90</p>
            <p>Электронная почта: mariya.r-52@mail.ru</p>
            <p>Сайт: yroslava.ru</p>

            <br/>
            <hr/>
            <br/>

            <p>1. Название организации: АНО Общество помощи детям "Возможность" приют для женщин с малолетними детьми, попавшими в ТЖС</p>
            <p>2. Область, город: Республика Башкортостан</p>
            <p>3. Направления помощи:</p>
            <p>проживание в приюте временного пребывания,</p>
            <p>продуктовая помощь,</p>
            <p>социально-правовое сопровождение,</p>
            <p>психологическая помощь</p>
            <p>4. Адрес: г. Уфа, ул. Российская, 9 </p>
            <p> 5. Контакты: 8-800-444-19-04 </p>
            <p>Электронная почта: info@vozmojnostufa.ru</p>

            <br/>
            <hr/>
            <br/>


            <p>1. Название организации: Центр гуманитарной помощи</p>
            <p>2. Область, город: Республика Башкортостан, с. Зирган</p>
            <p>3. Направления помощи:</p>
            <p>вещевая.</p>
            <p>продуктовая помощь.</p>
            <p>4. Адрес: с. Зирган, ул. Советская 146.</p>
            <p>5. Контакты: 8 917 771 92 28</p>
            <p>Электронная почта: zmz77@mail.ru</p>

            <br/>
            <hr/>
            <br/>


            <p>1.Название организации: Центр гуманитарной помощи</p>
            <p>2.Область,город: Республика Башкортостан</p>
            <p>3.Направления помощи:</p>
            <p>Вещевая</p>
            <p>Продуктовая</p>
            <p>4.Адрес: Республика Башкортостан, с. Подлубово</p>
            <p>5.Контакты: 8-960-391-58-75</p>
            <p>Электронная почта: osteon@list.ru</p>

            <br/><hr/><br/>

            <p>1.Название организации: Центр гуманитарной помощи</p>
            <p>2.Область, город: Республика Башкортостан</p>
            <p>3.Направления помощи:</p>
            <p>Вещевая</p>
            <p>Продуктовая</p>
            <p>4.Адрес: с.Бижбуляк</p>
            <p>5.Контакты: 8-996-581-13-81</p>
            <br/><hr/><br/>

            <p>1.Название организации: Консультационный центр "Мать и дитя"</p>
            <p>2.Область, город: Республика Башкортостан</p>
            <p>3.Направления помощи:</p>
            <p>гуманитарная</p>
            <p>юридическая</p>
            <p>психологическая</p>
            <p>4.Адрес: Республика Башкортостан, пос. Чишмы, ул. Ягодная, дом 16/5</p>
            <p>5.Контакты: 8-967-788-70-28</p>
            <p>Электронная почта: mamadetichishmy@mail.ru</p>

            <br/><hr/><br/>

            <p>1.Название организации: Центр защиты материнства и детства</p>
            <p>2.Область, город: Республика Башкортостан</p>
            <p>3.Направления помощи:</p>
            <p>гуманитарная,</p>
            <p>юридическая,</p>
            <p>психологическая</p>
            <p>4.Адрес: Республика Башкортостан, пос Кумертау ,40 лет Победы 5.</p>
            <p>5.Контакты: 8 963 905 31 75</p>
            <p>Электронная почта: hram.kumertau@rambler.ru</p>

                        <br/><hr/><br/>

            <p>1.Название организации: Центр гуманитарной помощи</p>
            <p>2.Область, город: Республика Башкортостан, пос. Раевский</p>
            <p>3.Направление помощи:</p>
            <p>гуманитарная</p>
            <p>4.Адрес: Республика Башкортостан, пос. Раевский</p>
            <p>5.Контакты: 8-937-853-75-35</p>

            <br/><hr/><br/>

            <p>1.Название организации: Центр гуманитарной помощи</p>
            <p>2.Область, город: Республика Башкортостан, пос. Ефремкино</p>
            <p>3.Направления помощи:</p>
            <p>Гуманитарная</p>
            <p>Вещевая</p>
            <p>4.Адрес: Республика Башкортостан, пос.Ефремкино, ул. Ленина 18.</p>
            <p>5.Контакты: 8-987-026-00-37</p>

            <br/><hr/><br/>

            <p>1.Название организации: Приют для беременных и женщин с младенцами «Дом Надежды»</p>
            <p>2.Область, город: Республика Башкортостан, с. Николо-Березовка</p>
            <p>3.Направления помощи:</p>
            <p>Приют временного проживания</p>
            <p>Психологическая</p>
            <p>Юридическая</p>
            <p>4.Адрес: Республика Башкортостан, Краснокамский р-н, с. Николо-Березовка, ул. Ленина, д. 100</p>
            <p>5.Контакты: 8-917-345-85-03, 8919 617 53 84, 8 917 748 20 17</p>

            <br/><hr/><br/>

            <p>1.Название организации: Центр гуманитарной помощи</p>
            <p>2.Область, город: Республика Башкортостан, с. Дюртюли</p>
            <p>3.Направление помощи:</p>
            <p>вещевая,</p>
            <p>продуктовая.</p>
            <p>4.Адресс: Республика Башкортостан, с. Дюртюли</p>
            <p>5.Контакты:: 8-917-359-19-15</p>

            <br/><hr/><br/>


            <p>1.Название организации: Центр гуманитарной помощи</p>
            <p>2.Область, город: Республика Башкортостан, г. Нефтекамск</p>
            <p>3. Направление помощи:</p>
              <p>вещевая,</p>
              <p>продуктовая</p>
            <p>4. Адрес: г.Нефтекамск, ул. Трактовая, 13 а</p>
            <p>5.Контакты: 8917 41345 91</p>
            <p>Электронная почта: nesterova569875@mail.ru</p>

            <br/><hr/><br/>

            <p>1.Название организации: Центр гуманитарной помощи</p>
            <p>2.Область, город: Республика Башкортостан, г. Туймазы</p>
            <p>3.Направление помощи:</p>
            <p>вещевая</p>
            <p>4.Адресс: Республика Башкортостан, г. Туймазы, ул. Островского, д.39, склад ул.8 марта д.13 кв.2</p>
            <p>5.Контакты: 7 (927) 944-13-47</p>
            <p>Электронная почта: mutternata@mail.ru</p>

            <br/><hr/><br/>


            <p>1.Название организации: Центр помощи "Мать и дитя"</p>
            <p>2.Область, город Республика Башкортостан, п. Зирган</p>
            <p>3.Направления помощи:</p>
            <p>вещевая</p>
            <p>4.Адресс: Республика Башкортостан, пос. Зирган, ул. Советская, 126</p>
            <p>5.Контакты: 8(987)109-92-02,  8 917 771 92 28</p>
            <p>Электронная почта: ZMZ77@mail.ru</p>

            <br/><hr/><br/>

            <p>Республика Бурятия — Улан-Удэ.</p>
            <p>1. Название организации: Автономная некоммерческая организация "Преображение"</p>
            <p>2. Область, город: Республика Бурятия, г. Северобайкальск</p>
            <p>3. Направления помощи:</p>
            <p>гуманитарная</p>
            <p>4.Адрес: г. Северобайкальск , ул. Мира, 10</p>
            <p>Контакты: +7(4752) 42 25 04</p>
            <p>Электронная почта: dpc-preobrazhenie@yandex.ru</p>

            <br/><hr/><br/>

            <p>1. Название организации: Центр защиты семьи, материнства и детства</p>
            <p>2. Область, город: Республика Бурятия, г. Северобайкальск</p>
            <p>3. Направления помощи:</p>
            <p>вещевая,</p>
            <p>продуктовая,</p>
            <p>юридическая</p>
            <p>психологическая,</p>
            <p>духовная</p>
            <p>4.Адрес: г. Северобайкальск, ул. Космонавтов, 21А</p>
            <p>Контакты: 89244505384</p>
            <p>Электронная почта: olga.sb85@mail.ru</p>

            <br/><hr/><br/>


            <p>1.Название организации: Благотворительный фонд "Радость материнства" приют  "Остров надежды"</p>
            <p>2.Область, город: Республика Бурятия, г. Улан-Уде</p>
            <p>3.Направления помощи:</p>
            <p>приют временного проживания,</p>
            <p>юридическая,</p>
            <p>психологическая,</p>
            <p>социальная</p>
            <p>4.Адрес: Республика Бурятия, г. Улан-Удэ, пос. Новая Брянь, ул. Октябрьская, 5</p>
            <p>5.Контакты: 8-983-635-76-46</p>
            <p>Электронная почта: pdmj@mail.ru</p>

            <br/><hr/><br/>

            <p>1.Название организации: Благотворительный фонд "Радость материнства" приют  "Остров надежды"</p>
            <p>2.Область, город Республика Бурятия ,г.Улан-Уде</p>
            <p>3.Направление помощи:</p>
            <p>вещевая,</p>
            <p>продуктовая,</p>
            <p>финансовая по ситуации,</p>
            <p>социальная</p>
            <p>юридическая,</p>
            <p>психологическая</p>
            <p>4.Адрес: Республика Бурятия, г. Улан-Удэ, ул. Красногвардейская, 32</p>
            <p>5.Контакты: 8 964 400 59 77 Екатерина</p>
            <p>Электронная почта: pdmj@mail.ru</p>

            <br/><hr/><br/>

            <p>1.Название организации: Центр гуманитарной помощи</p>
            <p>2.Область, город: Республика Бурятия,</p>
            <p>3. Направления помощи:</p>
            <p>вещевая,</p>
            <p>продуктовая</p>
            <p>4. Адрес: Республика Бурятия, село Мостовка</p>
            <p>5. Контакты: телефон для обращений 8-951- 623- 89- 79</p>

            <br/><hr/><br/>

            <p>Республика Дагестан — Махачкала.</p>
            <p>1. Название организации: Автономная некоммерческая организация "Центр защиты материнства и детства "Тёплый дом на горе"</p>
            <p>2. Область, город: Республика Дагестан, г. Махачкала</p>
            <p>3. Направление помощи:</p>

            <p>вещевая,</p>
            <p>продуктовая,</p>
            <p>юридическая,</p>
            <p>психологическая,</p>
            <p>гуманитарная,</p>
            <p>приют временного пребывания</p>
            <p>4.Адрес: г.Махачкала, ул. Абубакарова 46</p>
            <p>Контакты: 89886477302;</p>
            <p>Электронная почта: tdng05@yandex.ru</p>
            <p>Сайт: Dagmama.ru</p>

            <br/><hr/><br/>

            <p>Республика Калмыкия — Элиста.</p>
            <p>1.Название организации: Центр защиты семьи,  материнства и детства</p>
            <p>2.Область, город: Республика Калмыкия, г.Сыктывкар</p>
            <p>3.Направления помощи:</p>
            <p>вещевая,</p>
            <p>социально-правовая</p>
            <p>4. Адрес: г. Сыктывкар, ул.Бабушкина 20/60</p>
            <p>5. Контакты: 8 904 204 95 83</p>

            <br/><hr/><br/>

            <p>Карачаево-Черкесская Республика — Черкесск.</p>

            <p>1. Название организации: Благотворительный фонд "Утешение"</p>
            <p>2. Область, город: Республика Карелия, г. Петрозаводск</p>
            <p>3. Направления помощи:</p>
            <p>гуманитарная,</p>
            <p>вещевая и иная</p>
            <p>4. Адрес: Республика Карелия — Петрозаводск.</p>
            <p>Контакты: 8 (8142) 56-31-20, +79114030886</p>
            <p>Электронная почта: uteshenie@yandex.ru</p>


            <br/><hr/><br/>

            <p>1.Название организации: Центр гуманитарной помощи</p>
            <p>2.Область, город:Республика Калмыкия, г. Элиста</p>
            <p>3.Направление помощи:</p>
            <p>продуктовая,</p>
            <p>вещевая,</p>
            <p>ср-ва гигиены</p>
            <p>4.Адресс: г.Элиста, ул .Сергия Радонежского, 97</p>
            <p>5.Контакты: 8- 917-684- 65-44</p>
            <p>Электронная em-08@bk.ru</p>


            <br/><hr/><br/>

            <p>Республика Крым — Симферополь.</p>

            <p>Наименование организации: Благотворительная Автономная некоммерческая организация "Крымский дом для мамы"</p>
            <p>Область город: Крым Республика, г. Симферополь</p>
            <p>Направление помощи :</p>
            <p>Приют временного проживания,</p>
            <p>гуманитарная,</p>
            <p>психологическая,</p>
            <p>юридическая</p>
            <p>Адрес: г.Симферополь,  ( адрес не разглашают)</p>
            <p>Контакты: 8-978-265-52-92</p>
            <p>Электронная почта: dionis.tmt@gmail.com, krizis-mama@yandex.ru</p>
            <p>Сайт: https://xn------7cdrb9begebcp2b4ld3d.xn--p1ai/</p>

            <br/><hr/><br/>

            <p>Название организации: Центр гуманитарной помощи "Екатерининский"</p>
            <p>Область, город: Республика Крым, г. Симферополь</p>
            <p>Направления помощи:</p>
            <p>Гуманитарная</p>
            <p>Адрес: Республика Крым, г. Симферополь , 60-летия Октября, 16/98</p>
            <p>Контакты: 8978043 45 46</p>
             <br/><hr/><br/>

            <p>Республика Марий-Эл — Йошкар-Ола.</p>
            <p>1.Название организации: Центр гуманитарной помощи</p>
            <p>2.Область,город: Республика Марий Эл, г. Йошкар-Ола</p>
            <p>3.Направления помощи:</p>
            <p>вещевая,</p>
            <p>продуктовая</p>
            <p>4. Адрес: Республика Марий Эл,  г.  Йошкар-Ола, ул. Вознесенская, 81</p>
            <p>5. Контакты: тел.: 8 (8362) 63-83-18.</p>
            <p>Электронная почта: socslozhenie@mail.ru</p>
            <p>Сайт: Мариймилосердие.РФ</p>


            <br/><hr/><br/>


            <p>Республика Мордовия — Саранск.</p>
            <p>1.Название организации: Благотворительный фонд Защиты семьи, материнства и детства "Сохраним жизнь"</p>
            <p>2.Область, город: Республика Мордовия, г.Саранск</p>
            <p>3. Направление помощи:</p>
            <p>Просвещение</p>
            <p>Гуманитарная</p>
            <p>4.Адрес: г. Саранск, ул. Кирова, д. 63-г,</p>
            <p>5.Контакты: Илькаева Любовь Викторовна 8 953 030 84 78; Ольга Яковлевна 8(960) 338 25 25</p>
            <p>Электронное почта: o.y.kolesnik@gmail.com</p>

            <br/><hr/><br/>
            <p>Республика Татарстан — Казань.</p>
            <p>1.Название организации: АНО "Центр защиты материнства "Умиление"</p>
            <p>2.Область, город: Республика Татарстан, г.Казань</p>
            <p>3. Направление помощи:</p>
            <p>Приют временного проживания,</p>
            <p>консультирование,</p>
            <p>просвещение- лекционная деятельность,</p>
            <p>юридическая,</p>
            <p>психологическая помощь,</p>
            <p>ресурсный семейный центр</p>
            <p>4.Адрес: г. Казань, ул Сафиуллина 7</p>
            <p>5.Контакты: 8 987 220 03 60 дежурная</p>
            <p>Электронная почта: 79872200360@yаndex.ru</p>
            <p>Сайт:нет</p>
            <br/><hr/><br/>
            <p>1.Название организации: Центр гуманитарной помощи</p>
            <p>2.Область, город: Республика Татарстан, г. Тетюшин</p>
            <p>3.Направление помощи:</p>
            <p>вещевая,</p>
            <p>иногда продуктовая</p>
            <p>4.Адрес: г. Тетюшин, ул. 200 лет Тетюшам 14 В</p>
            <p>5.Контакты: 8 962 555 51 11</p>
            <p>Электронная почта: globuszav@rambler.ru</p>

            <br/><hr/><br/>

            <p>1.Название организации: Центр гуманитарной помощи</p>
            <p>2.Область, город: Республика Татарстан, с. Большое Фролово</p>
            <p>3.Направление помощи:</p>
            <p>вещевая,</p>
            <p>иногда продуктовая</p>
            <p>4.Адрес: село Большое Фролово, Энгельса 1</p>
            <p>5.Контакты:8 939 303 43 11</p>
            <br/><hr/><br/>
            <p>1.Название организации: Центр гуманитарной помощи</p>
            <p>2.Область, город: Республика Татарстан, с. Ембулатово</p>
            <p>3.Направление помощи:</p>
            <p>вещевая</p>
            <p>4.Адрес: с. Ембулатово, ул. Пролетарская 12</p>
            <p>5.Контакты: 8 965 619 58 49</p>
            <br/><hr/><br/>

            <p>Удмуртская Республика — Ижевск.</p>
            <p>1.Название организации: Дом матери "Надежда"</p>
            <p>2.Область, город: Республика Удмуртия, г. Сарапул</p>
            <p>3.Направление помощи:</p>
            <p>Приют временного проживания,</p>
            <p>психологическая,</p>
            <p>духовная,</p>
            <p>гуманитарная</p>
            <p>4.Адрес: г. Сарапул, улица Мостовая, дом 8</p>
            <p>5.Контакты: 8 922 682 32 34</p>
            <p>Электронная почта: sarapul-miloserdie@mail.ru</p>

            <br/><hr/><br/>

            <p>1.Название организации: Центр защиты семьи, материнства и детства «Источник»</p>
            <p>2.Область, город: Республика Удмуртия</p>
            <p>3.Направление помощи:</p>
            <p>психологическая,</p>
            <p>социальная,</p>
            <p>гуманитарная</p>
            <p>4.Адрес: Ижевск, ул. Удмуртская, 220</p>
            <p>5. Контакты 8-912-456-66-76,</p>
            <p>Электронная почта: pna12621@mail.ru</p>

            <br/><hr/><br/>

            <p>1.Название организации: Приют для беременных женщин и матерей с детьми в ТЖС</p>
            <p>2.Область, город: Республика Удмуртия, г. Ижевск</p>
            <p>3.Направление помощи:</p>
            <p>проживание,</p>
            <p>психологическая,</p>
            <p>юридическая,</p>
            <p>гуманитарная.</p>
            <p>4.Адрес: г.  Ижевск, Карла Маркса , 222</p>
            <p>5. Контакты: 8(3412)51 01 77</p>
            <p>Электронная почта: vosk-anna@yandex.ru</p>
            <p>Сайт: ижсобор.ру</p>

            <br/><hr/><br/>

            <p>1. Название организации : Центр гуманитарной помощи</p>
            <p>2. Область, город: Республика Хакасия, г. Абакан</p>
            <p>3. Направление:</p>
            <p>гуманитарная помощь</p>
            <p>4. Адрес: Республика Хакасия,  г.Абакан.</p>
            <p>Контакты: 89134463180</p>

            <br/><hr/><br/>


            <p>Чувашская Республика — Чебоксары.</p>

              <p>Название организации: Центр защиты семьи, материнства и детства «Покров»</p>
              <p>Область, город: Чувашская республика, г. Чебоксары</p>
            <p>Направление помощи:</p>
            <p>вещевая помощь,</p>
            <p>продуктовая помощь,</p>
            <p>вещевая помощь,</p>
            <p>духовная,</p>
            <p>просветительская (лекции)</p>
            <p>Адрес: г. Чебоксары, ул. Пирогова, д. 6Б</p>
            <p>Контакты:	8-8352-60-65-33, 8-908-304-89-19,</p>
            <p>e-mail: czmpokrov@mail.ru, rentgensurg@mail.ru</p>
            <p>Сайт: miloserdie21.ru</p>

            <br/><hr/><br/>

            <p>Название организации: Центр защиты семьи, детей и материнства «Благовещение»</p>
            <p>Область, город: Чувашская Республика</p>
            <p>Направление помощи:</p>
            <p>вещевая помощь,</p>
            <p>продуктовая помощь,</p>
            <p> помощь товара</p>
            <p> консультирование  православного психолога,</p>
            <p> Адрес: г. Алатырь, ул. Комсомола, д.35</p>
            <p> Контакты:	Протоиерей Андрей Николаевич Савенков, Вишневская Наталья Николаевна 8 919 138 94 24, 8-937-397-87-53 ЦГП</p>
            <p>  e-mail:blagovest_life@mail.ru</p>
            <p>  https://predtecha-alatyr.ru/center-blagoveshenie/</p>
            <p>  https://vk.com/blagovcheb</p>

            <br/><hr/><br/>


            <p>Алтайский Край — Барнаул.</p>
            <p>Наименование организации: Алтайская региональная общественная организация "Покров"</p>
            <p>Область, город: Алтайский край, г. Бийск</p>
            <p>Направление помощи:</p>
            <p>- временное проживание</p>
            <p>- вещевая</p>
            <p>- психологическая</p>
            <p> - юридическая</p>
            <p>4.   Адрес: г. Бийск, ул. Пушкина 188г</p>
            <p> 5.   Контакты: телефон для обращений: 89237927704</p>
            <p> Электронная почта: tar-ko.marina@yandex.ru</p>

            <br/><hr/><br/>

            <p>Забайкальский Край — Чита.</p>
            <p>Наименование организации: Центр защиты семьи, материнства и детства</p>
            <p>Область, город: Забайкальский край, г. Шилка</p>
            <p>Направление помощи:</p>
            <p>- вещевая</p>
            <p>- продуктовая</p>
            <p>- психологическая</p>
            <p>4.   Адрес: г. Шилка, п.Холбон, ул. Первомайская 1.</p>
            <p>5.   Контакты: телефон для обращений: 89144721902</p>
            <p>Электронная почта: miro.72@mail.ru</p>

            <br/><hr/><br/>

            <p>Наименование организации: Многофункциональный социально-культурный Центр имени святой преподобномученицы великой княгини Елизаветы Федоровны</p>
            <p>Область, город: Забайкальский край, г. Чита</p>
            <p>Направление помощи:</p>
            <p>- вещевая</p>
            <p>- просветительская</p>
            <p>- духовная</p>
            <p>4.   Адрес: г. Чита, 1-й Некрасовский  пер. 2</p>
            <p>5.   Контакты: телефон для обращений: 8 (3022) 24 39 74</p>
            <p>Наименование организации: АНО "Нити добра"</p>
            <p>Область, город: Забайкальский край</p>
            <p>Направление помощи:</p>
            <p>- вещевая</p>
            <p>- продукты питания для детей</p>
            <p>- памперсы</p>
            <p>- психологическая</p>
            <p>4.   Адрес: пос. Новая Чара, ул. Магистральная 22</p>
            <p>5.   Контакты: телефон для обращений: 8914513 59 29</p>
            <p>Электронная почта: kushneva.72@mail.ru</p>

            <br/><hr/><br/>

            <p>Наименование организации:  Забайкальское краевое отделение Всероссийской общественной организации «Союз добровольцев России»</p>
            <p>Область, город: Забайкальский край</p>
            <p>Направление помощи:</p>
            <p>- вещевая</p>
            <p>- психологическая</p>
            <p>- детское питание</p>
            <p>- сопровождение семей</p>
            <p>4.   Адрес: г. Чита, Лермонтова 1, оф. 320</p>
            <p>5.   Контакты: телефон для обращений: 89144903179</p>
            <p>Электронная почта: cdr-chita@mail.ru</p>

            <br/><hr/><br/>

            <p>Камчатский Край — Петропавловск-Камчатский.</p>
            <p>Наименование организации: Центр Покров</p>
            <p>Область, город: Камчатский край Петропавловск-Камчатский</p>
            <p>Направление помощи:</p>
            <p>продуктовая,</p>
            <p>вещевая</p>
            <p>Адрес: г. Петропавловск-Камчатский, ул. Владивостокская, 18</p>
            <p>Контакты :8 924 780 69 88</p>
            <p>Электронная почта: pke_miloserdie@list.ru</p>
            <p>Сайт: http://pravkamchatka.ru/</p>

            <br/><hr/><br/>

            <p>Краснодарский Край — Краснодар.</p>
            <p>1. Название организации : Центр защиты семьи, материнства и детства</p>
            <p>2. Область, город: Краснодарский край</p>
            <p>3. Направления помощи:</p>
            <p>консультационная помощь</p>
            <p>гуманитарная помощь</p>
            <p>4.Контакты: 8(918) 496-91-40</p>
            <p>Электронная почта: olginfinity@mail.ru</p>

            <br/><hr/><br/>

            <p>1.Наименование организации : АНО"Благой дар Кубани", приют "Светлая обитель"</p>
            <p>2.Область, город: Краснодарский край г. Краснодар</p>
            <p>3.Направление помощи :</p>
            <p>Приют временного проживания,</p>
            <p>психологическая,</p>
            <p>социальная,</p>
            <p>юридическая</p>
            <p>4.Адрес : г. Краснодар, Грушевая, 74 ( не разглашаем, не размещаем в общем доступе, даем телефон только)</p>
            <p>5.Контакты : 8-918-193-77-33, 8-918-000-70-00</p>
            <p>Электронная почта: polyakovais@inbox.ru</p>

            <br/><hr/><br/>

            <p>1.Наименование организации: Центр защиты семьи, материнства и детства</p>
            <p>2.Область, город: Краснодарский край, г. Анапа</p>
            <p>3.Направление помощи : соц.адаптация-проживание совместно с пожилыми подопечными богодельни-взаимополезное общение и делание) кормление бездомных 2 раза в неделю пн, чтв, юридическая и психологическая помощь по запросу</p>
            <p>4.Адрес : г. Анапа, ул. Маяковского 111, В ( в районе Южного рынка)</p>
            <p>5.Контакты : 8-86133 (5-08-61)  ;  8-918-057-37-37 звонить до 20.00</p>
            <p>Электронная почта: Elza.Pshenichnikova@yandex.ru</p>

            <br/><hr/><br/>

            <p>1.Наименование организации : СГОО "Многофунциональный кризисный центр в честь иконы Божией Матери "Нечаянная Радость"</p>
            <p>2.Область, город: Краснодарский край г. Сочи</p>
            <p>3.Направление помощи :</p>
            <p>гуманитарная помощь,</p>
            <p>информационная</p>
            <p>психологическая</p>
            <p>социальна,</p>
            <p>юридическая по запросу,</p>
            <p>4Адрес : г. Сочи, ул. Дагомысская 42, б</p>
            <p>5.Контакты : 8-988-233-35-95</p>
            <p>Электронная почта: miloserdie_sochivsr@bk.ru</p>


            <br/><hr/><br/>

            <p>1.Наименование организации : Медико-просветительский центр "Жизнь</p>
            <p>2.Область, город: Краснодарский край г. Горячий Ключ</p>
            <p>3.Направление помощи :</p>
            <p>-гуманитарная</p>
            <p>-просветительская</p>
            <p>4.Адрес : г. Горячий Ключ, ул. Советская,100</p>
            <p>5.Контакты : 8-918-931-55-46</p>
            <p>Электронная почта: troickij-chram-gk@list.ru, brysmart@mail.ru</p>
            <p>Сайт: http://pravkuban-15.cerkov.ru/</p>

            <br/><hr/><br/>

            <p>1.Наименование организации : Центр гуманитарной помощи</p>
            <p>2.Область, город: Краснодарский край ст. Казанская</p>
            <p>3.Направление помощи :</p>
            <p>гуманитарная</p>
            <p>4.Адрес : ст. Казанская, ул. Красная, 170</p>
            <p>5.Контакты : 7-918-434-49-99</p>
            <p>Электронная почта: kropotkinblago@yandex.ru</p>
            <p>Сайт: http://kazankahram.cerkov.ru/main-page/</p>

            <br/><hr/><br/>

            <p>1.Наименование организации : Центр поддержки материнства и детства "Пристань"</p>
            <p>2.Область, город: Краснодарский край Новороссийск</p>
            <p>3.Направление помощи :</p>
            <p>духовная,</p>
            <p>психологическая,</p>
            <p>юридическая,</p>
            <p>медико-социальная</p>
            <p>, гуманитарная (коляски,памперсы, продуктовые наборы,гигиена) для сохранивших беременность,</p>
            <p>лекционно-просветителькая деятельность</p>
            <p>4.Адрес : г. Новороссийск, ул.Видова, 26</p>
            <p>5.Контакты : 8-918-987 10 03 Вера Ивановна</p>
            <p>Электронная почта: Pashin_andrei@mail.ru, Sifiridi2012@yandex.ru</p>

            <br/><hr/><br/>

            <p>1.Наименование организации : Центр гуманитарной помощи</p>
            <p>2.Область, город: Краснодарский край, г. Туапсе</p>
            <p>3.Направление помощи :</p>
            <p>гуманитарная</p>
            <p>социальная по запросу</p>
            <p>4.Адрес : ул. Ленских рабочих, д. 7</p>
            <p>5.Контакты : 8-918-616-94-09</p>
            <p>Электронная почта: tuapse-prav@yandex.ru, roman-dejnega@yandex.ru</p>


            <br/><hr/><br/>

            <p>Красноярский Край — Красноярск.</p>
            <p>1.Наименование организации : Региональная общественная организация Красноярского края "Центр защиты материнства и детства св. Петра и Февронии"</p>
            <p>2.Область город : Красноярский край г.Красноярск</p>
            <p>3. Направление помощи :</p>
            <p>-духовная,</p>
            <p>- психологическая,</p>
            <p>-гуманитарная,</p>
            <p>-юридическая,</p>
            <p>-информационно- просветительская,</p>
            <p>-помощь в трудоустройстве</p>
            <p>4.Адрес : г.Красноярск, ул. Горького, 27</p>
            <p>5.Контакты : 8-391-227-73-90, 8-983-151-08-45, 8-923-294-97-54</p>
            <p>Электронная почта:   help-mother.kras@ mail, gmg2006@mail.ru</p>
            <p>Сайт: http://help-mother24.ru/</p>

            <br/><hr/><br/>

            1.Наименование организации : Региональная общественная организация Красноярского края "Центр защиты материнства и детства св. Петра и Февронии"
            2.Область город : Красноярский край, г.Красноярск
            3. Направление помощи :
            гуманитарная

            Адрес: г. Красноярск, Проспект мира 107
            Контакты : 8-391-227-73-90, 8-983-151-08-45, 8-923-294-97-54
            Электронная почта:     help-mother.kras@ mail, gmg2006@mail.ru
            Сайт: http://help-mother24.ru/

            <br/><hr/><br/>

            Наименование организации : Сестричество милосердия во имя свт. Луки
            Область город : Красноярский край , Лесосибирск
            Направление помощи :
            гуманитарная
            психологическая
            просветительская
            Адрес : г. Лесосибирск, ул. Горького, д. 25
            Контакты : 8-904-897-36-87
            Электронная почта: t.blagireva@mail.ru
            Сайт: енисейскаяепархия.рф

            <br/><hr/><br/>
            Пермский Край — Пермь.
            1. Название организации : Кризисный центр "Маленькая пристань" (Чайковская районная общественная организация социальной помощи населению «Возрождение»)
            2. Область, город: Пермский край ,г. Чайковский
            3. Направление: В центре организованы дневной детский центр пребывания детей - «мини-ясли»; а также временный приют для беременных и женщин с детьми, работают психологи, юристы. Стационар рассчитан на 5 мест для женщин с детьми. Срок пребывания 1-3 мес.
            4.Адрес: г. Чайковский, ул. Приморский б-р,  дом 22, офис 15
            Контакты: 8 9223 15 00 85

            <br/><hr/><br/>

            1.Название организации: Центр поддержки материнства и детства "Колыбель"
            2. Область, город: ПЕРМСКИЙ КРАЙ
            3. Направление помощи:
            гуманитарная,
            сопровождение беременной до годовалого возраста ребенка,
            психологическая,
            юридическая
            4. Адрес: Г. Пермь, ул. Пушкина, 83 при храме св. Симеона Верхотурского
            5.  Контакты: телефон для обращений матушка Елена Сергеевна Дегтярева-Леонова 8 982 463 99 82; 8-342-236-50-54
            Электронная почта: kolybel-perm@yandex.ru
            Сайт: нет
                <br/><hr/><br/>

            Приморский Край — Владивосток.
            1.Название организации: Центр защиты материнства «Колыбель»
            2. Область, город: Приморский край, г. Владивосток
            3. Направление помощи:
            Приют временного пребывания
            Гуманитарная
            Психологическая
            Юридическая

            4.Адрес :  Россия, г. Владивосток, Океанский пр-т, 44
            5. Контакты: 8 423 254 14 44
            Электронная почта: mvladru@yandex.ru
            <br/><hr/><br/>

            1.Название организации: Дом милосердия
            2. Область, город: Приморский край, с. Анучино
            3. Направление помощи:
            Приют временного проживания
            Гуманитарная
            Социальная
            4.Адрес :  Россия, Приморский край ,с. Анучино, ул.Лазурь , 20а
            5. Контакты: Варсонофий 8-914-345-84-82 звонить с 9 до 12 по Московскому времени
            Электронная почта: arseparhia@yandex.ru
            <br/><hr/><br/>



            1. Название организации: Автономная некоммерческая организация «Покров»

            2. Область, город: Приморский край, с. Покровка
            3. Направление помощи:
            гуманитарная,
            консультационная
            психологическая
            4.Адрес: Приморский край, с.Покровка, Октябрьская ул., 6
            5. Контакты:  8908 456 6874
            Электронная почта: yanusik31@mail.ru
            <br/><hr/><br/>

            1. Название организации: Благотворительный фонд «Забота»
            2. Область, город: Приморский край, п. Врангель
            3. Направление помощи:
            гуманитарная
            психологическая,
            юридическая,
            духовная
            4.Адрес: Приморский край, пос. Врангель церковь Живоначальной Троицы

            5. Контакты:  8-914-709-29-06

            Электронная почта: npeu@mail.ru
            <br/><hr/><br/>


            1. Название организации: Центр защиты материнства "Колыбель"

            2. Область, город: Приморский край

            3. Направление помощи:
            психологическая, информационно-просветителькая, юридическая, медицинская.

            4.Адрес: Приморский край, Владивосток, ул. Калинина, 55

            5. Контакты: 8902 524 14 44; 8-914-674-65-52

            Электронная почта: info@kolybel.org;  volosina-vp@mail.ru

            Сайт: нет
            <br/><hr/><br/>

            1.. Название организации: Центр гуманитарной помощи

            2. Область, город: Приморский край

            3. Направление: продуктовая, вещевая, коляски, кроватки, юридическая помощь

            4.Адрес: Приморский край, г. Находка, Находкинский проспект, д. 33, 5.

            Контакты: 8 914 709 29 06 ЕЛЕНА; 8914 662 14 59

            Электронная почта: npeu@mail.ru

            Сайт: нет

            <br/><hr/><br/>

            Ставропольский Край — Ставрополь.
            Наименование организации: Центр гуманитарной помощи
            Область, город: Ставропольский край
            Направление помощи:
            - продуктовая
            - вещевая
            4.   Адрес:  г. Михайловск, ул. Октябрьская, 367
            5.   Контакты: телефон для обращений:   8-928-321-31-81
            Электронная почта: blago11@yandex.ru
            Сайт:
            <br/><hr/><br/>
            Наименование организации: Региональное отделение Международной Общественной Организации «Союз православных женщин» в Ставропольском крае
            Область, город: Ставропольский край, г. Ставрополь
            Направление помощи:
            - вещевая
            - продуктовая
            - коляски, кроватки
            - памперсы
            4.   Адрес: г. Ставрополь, ул.Дзержинского,  157
            5.   Контакты: телефон для обращений: 89620263328
            Электронная почта: natalya-mozdor@yandex.ru
            Сайт:
            <br/><hr/><br/>
            Наименование организации: Приют для беременных и женщин с младенцами, оказавшимся в трудной жизненной ситуации "Мамины  руки"
            Область, город: Ставропольский край
            Направление помощи:
            - временное проживание
            - продуктовая
            - вещевая
            - психологическая
            - юридическая

            4.   Адрес: г. Ставрополь, ул. Партизанская,1
            5.   Контакты: телефон для обращений: +7-905-490-82-40
            Электронная почта: preobrajenie_19@mail.ru, my-san777@yandex.ru
            Сайт:
            <br/><hr/><br/>

            Наименование организации: Автономная некоммерческая организация «Дом для мамы» г. Ессентуки
            Область, город: Ставропольский край, г. Ессентуки
            Направление помощи:
            - временное проживание
            - продуктовая
            - вещевая
            - гигиена
            - юридическая
            - психологическая
            - семейный клуб
            4.   Адрес: г. Ессентуки, Вокзальная 11
            5.   Контакты: телефон для обращений: 8919756 93 11
            Электронная почта: dom-mamy@mail.ru
            Сайт:

            <br/><hr/><br/>
            Хабаровский Край — Хабаровск.
            1. Название организации: Центр гуманитарной помощи
            2. Область, город: Хабаровский край
            3. Направление: Вещевая, аренда ТСР инвалидам по запросу , духовная.

            4.Адрес: Хабаровский край, Нанайский район, село Троицкое, ул.Калинина,дом90

            Контакты: Татьяна Юрьевна Ходжер 8-914-373-17-34    Часы работы: понедельник, среда, пятница с 12 до 14
            Электронная почта: нет
            Сайт: нет

            <br/><hr/><br/>

            Название организации: Хабаровская краевая общественная организация "Милосердие" Кризисный центр для женщин "Не одна"
            Область, город: Хабаровский край
            Направление помощи:
            вещевая, продуктовая, психологическая, юридическая, помощь с трудоустройством, помощь соцработника (оформление льгот, пособий и т.п.), социально-медицинская.
            Адрес: Хабаровск, ул. Красноводская, 2
            Контакты Ветренко Константин Владимирович, тел: +7-914-158-27-66, E-mail:  xkoo.miloserdie@mail.ru, https://hkmiloserdie.ru/

            <br/><hr/><br/>
            Название организации: Центр защиты семьи, материнства и детства
            Область, город: Хабаровский край
            Направление помощи: вещевая, продуктовая, психологическое консультирование
            Адрес:  г.Хабаровск, ул. Воронежская 49/1
            Контакты: 8914-159-30-61
            E-mail: ocbiss@mail.ru
            https://miloserdiedv.ru

            <br/><hr/><br/>
            Название организации: Центр защиты семьи, материнства и детства
            Область, город: Хабаровский край
            Направление помощи: гуманитарная
            Адрес: Хабаровский край, Нанайский район, село Троицкое, ул. Калинина, д. 90
            Контакты: Ходжер Татьяна Юрьевна 8-914-373-17-34


            <br/><hr/><br/>

            Амурская область — Благовещенск.

            1. Название организации : "Новая жизнь - новая Я"
            2. Область, город: Архангельская обл. г.Каргополь
            3. Направление: консультационная, психологическая, гуманитарная
            4.Адрес: г.Каргополь, ул.Ленинградский пер. д.2а
            5.Контакты: 8-818-41-211-73;
            Электронная почта: priutkar@yandex.ru
            Сайт: нет


            <br/><hr/><br/>

            Астраханская область — Астрахань.
            1. Название организации: Центр гуманитарной помощи
            2. Область, город: Астраханская область г. Ахтубинск
            3. Направление: вещевая, периодически продуктовая
            4. Адрес: г.Ахтубинск, ул. Фрунзе ,80а
            5. Контакты:
            Электронная почта: Glavbuh@tinaki.ru
            Сайт: нет


            <br/><hr/><br/>

            1. Название организации: Центр гуманитарной помощи
            2. Область, город: Астраханская область. г. Астрахань
            3. Направление:
            Вещевая,
            периодически продуктовая,
            консультационная,
            психологическая,
            юридическая
            4.Адрес: г. Астрахань, ул. Коммунистическая 38 а/ пер. Березовского 13а
            Контакты: 8 967 829 95 90
            Электронная почта: socotdel30@yandex.ru
            Сайт: нет

            <br/><hr/><br/>

            Белгородская область — Белгород.
            1. Название организации: СМОО "Кризисный центр для женщин,попавших в трудную жизненную ситуацию", г. Старый Оскол  /приют "Ангел"
            2. Область, город: Белгородская область г. Старый Оскол
            3. Направление: Центр предоставляет юридическую, психологическую, гуманитарную помощь, приют временного проживания, содействует в решении жилищных вопросов, предоставляет возможность обучения на курсах по подготовке к родам. Семьи, состоящие на учете в центре, ежемесячно получают гуманитарные наборы. На базе центра открыт приют для беременных женщин и женщин с малолетними детьми, рассчитанный на 10 мест. Срок пребывания до 3 месяцев.
            4.Адрес: г. Старый Оскол, мк-н Рудничный, 13
            Контакты: 84725 47 17 13 Анна Борисовна
            Электронная почта: mamaoskol2012@yandex.ru
            Сайт: нет


            <br/><hr/><br/>

            1. Название организации: Центр гуманитарной помощи
            2. Область, город: Белгородская область Белгород
            3. Направление помощи: вещевая, продуктовая
            4.Адрес: г. г. Белгород, пр. Гражданский, 50а
            Контакты: 8(4722)33 86 96, 89517672857
            Электронная почта: mal_mar11@mail.ru
            Сайт: нет

            <br/><hr/><br/>
            Владимирская область — Владимир.
            Название организации: Владимирская региональная общественная благотворительная организация "Нечаянная радость", Кризисный центр "Мамин домик"
            Область, город: Владимирская область
            Направление помощи:
            - проживание;
            -психологическая;
            -вещевая;
            - продуктовая
            - духовная
            - юридическая
            Киржачский р-н, с. Ельцы, ул. Лесная 12а
            Контакты:
            8(49237) 71 585;  8-925-017-00-02
            Эл. Почта - mishuevaolga@mail.ru
            Сайт - https://vk.com/mamindomik33
            <br/><hr/><br/>

            Название организации: Владимирская региональная общественная благотворительная организация "Нечаянная радость", Центр гуманитарной помощи
            Область, город: Владимирская область
            Направление помощи:
            -вещевая;
            - продуктовая по запросу
            Киржачский р-н, с. Ельцы, ул. Дзержинского, 2а
            Контакты:
            8(49237) 71 585;  8-925-017-00-02
            Эл. Почта - mishuevaolga@mail.ru
            Сайт - https://vk.com/mamindomik33
            <br/><hr/><br/>


            Название организации: Центр гуманитарной помощи
            Область, город: Владимирская область
            Направление помощи:
            - вещевая,
            - периодически продуктовая
            Адрес:  г. Ковров, ул. Лопатина, д. 13/3 ,
            Контакты: 8-910-178-91-57
            E-mail: n.dobrinskaya@yandex.ru
            сайт в процессе

            <br/><hr/><br/>
            Название организации: Центр поддержки семьи
            Область, город: Владимирская область
            Направление помощи:
            психологическое консультирование, соляная комната, группа кратковременного пребывания для женщин с детьми ( цель-облегчить декретный период кризисных женщин)
            Адрес:  г. Ковров,г. Ковров, ул. Лопатина, д. 13/3, ул. Пугачева, 23а-соляная комната
            Контакты: 8-910-178-91-57
            E-mail: n.dobrinskaya@yandex.ru
            сайт в процессе
            <br/><hr/><br/>
            Название организации: Некоммерческий благотворительный фонд «Дар жизни», Инклюзивный семейный центр Радуга (относится к НБФ "Дар жизни")
            Область, город: Владимирская область
            Направление помощи:
            - вещевая,
            - продуктовая
            гуманитарная, консультативная, оздоровление для детей, психологическая (для многодетных семей и детей-инвалидов), мастер-классы, развивающие занятия для детей с ОВЗ, логопед, дефектолог
            Адрес:  г.Суздаль, ул.Советская, д.38
            Контакты: 8-961-112-22-28
            E-mail: dar_zhizni@mail.ru
            Сайт: http://www.darzhizni.ru/

            <br/><hr/><br/>
            Название организации: Владимирская региональная общественная организация поддержки материнства, детства и защиты нравственных ценностей "Покров семьи" (приют, ЦГП, ЦЗМ)
            Область, город: Владимирская область
            Направление помощи:
            проживание, вещевая, продуктовая, юридическая, психологическая,
            Адрес:  г.Владимир (п.Боголюбово), ул. Западная, 12 а
            Контакты: 8-960-731-97-28
            E-mail: pravlenie@pokrov-s.ru
            Сайт:

            <br/><hr/><br/>

            Название организации: Центр гуманитарной помощи
            Область, город: Владимирская область
            Направление помощи:
            - вещевая
            - продуктовая
            Адрес: г.Владимир, ул. Большая Московская, 106
            Контакты: о. Вячеслав Андросов 8-906-614-25-83
            E-mail:
            Сайт:

            <br/><hr/><br/>

            Название организации: Центр защиты семьи, материнства и детства
            Область, город: Владимирская область
            Владимирская область
            Направление помощи:
            - вещевая,
            - продуктовая
            - юридическая
            - психологическая
            - духовная
            - медицинская
            Адрес:  Владимирская область, с. Приклон, ул Центральная д. 36
            Контакты: 8-960-720-04-09
            E-mail: i.barinova26ioz@yandex.ru
            Сайт: http://mihail-arh.cerkov.ru/

            <br/><hr/><br/>


            Волгоградская область — Волгоград.
            1. Название организации: Центр гуманитарной помощи
            2. Область, город: Волгоградская область
            3. Направление: вещевая, продуктовая
            4. Адрес: Волгоградская область, г. Жирновск, ул. Матросова ,2
            5.Контакты: 8927 069 5931, 8-84454-5-18-95
            Электронная почта: jirnovskblag@mail.ru,  pu18byx@rambler.ru
            Сайт: нет

            <br/><hr/><br/>

            Название организации: Приют для женщин, оказавшимся в трудной жизненной ситуации "Семья"
            Область, город: Волгоградская область
            Направление помощи:
            - приют временного проживания
            - гуманитарная по запросу
            Адрес:  Волгоградская область, г. Волжский, ул. Комсомольская, 36
            Контакты: 8-966-788-93-53
            E-mail: center-family.cerkov.ru, pravcentrsemy@mail.ru; vifleem2012@yandex.ru
            Сайт: http://volblag.cerkov.ru/,  vk.com›eparh_centr_semya

            <br/><hr/><br/>

            Название организации: Центр защиты семьи, материнства и детства
            Область, город: Волгоградская область
            Направление помощи:
            - вещевая
            - психологическая
            - юридическая
            - духовная
            - социальная
            Адрес:  Волгоградская область, г. Волжский, ул. Московская, д. 16
            Контакты: 8-966-788-93-53
            E-mail: center-family.cerkov.ru, pravcentrsemy@mail.ru; vifleem2012@yandex.ru
            Сайт: http://volblag.cerkov.ru/,  vk.com›eparh_centr_semya

            <br/><hr/><br/>   <br/><hr/><br/>

            Название организации: Общественная организация семейный центр "Лествица", приют "ПОКРОВ"
            Область, город: Волгоградская область
            Направление помощи:
            - проживание
            - вещевая
            - продуктовая
            - психологическая
            - юридическая
            Адрес:  Волгоградская область, г. Волгоград, ул. Толбухина, 38, ЦГП Пражская,8
            Контакты: 8-902-098-10-27
            E-mail: volgapokrov@yandex.ru
            Сайт: О центре (lestvicza.ru)
            <br/><hr/><br/>
            Название организации: Приют «Дом милосердия»
            Область, город: Волгоградская область
            Направление помощи:
            - проживание
            - вещевая
            - продуктовая
            - психологическая
            - юридическая
            Адрес:  Волгоградская область, г. Волгоград, Красноармейский район, ул. Кедроградская, 1а
            Контакты: 8-961-691-01-88, 8-917-330-94-20
            E-mail: volgogradovo34@mail.ru
            Сайт: Дом милосердия — Святотроицкий Православный Cоциальный Центр (obitel34.ru)

            <br/><hr/><br/>
            Название организации: Приют «Дом мамы Мария»
            Область, город: Волгоградская область
            Направление помощи:
            - проживание
            - вещевая
            - продуктовая
            - психологическая
            - юридическая
            Адрес:  Волгоградская область, г. Урюпинск, ул. Красногвардейская 1/1
            Контакты: 8-988-495-06-98
            E-mail: maria.dommamy@yandex.ru ; bizinrustik@rambler.ru
            Сайт:
            <br/><hr/><br/>

            Название организации: Центр помощи "Второе дыхание"
            Область, город: Волгоградская область
            Направление помощи:
            - вещевая
            - памперсы
            - дет. питание
            - юридическая по запросу
            Адрес:  Волгоградская область, г. Камышин, ул. 22 партсъезда, д.4
            Контакты: 8-960-877-46-09
            E-mail: borisowa.lt@ya.ru
            Сайт:
            <br/><hr/><br/>
            Название организации: Центр гуманитарной помощи
            Область, город: Волгоградская область
            Направление помощи: гуманитарная
            Адрес:  Волгоградская область, г. Волгоград
            Контакты:
            E-mail: vladimirskaya.druzhina@yandex.ru
            Сайт:
            <br/><hr/><br/>


            Название организации: Приют временного пребывания
            2. Область, город: Волгоградская область
            3. Направление помощи:
            - проживание
            - вещевая
            - продуктовая
            - психологическая
            – юридическая
            - социальная
            Адрес:  Волгоградская область, г. Новоаннинский, ул. Пионерская, 39
            Контакты: 8-904-404-28-00
            E-mail: prihod.agapita@yandex.ru, novprihod@mail.ru
            Сайт:
            <br/><hr/><br/>   <br/><hr/><br/>

            Вологодская область — Вологда.
            1. Название организации : Центр гуманитарной помощи
            2. Область, город: Вологодская область г. Великий Устюг
            3. Направление : гуманитарная
            4.Адрес: г. Великий Устюг
            Контакты: 8-911-509-43-50
            Электронная почта: eparhiaustyug@mail.ru
            Сайт: нет
            <br/><hr/><br/>   <br/><hr/><br/>
            1. Название организации : Центр гуманитарной помощи
            2. Область, город: Вологодская область, г. Вологда
            3. Направление помощи: в основном продуктовая, по запросу вещевая
            4. Адрес: Вологодская область, г. Вологда, ул. Мохова, д.52а
            5. Контакты: телефон для обращений 8-911-514-62-00
            Электронная почта: hram.andreya.perv@mail.ru

            <br/><hr/><br/>   <br/><hr/><br/>

            1. Название организации : Центр гуманитарной помощи

            2. Область, город: г. Вологда

            3. Направление: вещевая, продуктовая

            4.Адрес: г. Вологда , Горловский проезд, 2

            Контакты: Смирнова Екатерина Александровна 8911 444 22 16, 8(8172) 71-55-39
            Электронная почта: kafedralnyisobor@yandex.ru
            Сайт: нет

            <br/><hr/><br/>
            Воронежская область — Воронеж.
            1. Название организации : Центр гуманитарной помощи
            2. Область, город: Воронежская область, с. Хреновое
            3. Направление помощи: по запросу вещевая, продуктовая, памперсы
            4. Адрес: Воронежская область, Бобруйский р-н, с. Хреновое, ул. Пушкина, д. 147
            5. Контакты: телефон для обращений 8-903-655-93-28
            Электронная почта: hramhrenovoe@yandex.ru
            Сайт
            <br/><hr/><br/>


            1. Название организации : Центр гуманитарной помощи

            2. Область, город: Воронежская область

            3. Направление: вещевая, продуктовая, духовная

            4.Адрес: Воронежская область, г. Острогожск, ул. Ленина, 68

            Контакты: 8950 750 93 98,89507512426,8-908132-82-25 Луцкевич Алла Николаевна


            Электронная почта: chernikova3688@mail.ru

            Сайт: нет
            <br/><hr/><br/>

            1. Название организации: АНО защиты материнства "АГАТОС"

            2. Область, город: Воронежская область

            3. Направление: консультационная, духовная, психоcоциальная,  гуманитарная,продуктовая
            4.Адрес: г. Воронеж, генерала Лизюкова, дом 3
            Контакты: 89103400413 и 89103434201 Карпенко Алевтина Анатольевна

            Электронная почта: perinatalpsiholog@yandex.ru

            Сайт: нет
            <br/><hr/><br/>


            1. Название организации: Приют "Дом мамы"  княг. Елисаветы

            2. Область, город: г. Воронеж

            3. Направление: вещевая, продуктовая, духовная, юридическая, психологическая

            4.Адрес: г. Воронеж, Московский проспект, 31 Б

            Контакты: 8910 284 65 68 Елена Владимировна, 89155800331 Ольга Викторовна

            Электронная почта: hram.vp@yandex.ru
            Сайт: нет

            <br/><hr/><br/>

            1. Название организации: Благотворительный фонд "Мир добрых сердец"

            2. Область, город: Воронежская область

            3. Направление:
            проживание, психологическая, гуманитарная, духовная, социально-правовая. Центр комплексной помощи семье

            4.Адрес: Павловский р-н, село Елизаветовка, 2-а, Проспект Революции
            5.Контакты: 8 910 245 37 15
            Электронная почта: nina-vakulina@mail.ru

            Сайт: нет

            <br/><hr/><br/>

            1. Название организации: АНО ЦЗМ"Ангел-Хранитель"Социальный приют "Дом матери и дитя"

            2. Область, город: г. Воронеж

            3. Направление: Является структурным подразделением центра «Ангел-Хранитель». Принимает беременных женщин и женщин с несовершеннолетними детьми, находящихся в трудной жизненной ситуации. В центре оказывается вся необходимая помощь нуждающимся: психологическая, юридическая, вещевая, а также приют временного проживания. Стационар рассчитан на 6-8 женщин с детьми. Срок пребывания -  3 мес.
            4.Адрес: г. Воронеж , ул. Шишкова 65

            5.Контакты: 8960 123 88 22, Илья Игоревич Пилюгин 89507732249

            Электронная почта: a-hranit@yandex.ru

            Сайт: нет

            <br/><hr/><br/>


            1. Название организации: АНО Кризисный центр помощи людям в ТЖС "Покров"

            2. Область, город: Воронежская область

            3. Направление: вещевая, продуктовая, консультационная, можно временно в кризисной ситуации оставить ребенка в центре временного пребывания.

            4.Адрес: Воронежская область, п. Отрадное, ул. Советская 41В

            5.Контакты: Алексеенкова Наталья Юрьевна  руководитель 8952 545 9301

            Электронная почта: nata211106@mail.ru

            Сайт: нет
            <br/><hr/><br/>


            Ивановская область — Иваново.
            Наименование организации: АНО "Колыбель-Южа"
            Область, город: Ивановская, г. Южа
            Направление помощи:
            - вещевая
            - продуктовая
            - доабортное консультирование
            4.   Адрес: г. Южа, ул. Дача 2
            5.   Контакты: телефон для обращений: 8 910 695 80 07
            Электронная почта: gunaevagerne@mail.ru
            Сайт:
            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Ивановская, Иваново
            Направление помощи:
            - временное проживание
            - вещевая
            - продуктовая
            - духовная
            - социальная реабилитация
            4.   Адрес: с. Воробьецово д.21
            5.   Контакты: телефон для обращений: 8926381 38 20
            Электронная почта: o.meliton@mail.ru
            Сайт:
            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Ивановская, г. Кинешма
            Направление помощи:
            - продуктовая
            - вещевая
            - юридическая
            - психологическая
            - доабортное консультирование
            4.   Адрес: г. Кинешма, Волжский бульвар, 4
            5.   Контакты: телефон для обращений: 89038783830, 89303452292
            Электронная почта: kin.eparhiya.buh@gmail.com
            Сайт:
            <br/><hr/><br/>
            Наименование организации: АНО "Колыбель-Шуя"
            Область, город: Ивановская, Шуя
            Направление помощи:
            - временное проживание
            - продуктовая
            - вещевая
            - психологическая
            - юридическая
            - психологическое консультирование
            - логопед, спорт. секции для детей
            4.   Адрес: Шуя, пл. Фрунзе 2
            5.   Контакты: телефон для обращений: 89605095739
            Электронная почта: multimama2010@mail.ru
            Сайт:
            <br/><hr/><br/>
            Наименование организации: Фонд «Колыбель» «Центр защиты беременности и материнства», некоммерческая организация
            Область, город: Ивановская, г. Тейково
            Направление помощи:
            временное проживание
            продуктовая
            вещевая
            психологическая
            юридическая
            4.   Адрес: г. Тейково, д. Горки, д.46а
            5.   Контакты: телефон для обращений: 89038787531
            Электронная почта: karetnicoff@mail.ru
            Сайт:
            <br/><hr/><br/>
            Наименование организации: «Общественный комитет защиты детства, семьи и нравственности «КОЛЫБЕЛЬ»
            Область, город: Ивановская, Иваново
            Направление помощи:
            - временное проживание
            - продуктовая
            - вещевая
            - психологическая
            - юридическая
            - социальная адаптация
            - группы присмотра за детьми
            4.   Адрес: г. Иваново, ул. Войкова 13 (офис), ул. Чкалова 7 (приют), ул. Лежевская 109 (гум. склад)
            5.   Контакты: телефон для обращений: 8(4932)33-42-77
            Электронная почта: evaaa@mail.ru
            Сайт: колыбель-иваново.рф
            <br/><hr/><br/>   <br/><hr/><br/>
            Иркутская область — Иркутск.
            Наименование организации: Центр гуманитарной помощи
            Область, город: Иркутская область
            Направление помощи:
            Вещевая помощь,
            Продуктовая помощь,
            Помощь средствами гигиены и др. непродуктовыми товарами
            Адрес: г. Братск, ж.р. Падун, ул. Полевая,3
            Контакты: Николай (Осипов)	8908 641 64 15
            Эл. почта: bratskhram@mail.ru
            <br/><hr/><br/>

            Наименование организации: Центр гуманитарной помощи
            Область, город: Иркутская область
            Направление помощи:
            Вещевая помощь,
            Помощь средствами гигиены и др. не продуктовыми товарами
            Адрес: г. Братск, ж.р. Энргетик, ул. Макаренко, 22
            Контакты:	Протоиерей Андрей Дорогобид 89025765441, kliricsvu@mail.ru


            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Иркутская область
            Направление помощи:
            Вещевая помощь,
            Продуктовая помощь,
            Помощь средствами гигиены и др. не продуктовыми товарами
            Адрес: г.Братск, Массив Лесной 1, (пересечение улиц Металлургов и Муханова)
            Контакты: протоиерей Дмитрий Кирьян  89025766798 hram_bratsk@mail.ru
            <br/><hr/><br/>
            Калининградская область — Калининград.
            1.Наименование организации: Калининградская епархия Русской православной церкви, Отдел по социальному служению и благотворительности. Кризисный центр для беременных женщин и женщин с детьми "Обитель"
            2.Область, город: Калининградская обл., г.Калининград
            3.Направление помощи:
            Приют временного проживания
            Гуманитарная
            Психологическая
            Социальная
            Юридическая

            4.Адрес: Калининградская обл., поселок Ушакова, ул. Школьная, д. 12
            5.Контакты :  8-911-853-30-91
            Сайт: https://kaliningrad.ya39.su/kaliningradskaya-eparkhiya-otdel-sotsialnomu-sluzheniyu-i-blagotvoritelnosti

            <br/><hr/><br/>

            1.Наименование организации: Центр защиты семьи, материнства и детства
            2.Область, город: Калининградская обл. г.Гусев
            3.Направление помощи:
            Приют временного проживания,
            медпомощь,
            психологическая,
            юридическая
            4.Адрес: Калининградская обл., г. Гусев, Толстого, 2
            5.Контакты :  8-911-860-56-60
            Электронная почта: ortodox76@gmail.com, ortodox75@gmail.com
            <br/><hr/><br/>
            1.Наименование организации: Центр гуманитарной помощи
            2.Область, город: Калининградская обл. г.Озерск
            3Направление помощи:
            гуманитарная
            4Адрес: Калининградская обл., г.Озерск, ул. Советская, 11 (приходской дом)
            5.Контакты : 8918 466 8300, 8-962-446-67-09
            Электронная почта: m_romanko@mail.ru,  podgolosok@gmail.com
            <br/><hr/><br/>
            1. Наименование организации: Калининградская региональная, социальная духовно-нравственно направленная общественная организация "Союз территорий"
            2. Область, город: Калининградская обл. г. Советск
            3. Направление помощи:
            юридическая по запросу,
            психологическая по запросу,
            продуктовая,
            Адрес: Калининградская обл., г. Советск, ул Победы 4а
            Контакты : Маковиенко Оксана 8-921-852-13-84
            Электронная почта: blagohsinie39@yandex.ru
            Сайт: союз-территорий.рф

            <br/><hr/><br/>

            1.Наименование организации: Центр гуманитарной помощи
            2.Область, город: Калининградская обл. г. Советск
            3. Направление помощи :
            Гуманитарная
            Юридическая по запросу
            4. Адрес: Калининградская обл., г.Советск, ул.Калининградское шоссе 1 б
            5. Контакты : 8-921-613-32-09
            Электронная почта: nadezhda.borodina89@yandex.ru
            <br/><hr/><br/>

            Калужская область — Калуга.
            Наименование организации: Центр гуманитарной помощи
            Область, город: Калужская обл.,г.Калуга
            Направление помощи :
            гуманитарная
            4. Адрес: г.Калуга , Никитина 66 стр 2
            5. Контакты : Слинченко Олег 8918 466 8300, 8-962-446-67-09
            Электронная почта: alpelevin@mail.ru

            <br/><hr/><br/>

            Наименование организации: Центр помощи беременным и женщинам в трудной жизненной ситуации "Материнский ковчег"
            Область, город: Калужская обл.,г.Калуга
            Направление помощи:
            Приют временного проживания
            гуманитарная,
            психологическая,
            юридическая,
            социальная
            4.Адрес: г. Калуга, Салтыкова- Щедрина д. 55
            5.Контакты : слинченко Олег 8918 466 8300, 8-962-446-67-09
            Электронная почта: diaconia-eparhia@yandex.ru
            <br/><hr/><br/>

            1.Наименование организации: Социальный Благотворительный Фонд "Покров", дочка Семейный приют "Ковчег"
            2.Область, город: Калужская обл.,г.Калуга
            3.Направление помощи:
            приют временного проживания
            гуманитарная,
            психологическая,
            юридическая,
            социальная
            4.Адрес: Калужская обл., д. Киреевское-2, ул. Школьная , д. 58
            5.Контакты : 8-985-078-78-78
            Электронная почта: info@pokrov-fond.ru
            Сайт: https://pokrov-fond.ru/
            1.Наименование организации: Калужская региональная общественная организация «Милосердие»
            2.Область, город: Калужская обл., г.Калуга
            3.Направление помощи:
            гуманитарная
            4.Адрес: г. Калуга, ул. Салтыкова Щедрина, д.27
            5.Контакты: 8-953-337-81-23 Виктория
            Электронная почта: tori197848@gmail.ru
            <br/><hr/><br/>


            1.Наименование организации : Калужская региональная благотворительная общественна организация "Центр поддержки семьи, материнства и детства "Жизнь"
            2.Область, город: Калужская обл., г.Калуга
            3. Направление помощи:
            психологическая,
            юридическая,
            группа временного пребывания детей,
            семейный клуб
            гуманитарная
            4.Адрес: г.Калуга, ул. Постовалова, 7
            5.Контакты: 8-953-319-26-52; и по группе в ВК (центр жизнь Калуга)
            Электронная почта: vladlina@inbox.ru
            Сайт: группа вконтакте
            1.Наименование организации: Центр гуманитарной помощи
            2.Область, город: Калужская обл.,г.Боровск
            3.Направление помощи:
            вещевая
            4.Адрес: Калужская обл., г. Боровск , ул. Коммунистическая, 102

            <br/><hr/><br/>
            Кемеровская область (Кузбасс) — Кемерово.
            1.Наименование организации: Приют временного пребывания для женщин с детьми, пострадавших от семейного насилия
            2.Область, город: Кемеровская область, г. Юрга
            3.Направление помощи:
            Приют временного проживания
            Гуманитарная,
            Юридическая,
            Психологическая
            Социальная

            4.Адрес г. Юрга, пр-т. Кузбасский, д.15а
            5.Контакты: 8-902-758-70-88
            Электронная почта: aos_09@mail.ru;  me-oss@yandex.ru
            <br/><hr/><br/>



            1.Наименование организации: АНО "Центр защиты семьи, материнства, детства и гражданского благополучия "Спаси жизнь"
            2.Область, город: Кемеровская область г. Прокопьевск
            3.Направление помощи:
            психологическая,
            гуманитарная,
            социальная
            4.Адрес: г. Прокопьевск, ул. Союзная 4, 1 этаж
            5.Контакты: 8-950-579-27-27
            Электронная почта zhenya_udarcev@mail.ru
            Сайт: https://sos-family.ru/
            <br/><hr/><br/>
            Киргизия
            1.Наименование организации: Приют "Надежда"
            2.Область, город: Киргизияг. Бишкек
            3.Направление помощи:
            духовная,
            психологическая,
            гуманитарная,
            социальная,
            юридическая
            4.Адрес: г. Бишкек, проспект Жибек Жолу, 497
            5.Контакты: +996 (312) 32-53-61

            Электронная почта: bdommiloserdiya@mail.ru
            <br/><hr/><br/>

            Кировская область — Киров.
            1.Наименование организации: Приют "Надежда"
            2.Область, город: Кировская область, Уржумский район, г. Уржум
            3.Направление помощи:
            вещевая,
            продуктовая по возможности
            4.Адрес г.Уржум, ул.Советская, 19
            5.Контакты: 8 833 632-15-82
            Электронная почта: social@urzhum-eparhiya.ru


            <br/><hr/><br/>

            1.Наименование организации : Центр гуманитарной помощи
            2.Область, город: Кировская областьг. Киров
            3.Направление помощи :
            гуманитарная
            4.Адрес : г. Киров, ул.Герцена 42 б,
            5.Контакты: 8-912-330-52-01
            Электронная почта: hramvsezariza@yandex.ru


            <br/><hr/><br/>
            1.Наименование организации : Межрегиональная Общественная организация "Центр защиты материнства и детства "Моя семья"
            2.Область, город: Кировская область ,г. Киров
            3.Направление помощи :
            просветительская
            психологическая
            организация досуга детей
            гуманитарная

            4.Адрес : Консультационный центр- г.Киров, ул. Пятницкая 9, гуманитарный склад- г. Киров, ул. Свободы,  70-А
            5.Контакты: 8-912-710-08-40, горячая линия 8 800 100 30 70
            Электронная почта: semya43@bk.ru
            Сайт: https://sos-life.ru/kirov-pomoshch-beremennym
            <br/><hr/><br/>

            1.Наименование организации : Центр гуманитарной помощи
            2.Область, город: Кировская область г.Котельнич
            3.Направление помощи:
            Гуманитарная
            Социальная
            Психологическая
            юридическая
            4.Адрес г.Котельнич, ул.Шмидта 30,
            5.Контакты : 8(915)204-20-93
            Электронная почта: m.kryukova78@mail.ru
            <br/><hr/><br/>

            1.Название организации : Центр гуманитарной помощи
            2.Область, город: Кировская область г. Яранск
            3.Направление помощи :
            Гуманитарная
            Социальная
            Психологическая
            юридическая
            4.Адрес : г. Яранск, ул. Карла Маркса  14
            5.Контакты : 8(915)204-20-93
            .Электронная почта: m.kryukova78@mail.ru
            <br/><hr/><br/>
            1.Наименование организации : Центр гуманитарной помощи
            2.Область, город: Кировская область пгт Кикнур
            3.Направление помощи:
            Гуманитарная
            Социальная
            Психологическая
            юридическая

            4.Адрес : пгт.Кикнур, ул.Красноармейская 3
            5.Контакты 8(915)204-20-93
            Электронная почта: m.kryukova78@mail.ru, hramkiknur@mail.ru
            <br/><hr/><br/>
            Костромская область — Кострома.
            1.Наименование организации : Приют "Ласточкино гнездо"
            2.Область, город: Костромская область , с. Шайма
            3.Направление помощи :
            Приют временного проживания,
            юридическая,
            психологическая,
            социальная,
            гуманитарная
            4.Адрес : Палинский р-н, с. Шайма, д. 13
            5.Контакты : 8-926-454-21-11, 8 926 932 08 77
            Электронная почта: ludmiladyadyura@yandex.ru
            <br/><hr/><br/>
            Курганская область — Курган.
            1.Наименование организации : Центр защиты семьи, материнства и детства
            2.Область город : Курганская область г. Курган
            3. Направление помощи :
            Гуманитарная
            психологическая,
            юридическая,
            социальная
            4.Адрес : г. Курган, ул. Ястржемского, 41а
            5.Контакты : 8-963-010-33-57 тел. горячей линии, психолог Надежда Анатольевна психолог
            Электронная почта: port-artur-hram.ru, miloserdie-45@mail.ru
            Сайт: port-artur-hram.ru
            <br/><hr/><br/>
            1.Наименование организации : Центр гуманитарной помощи
            2.Область город : Курганская область, г. Шадринск
            3.Направление помощи :
            гуманитарная
            4.Адрес : г. Шадринск, ул.  Ломоносова, 12
            5.Контакты : 8-919-583-20-04
            Электронная почта:  hramkresti@mail.ru

            <br/><hr/><br/>
            Курская область — Курск.
            1.Наименование организации : Межрегиональная общественная организация "Социальный навигатор"
            2.Область город : Курская область, г. Курск
            3. Направление помощи :
            Консультационная
            4.Адрес : г. Курск , ул. Дзержинского,80,  офис "Независимая экспертиза"
            5.Контакты :  8-982-331-49-56
            Электронная почта:  nazarov-tri@mail.ru
            Сайт: в разработке

            <br/><hr/><br/>
            Ленинградская область — Санкт-Петербург.

            Наименование организации: Приют "Благодать"
            Область, город: Санкт –Петербург;
            Направление помощи:
            -временное проживание для выпускниц детских домов Санкт-Петербурга и Ленинградской области,
            юридическая,
            социальная,
            психологическая
            4. Адрес: Санкт-Петербург, Московский пр-т, д. 104
            5. Контакты: телефон для обращений 8-931-305-34-56,
            Электронный адрес: e.pavlova73@mail.ru
            <br/><hr/><br/>

            Наименование организации: Благотворительный фонд "Теплый дом" г. Санкт-Петербург;
            Область, город: Санкт –Петербург;
            Направления помощи:
            - психологическая,
            - социальная,
            - материальная.
            4. Адрес: г.Санкт-Петербург, ул. Оружейника Федорова, д.2
            5. Контакты: телефон для обращений 8 912 975 81 65, +7(812)275-81-65,
            электронный адрес: info@domgdeteplo.ru
            сайт: https://domgdeteplo.ru

            <br/><hr/><br/>
            1. Наименование организации: Благотворительный фонд св. Димитрия Солунского;
            2. Область, город: Санкт –Петербург;
            3. Направление помощи:
            психологическая,
            - социальная,
            - материальная,
            - гуманитарная.
            4. Адрес: г. Санкт-Петербург, Наличная д. 23
            5. Контакты: телефон для обращений 812 374 66 47; 8 921 847 21 90,
            Электронный адрес: dimitiusfond@yandex.ru
            Сайт: https://fondsolunskiy.ru

            <br/><hr/><br/>

            Наименование организации: Центр гуманитарной помощи
            Область, город: Санкт –Петербург;
            Направления помощи:
            - гуманитарная.
            Адрес: г. Санкт-Петербург, Красное Село, ул. Массальского, 4 А
            Контакты: телефон для обращений 8 981 106 97 23, 8 911 252 19 49,
            Электронный адрес: stansor@mail.ru,
            Сайт: http://blagotvorite.com/


            <br/><hr/><br/>
            Наименование организации: Автономная некоммерческая организация предоставления услуг в социальной сфере Центр социальных инноваций «Жизнь»;
            Область, город: Санкт –Петербург;
            Направления помощи:
            - гуманитарная,
            -юридическая,
            -социальная,
            -психологическая.
            4. Адрес: г. Санкт-Петербург, ул. Набережная водного канала,116
            5. Контакты: телефон для обращений 8911 734 04 04,
            Электронный адрес: info@lifecenter.spb.ru,
            сайт: https://www.centerlife.info/

            <br/><hr/><br/>
            Наименование организации: Центр защиты материнства
            Город: Санкт-Петербург;
            Направления помощи:
            -психологическая,
            - юридическая,
            - социальная,
            - гуманитарная.
            4. Адрес: г. Санкт-Петербург, ул. Крупской , 5
            5. Контакты: телефон для обращений: 8 921 772 34 44
            Электронная почта: keeplife.spb@gmail.com
            Сайт: https://vk.com/czmspb

            <br/><hr/><br/>
            Наименование организации: Благотворительный фонд «Благо дари»
            Область, город: Ленинградская область, г. Гатчина
            Направления помощи:
            - гуманитарная,
            - психологическая.
            4. Адрес: Ленинградская область, г. Гатчина, ул. Красная, 1 в
            5. Контакты: телефон для обращений: 8 903 099 54 54
            Электронная почта: info@blagodari.org
            Сайт: https://blagodari.org/

            <br/><hr/><br/>
            Наименование организации: Региональная общественная организация в защиту детей, беременных женщин и традиционных семейных ценностей "Две полоски";
            Область, город :Ленинградская область, г. Волосово;
            Направления помощи:
            - психологическая,
            - гуманитарная,
            -кратковременное проживание в случае кризисной ситуации,
            Адрес: Ленинградская область, г. Волосово, ул. Красных командиров, 7
            Контакты: телефон для обращений 8 909 590 95 26; 8981 823 20 48,    Электронный адрес: help@dve-poloski.ru

            <br/><hr/><br/>

            Наименование организации: Центр гуманитарной помощи
            Область, город: Ленинградская область, г. Петергоф;
            Направления помощи:
            - гуманитарная
            - социальная
            - юридическая.
            4. Адрес: г. Петергоф, ул. Санкт-Петербургский проспект, дом 32
            5. Контакты: телефон для обращений: 8 (921)913-21-88
            Электронный адрес: warlamova-77@mail.ru
            <br/><hr/><br/>

            Магаданская область — Магадан.

            Наименование организации: Автономная некоммерческая организация "Колыма -За Жизнь"
            Область, город : Магаданская область, г. Магадан;
            Направления помощи:
            - приют временного пребывания,
            - социальная,
            - психологическая,
            - юридическая,
            - гуманитарная,
            4. Адрес: Магаданская область, г. Магадан, ул. Парковая, 9/12,
            5. Контакты: телефон для обращений 8914 858 90 02;
            Электронный адрес: kolymazhizn@yandex.ru,
            Сайт: https://kolyma-za-zhizn.ru/

            <br/><hr/><br/>

            Московская область — Москва.
            Наименование организации: Автономная некоммерческая организация «Дом для мамы»;
            Город Москва;
            Направления помощи:
            -приют временного проживания,
            -гуманитарная,
            -социальная,
            -психологическая,
            -юридическая
            -материальная.
            4. Адрес: г. Москва, ул. Станиславского 22, стр. 1 (приют),
            Ул. Николоямская 49, стр.3 (Центр гуманитарной помощи),
            Ул. Николоямская 52, стр.2 (Консультационный центр)
            5. Контакты: телефон для обращений: 8499350 52 77, 8965262 98 78
            Электронная почта: info@domdliamamy.ru; domdliamamy77@yandex.ru
            Сайт: https://domdliamamy.ru/
            <br/><hr/><br/>



            Наименование организации: Автономная некоммерческая благотворительная организация помощи беременным и нуждающимся семьям с детьми «Спаси жизнь»
            Город Москва
            Направления помощи:
            -психологическая
            -юридическая
            -социальная
            - гуманитарная
            -материальная
            4. Адрес: Москва, ул. Малыгина 7 (центр гуманитарной помощи),
            Ул. Ленинский проспект , д.158,  офис 13, 14, 14А/0232 (консультационный центр)
            5. Контакты: телефон для обращений :8495142 49 01
            Электронный адрес: soslife@yandex.ru
            Сайт: www.sos-life.ru
            <br/><hr/><br/>

            Наименование организации: Центра помощи семье и детям «Растим вместе»
            Область, город: Московская область, городской округ Солнечногорск, деревня Голубое
            Направления помощи:
            - приют временного проживания,
            - социальная,
            -психологическая,
            - гуманитарная.
            4. Адрес: Московская область, городской округ Солнечногорск, деревня Голубое,             Тверецкий проезд, дом 16А
            5. Контакты: телефон для обращений: +7(916)415-88-13
            Электронный адрес: luninataliya@yandex.ru
            Сайт: https://vk.com/rastim_v_meste
            <br/><hr/><br/>
            Наименование организации: Приют для беременных женщин
            Область, населенный пункт: Московская область, п. Измайлово
            Направления помощи:
            - приют временного проживания,
            - социальная,
            - психологическая,
            -гуманитарная.
            4. Адрес: Московская область, сельское поселение Булатниковское, п. Измайлово,
            дом 21
            5. Контакты: телефон для обращений: 8 905 715 43 69
            Электронный адрес: slg1308@yandex.ru

            <br/><hr/><br/>

            Наименование организации: Автономная благотворительная некоммерческая организация «Надежда»
            Область, город: Московская область, г. Егорьевск,
            Направления помощи:
            - социальная,
            - психологическая,
            - гуманитарная
            4. Адрес: Московская область, г. Егорьевск, ул. Владимирская, д. 6.
            5. Контакты: телефон для обращений: 8 903 503 26 90
            Электронный адрес: nadezhda.boo@gmail.com
            Сайт: www.nadezhda-boo.ru
            Наименование организации: Благотворительный фонд поддержки материнства и детства «ЕВА»
            Область, город: Московская область, г. Подольск
            Направления помощи:
            - социальная
            - гуманитарная
            - юридическая,
            - психологическая
            4. Адрес: Московская область, г. Подольск, ул. ул. Мира 12/5,
            5. Контакты: телефон для обращений: 8926577 11 11
            Электронный адрес: fond-eva@mail.ru
            Сайт: https://fond-eva.ru/
            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Город Москва
            Направления помощи:
            -гуманитарная
            4. Адрес: Москва, ул. Воротынская, д. 14,
            5. Контакты: телефон для обращений: 8 962 947 08 01
            Электронный адрес: info@georgkurkino
            Сайт: http://georgkurkino.ru
            Наименование организации: Центр гуманитарной помощи
            Область, город: Московская область, г. Талдом
            Направление помощи:
            - гуманитарная
            4. Адрес: Московская область, г. Талдом, ул. Карла Маркса , д. 8
            5. Контакты: телефон для обращений: 8(906) 773-53-57
            Электронный адрес: annaorthodox@mail.ru
            Сайт: https://vk.com/taldomchram
            <br/><hr/><br/><br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, населенный пункт: Московская область, г. Можайский район, п. Уваровка
            Направления помощи:
            - гуманитарная,
            - психологическая,
            - социальная.
            4. Адрес: Московская область, г. Можайский район, п. Уваровка, по запросу
            5. Контакты: телефон для обращений: 8-916-615-51-37
            Электронный адрес: yevmenenko@mail.ru, papamamaimir@yandex.ru
            Сайт: https://vk.com/public200176138
            <br/><hr/><br/>

            Наименование организации: Центр гуманитарной помощи
            Область, населенный пункт: Московская область, поселок Андреевка
            Направления помощи:
            - гуманитарная
            4. Адрес: Московская область, поселок Андреевка, ул.Староандреевская, д.73
            5. Контакты: телефон для обращений: 8 926 224 04 18,
            Электронный адрес: luninataliya@yandex.ru
            Сайт: https://vk.com/spasandreevka

            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, населенный пункт: Московская область, г. Серпухов
            Направления помощи: гуманитарная
            4. Адрес: Московская область, поселок Андреевка, г. Серпухов
            5. Контакты: телефон для обращений: 8-926-524-93-75; 8(4967) 77 46 25
            Электронный адрес: m-aleksia@yandex.ru
            Сайт:

            <br/><hr/><br/>
            Мурманская область — Мурманск.

            Наименование организации: Мурманская региональная общественная организация в защиту жизни «Колыбель»
            Область, населенный пункт: Мурманская область, г. Мурманск,
            Направления помощи:
            - приют временного проживания,
            - психологическая,
            - юридическая,
            - социальная,
            - гуманитарная.
            4.  Адрес: Мурманская область, г. Мурманск, ул. Бочкова, 13
            5. Контакты: телефон для обращений: +7(8152)75-22-10
            Электронная почта: mroo.zhizn@yandex.ru
            Сайт: http://www.kolybel51.ru
            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи «Стезя милосердия».
            Область, город: Мурманская область, г. Североморск,
            Направления помощи:
            - гуманитарная.
            4.  Адрес: Мурманская область, г. Североморск, ул. Советская, д. 21 А
            5.  Контакты: телефон для обращений: +7 911 320 12 53
            Электронная почта: esperatemplo@yandex.ru
            Сайт: https://vk.com/stezyam
            <br/><hr/><br/>
            Нижегородская область — Нижний Новгород.
            Наименование организации: Центр гуманитарной помощи
            Область, город: Нижегородская область, пос.Вознесенское
            Направления помощи:
            - гуманитарная
            - вещевая, продуктовая
            4. Адрес: Нижегородская область, пос.Вознесенское, ул. Советская, 36
            5. Контакты: телефон для обращений: 8 (83178) 6 23 84
            Электронный адрес: vozblago@mail.ru
            <br/><hr/><br/>
            Наименование организации: Автономная некоммерческая организация  Центр помощи семье и детям "Ярослава"
            Область, город: Нижегородская область, г. Нижний Новгород
            Направления помощи:
            - приют временного проживания,
            - социальная,
            - психологическая,
            - юридическая,
            - гуманитарная.
            4. Адрес: Нижегородская область, г. Нижний Новгород, ул. Василия Иванова, 14/4, 133
            5. Контакты: телефон для обращений: +7 950 372 68 22, +7 902 300 68 32
            Электронная почта: mariya.r-52@mail.ru
            Сайт: http://yroslava.ru/about/
            <br/><hr/><br/>


            Наименование организации: Центр помощи семье и детям «Быть мамой»
            Область, город: Нижегородская область, г. Нижний Новгород
            Направления помощи:
            - приют временного проживания,
            -психологическая,
            - социальная,
            - юридическая,
            - гуманитарная.
            4. Адрес: Нижегородская область, г. Нижний Новгород, ул. Суздальская 58
            5. Контакты: телефон для обращений 8 (908) 741- 02- 88
            Электронный адрес: bmnne@mail.ru
            Сайт: https://vk.com/bm_center
            <br/><hr/><br/><br/><hr/><br/>
            Наименование организации: Социальная общественная организация "Забота"
            Область, город: Нижегородская область, г. Выкса
            Направления помощи:
            - гуманитарная,
            - психологическая,
            - социальная
            - юридическая
            4. Адрес: Нижегородская область, г. Выкса, ул. Чкалова 64/2
            5. Контакты: телефон для обращений: 8-915-930-60-59; 8-910-135-22-20
            Электронный адрес: zabota1966@mail.ru
            Сайт: http://zabota-vyksa.ru/
            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Нижегородская область, г. Павлово
            Направления помощи:
            - гуманитарная
            4. Адрес: Нижегородская область, г. Павлово, ул. Шмидта, д.6
            5. Контакты : телефон для обращений: 8-950-627-14-63
            Электронная почта:pavlovo-blag@mail.ru
            Сайт: https://vk.com/public163026467
            Наименование организации: Дом для мамы «Забота»
            Область, город: Нижегородская область, г. Выкса,
            Направления помощи:
            -Приют временного проживания,
            - социальная,
            -психологическая,
            - юридическая,
            -гуманитарная
            4. Адрес: Нижегородская область, г. Выкса, ул. Чкалова 64
            5. Контакты: телефон для обращений 8 (83177) 6 55 88
            Электронная почта: ocbss-vyksa@mail.ru
            Сайт: https://blagovyksa.ru/
            <br/><hr/><br/>
            Наименование организации: Социальный центр «Покров»
            Область, город: Нижегородская область, г. Дзержинск.
            Направления помощи:
            - гуманитарная,
            -социальная,
            - психологическая
            4.  Адрес: Нижегородская область, Дзержинск,  Свято-Тихоновский проезд, д. 1.
            5. Контакты: телефон для обращений: +7(910)387-00-87
            Электронная почта: soc_centr.pokrov@mail.ru
            Сайт: https://vk.com/soc_centr.pokrov
            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Нижегородская область, г. Нижний Новгород
            Направления помощи:
            - социальная,
            - гуманитарная
            4. Адрес: Нижегородская область, г. Нижний Новгород, ул. улица Суздальская, 58
            5. Контакты: телефон для обращений: 8 (831) 269-77-72
            Электронный адрес: mednne@yandex.ru; meds@nne.ru
            Сайт: https://socnne.vsite.biz/

            <br/><hr/><br/>
            Наименование организации. Социальный центр «Отрада и утешение»
            Область, город: Нижегородская область, г. Арзамас
            Направления помощи:
            - социальная,
            - гуманитарная,
            - юридическая,
            - психологическая
            4. Адрес: Нижегородская область, г. Арзамас, ул. Верхняя Набережная, д.11
            5. Контакты: телефон для обращений: 8 910 792 70 19
            Электронный адрес: nalasib43@yandex.ru

            Сайт: https://vk.com/public129971384
            <br/><hr/><br/><br/><hr/><br/>

            Наименование организации: Центр гуманитарной помощи
            Область, город: Нижегородская область, г. Лысково
            Направления помощи:
            - гуманитарная
            4. Адрес: Нижегородская область, г. Лысково,  ул. Горького, д.5 или ул. Головина д.2 (Казанский Храм).
            5. Контакты: телефон для обращений : 8 952-451-00-60
            Электронный адрес: lsk-social@yandex.ru
            Сайт: http://lsk-kz.ru/contacts/

            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Нижегородская область, г. Саров.
            Направления помощи:
            - гуманитарная
            4. Адрес: Нижегородская область, ул. Пушкина, д. 24 А
            5. Контакты: телефон для обращений: 8 8313 07 70 99,  8 987 535 40 15
            Электронный адрес: kutsyk.anna@mail.ru
            Сайт- нет
            <br/><hr/><br/>
            Новгородская область — Великий Новгород.
            Наименование организации: Районная общественная организация по защите семьи, духовно-нравственных ценностей и благополучия населения “Мой город”
            Область, город: Новгородская область, г. Пестово
            Направления помощи:
            - гуманитарная,
            - психологическая
            - психиатрическая
            - юридическая
            4. Адрес: Новгородская область, г. Пестово, ул. Вокзальная, д. 29
            5. Контакты: телефон для обращений: 8-911-063-42-49
            Электронный адрес: 291272@bk.ru
            Сайт: нет
            <br/><hr/><br/>


            Новосибирская область — Новосибирск.
            Наименование организации: Центр защиты семьи, материнства и детства
            Область, город: Новосибирская область, г. Новосибирск
            Направления помощи:
            - гуманитарная,
            - психологическая
            - психиатрическая
            - юридическая
            4.   Адрес: Новосибирская область, г. Новосибирск, ул. Народная, 75
            5. Контакты: телефон для обращений: 8-913-010-25-68;
            Электронный адрес: znamenskiy-hram@mail.ru
            <br/><hr/><br/>
            Наименование организации: Приют "Мать и дитя"
            Область, город: Новосибирская область, г. Новосибирск
            Направления помощи:
            - Приют временного проживания,
            - гуманитарная,
            - психологическая,
            - социальная
            4. Адрес: Новосибирская область, г. Новосибирск, ул. Полярная, д.22
            5. Контакты: телефон для обращений: 8 913 728 44 64
            Электронный адрес: moskv_2016@mail.ru
            Сайт: https://vk.com/mami_deti54
            <br/><hr/><br/>
            Наименование организации: Центр защиты семьи, материнства и детства
            Область, город: Новосибирская область, г. Искитим
            Направления помощи:
            - гуманитарная (вещевая, продуктовая, дет.гигиена)
            - психологическая по запросу
            - юридическая по запросу
            - духовная
            4. Адрес: Новосибирская область, г. Искитим, ул. Комсомольская, д. 19

            5. Контакты: телефон для обращений: 8-913-896-96-01
            Электронный адрес: brylevskiy82@mail.ru,  iskitimeparhia@yandex.ru
            Сайт: http://iskitimeparhia.ru

            <br/><hr/><br/>

            Наименование организации: Центр защиты семьи, материнства и детства
            Область, город: Новосибирская область, Новосибирск (ЦЗМ)
            Направления помощи:
            - гуманитарная,
            - психологическая
            - социальная
            4. Адрес: Новосибирская область, г. Новосибирск, ул. Советская, 91
            5. Контакты: телефон для обращений: 8-800-70-70-222
            Электронный адрес: o$$cb@mail.ru
            Сайт: нет

            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Новосибирская область, Новосибирск
            Направления помощи:
            - вещевая
            4. Адрес: Новосибирская область, г. Новосибирск, ул.Новогодняя, 24
            5. Контакты: телефон для обращений: 8-953-760-18-22
            Электронный адрес: делают
            Сайт: нет



            <br/><hr/><br/>

            Наименование организации: Благотворительный фонд «В защиту жизни детей»
            Область, город: Новосибирская область, Новосибирск,
            Направления помощи:
            - гуманитарная,
            - психологическая,
            - социальная
            4. Адрес: Новосибирская область, г. Новосибирск, ул. Сибркома, д.20 (гуманитарный склад) ул. Фабричная, дом 4, к.210/6 (консультационный центр)
            5. Контакты: телефон для обращений: +7(953)807-35-50, +7(923)136-20-20.
            Электронный адрес: sib-pro-life@rambler.ru
            Сайт: http://ansobor.ru/

            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Новосибирская область, г. Куйбышев (ЦГП)
            Направления помощи:
            - гуманитарная,
            - социальная
            4. Адрес: Новосибирская область, г. Куйбышев, ул. Свердлова 34
            5. Контакты: телефон для обращений: 8-913-894-55-11 (звонить до 18.00)
            Электронный адрес: prihod1904@mail.ru
            Сайт- нет
            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Новосибирская область, г. Бердск
            Направления помощи:
            - вещевая,
            - одежда
            4. Адрес: Новосибирская область, г. Бердск, Максима Горького, 44
            5. Контакты: телефон для обращений: 8-383-416-15-54
            Электронный адрес:
            Сайт: http://berdsk.orthodoxy.ru
            <br/><hr/><br/>
            Омская область — Омск.
            Наименование организации:  Центр защиты материнства «Нечаянная радость» Приют "Радость материнства"
            Область, город: Омская область, г. Омск
            Направления помощи:
            временного проживания;
            психологическая помощь;
            социальная помощь;
            юридическая помощь;
            материальная помощь
            4. Адрес: Омская область,  г.Омск, ул.Гусарова, 30, кв.
            5. Контакты: 8 381 295 59 79; 8 913 684 38 58
            E-mail: omskdeti@mail.ru
            Сайт: http://xn----8sbmjzpllj5a9e.xn--p1ai/blog/novosti/itogi_2020_goda
            <br/><hr/><br/>

            Наименование организации: Центр гуманитарной помощи "Милосердие (ЦГП)
            Область, город: Омская область, г. Калачинск
            Направления помощи:
            гуманитарная помощь
            4. Адрес: Омская область, г. Калачинск, ул. Семашко, д. 21
            5. Контакты: 8908104 09 97, 8(381 55) 21210
            E-mail: buryaknatali@yandex.ru
            Сайт: https://vk.com/wall-184038427_32

            <br/><hr/><br/>
            Наименование организации: Автономная некоммерческая благотворительная организация Серафимо-Вырицкая обитель милосердия
            Область, город: Омская область, г. Омск
            Направления помощи:
            вещевая помощь,
            продуктовая помощь
            психологическая помощь,
            социальная помощь,
            юридическая помощь;
            материальная помощь по запросу
            4. Адрес: Омская область,  г. Омск, ул. Куйбышева, д.10
            5. Контакты: 8-800-201-01-13
            E-mail: obitel.miloserdia@mail.ru
            Сайт: http://obitel-omsk.ru/
            <br/><hr/><br/>

            Оренбургская область — Оренбург
            Наименование организации: Центр защиты материнства "Колыбель"
            Область, город: Оренбургская область, г. Оренбург
            Направления помощи:
            временное проживание,
            психологическая помощь,
            социальная помощь,
            юридическая помощь,
            гуманитарная помощь
            Адрес: Оренбургская область, г. Оренбург, ул. Конституции СССР 24/1
            Контакты: +7(3532)20-17-71
            E-mail: evgeniya8856@mail.ru
            Сайт: колыбель56.рф

            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Оренбургская область, г. Бугуруслан
            Направления помощи:
            вещевая помощь;
            продуктовая помощь;
            средства гигиены по возможности
            Адрес: Оренбургская область, г. Бугуруслан, Промысловая, д.20
            Контакты: 8-353-523-61-71
            E-mail: sobor-troica@sobor-troica.ru
            Сайт: https://sobor-troica.ru/
            <br/><hr/><br/>

            Наименование организации: Центр гуманитарной помощи
            Область, город: Оренбургская область, г. Орск.
            Направления помощи:
            гуманитарная помощь
            4. Адрес: Оренбургская область, г. Орск, ул. Добровольского, 18
            5. Контакты: 8 (922) 814 91 64
            E-mail: cozotdel@mail.ru
            Сайт: https://vk.com/club160995797

            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Оренбургская область, г. Бузулук
            Направления помощи:
            гуманитарная помощь
            Адрес: Оренбургская область, г. Бузулук, ул. Серго, 7
            Контакты: 8 922-54-88-555,
            E-mail: anastasiy_b@mail.ru, irinagroschewa@yandex.ru
            Сайт: https://vk.com/miloserdie_buzuluk

            <br/><hr/><br/>
            Орловская область — Орел

            Наименование организации: Приют для мам  "Все в твоих руках"
            Область, город: Орловская область, г. Ливны
            Направления помощи:
            временное проживание помощь;
            психологическая помощь;
            духовная помощь;
            юридическая помощь;
            социальная помощь;
            обучение швейному делу

            Адрес: Орловская область, г. Ливны, ул. Дружбы народов, 165
            Контакты: 8(46677)2-00-55,  8-920-080-01-10
            E-mail: anastasiy_b@mail.ru, irinagroschewa@yandex.ru
            Сайт: gro71@bk.ru

            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Орловская область, г. Ливны
            Направления помощи:
            вещевая помощь
            4. Адрес: Орловская область, г. Ливны, ул. Ленина, 26
            5. Контакты: 8-920-080-01-10
            E-mail: vikhrv-vitalijj@mail.ru,  gro71@bk.ru

            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Орловская область, г. Ливны
            Направления помощи:
            продуктовая помощь
            Адрес: Орловская область, г. Ливны, г. Ливны, ул. Свердлова 52
            Контакты: 8-920-080-01-10
            E-mail: fotinaaa@gmail.com; gro71@bk.ru

            <br/><hr/><br/>
            Наименование организации: АНО "Небесные ласточки" (Центр защиты семьи, материнства и детства)
            Область, город: Орловская область, г. Ливны
            Направление помощи:
            консультационная помощь;
            психологическая помощь
            4. Адрес: Орловская область, г.Ливны, ул. Пушкина, 10
            5. Контакты: 8-920-080-01-10
            E-mail: gro71@bk.ru
            <br/><hr/><br/>

            Наименование организации: Центр гуманитарной помощи
            Область, город: Орловская область, г. Орел
            Направления помощи:
            гуманитарная помощь
            4. Адрес: Орловская область, г. Орел, улица Нормандия-Неман, д. 27
            5. Контакты: +7(953)618-54-08
            E-mail:  Anokhin-Oleg79@inbox.ru
            Сайт: https://vk.com/club205297796

            <br/><hr/><br/>
            Пензенская область — Пенза
            1. Наименование организации: Центр помощи семье "Покров"
            2. Область, город: Пензенская область, г. Пенза
            3. Направление помощи:
            временное проживание;
            психологическая помощь;
            вещевая помощь;
            продуктовая помощь;
            семинары для психологов
            4. Адрес :г. Пенза, ул. Красная горка, 24
            5. Контакты: 8 (8412) 71 28 04
            E-mail: fondpokrov@mail.ru
            Сайт: fond-pokrov.

            <br/><hr/><br/>
            1. Наименование организации: Автономная некоммерческая организация Центр социальной поддержки граждан "Содействие"
            2. Область, город: Пензенская область, г. Кузнецк
            3. Направление помощи:
            продуктовая помощь;
            вещевая помощь;
            средства гигиены;
            психологическая помощь;
            помощь в оплате реабилитации инвалидов и ТСР.
            4. Адрес :  г. Кузнецк, ул. Белинского, 9а
            5. Контакты: 89276491377
            E-mail: nko.sodeistvie@yandex.ru
            <br/><hr/><br/>

            1. Название организации: Центр гуманитарной помощи
            2. Область, город: Пензенская область
            3. Направление помощи:
            продуктовая помощь;
            вещевая помощь
            4. Адрес:  раб. поселок Пачелма, ул. Драгунова, 10
            5. Контакты: 8-961-351-5765 Ирина Мартынова
            E-mail: nikcerkpach@yandex.ru; svgeorgiypachelma@mail.ru

            <br/><hr/><br/>

            Псковская область — Псков
            1. Название организация: Центр гуманитарной помощи
            2. Область, город: Псковская область, д. Усть-долыссы
            3. Направление помощи:
            гуманитарная,
            проживание на летний период
            4. Адрес: Псковская область, д. Усть-Долыссы
            5. Контакты: 8-911-357-31-39
            E-mail: hram-vladimir@mail.ru

            <br/><hr/><br/>
            Ростовская область — Ростов-на-Дону
            1. Название организации: Социальный приют "Матронин дом"
            2. Область, город: Ростовская область, пос. Шолоховский
            3. Направление помощи:
            временное проживание;
            гуманитарная помощь;
            юридическая помощь;
            помощь многодетным семьям
            4. Адрес: Ростовская область, Белокалитвенского р-на, пос.Шолоховский, ул. М.Горького,д.21
            5.Контакты: 8-961-320-20-58
            E-mail: ivovarova@yandex.ru
            Сайт: matronindompravork.ru


            <br/><hr/><br/>
            1.Название организации: Центр гуманитарной помощи
            2.Область, город: Ростовская область, г. Новошахтинск
            3.Направление помощи:
            сестринское сопровождение;
            продуктовая помощь;
            вещевая помощь;
            юридическая помощь;
            психологическая помощь
            4.Адрес г. Новошахтинск , ул. Харьковская 177
            5. Контакты 8903 430 85 33
            E-mail: blagohsinie.nov@mail.ru
            Сайт: hram-donskoi.pravorog.ru
            <br/><hr/><br/>

            1. Название организации : Приют для мам
            2. Область, город: г. Ростов-на-Дону
            3. Направление помощи:
            временное проживание,
            юридическая помощь;
            психологическая помощь;
            духовная помощь;
            вещевая помощь;
            продуктовая помощь;
            предметы гигиены
            4.Адрес: г. Ростов-на-Дону, ул. Орбитальная  1Б
            5. Контакты: 8-928-108-74-78, 8-800-775-81-30 гор.линия
            E-mail: otsbss@yandex.ru (епархия), miloserdie-don@yandex.ru
            Сайт: Милосердие-на Дону

            <br/><hr/><br/>
            1.Название организации : Центр Дом для Мамы
            2.Область, город: Ростовская область, г.Каменск-Шахтинск
            3.Направление помощи:
            временное проживание;
            продуктовая помощь;
            вещевая помощь;
            юридическая,
            психологическая помощь
            4.Адрес: г. Каменск-Шахтинский, пер. Башкевича, 85,( ул. Гагарина 91 дом для проживания)
            5. Контакты: 8-918- 563- 38- 41; 8-928-214-70-04
            E-mail: k1252008@yandex.ru
            <br/><hr/><br/>

            Рязанская область — Рязань
            1.Название организации : Благотворительный фонд охраны женского здоровья "Мы вместе"
            2.Область, город: Рязанская область, г.Рязань
            3.Направление помощи:
            продуктовая помощь,
            вещевая помощь,
            юридическая помощь;
            психологическая помощь;
            4.Адрес:  г.Рязань,ул. Каширина д.6
            5. Контакты: 8-910-645-97-66
            E-mail: vmesterzn@mail.ru
            Сайт: htt://vmesterzn.ru

            <br/><hr/><br/>
            1. Название организации : Благотворительный фонд во имя свт. Василия Рязанского г. Рязань
            2. Область, город: Рязанская область, г.Рязань
            3.Направление помощи:
            гуманитарная помощь;
            финансовая помощь
            4. Адрес :  Россия, Рязанская область, г.Рязань, ул. Горького, д. 14
            5. Контакты: 8(4912)45-18-64 вт, чт.10:00-14:00

            <br/><hr/><br/>
            Самарская область — Самара.
            Наименование организации: Центр гуманитарной помощи
            Область, город: Самарская область, Самара
            Направление помощи:
            гуманитарная помощь
            4. Адрес: г. Самара, ул. Вилоновская л. 22
            5. Контакты: телефон для обращений: 8 927 755 50 95

            <br/><hr/><br/>
            Наименование организации:  Региональная общественная организация поддержки семьи Самарской области "Родные люди"
            Область, город: Самарская область, Самара
            Направление помощи:
            вещевая помощь;
            продуктовая помощь;
            средства гигиены для детей;
            психологическая помощь;
            юридическая помощь
            4.   Адрес : г. Самара, ул. Высоцкого д. 8
            5.   Контакты: 89033028888
            E-mail:  sanfirova.s@mail.ru

            <br/><hr/><br/>
            Наименование организации: Центр социальной помощи женщинам "Ты не одна"
            Область, город: Самарская область, г. Самара
            Направление помощи:
            временное проживание
            вещевая помощь;
            психологическая помощь;
            юридическая помощь;
            продуктовая помощь
            4.   Адрес: г. Самара, ул. Ленинградская 100
            5.   Контакты: 89608290855, 89879494895

            <br/><hr/><br/>
            Наименование организации: Приют временного проживания
            Область, город: Самарская область, пгт Безенчук
            Направление помощи:
            продуктовая;
            вещевая помощь;
            средства гигиены для детей;
            духовная помощь;
            психологическая помощь;
            юридическая помощь;
            временное проживание для мам с детьми
            4. Адрес: Самарская обл, пгт Безенчук, ул. Чапаева д.8
            5. Контакты: 8 917 148 43 64
            E-mail:  syhristina.elena@yandex.ru
            <br/><hr/><br/>

            Саратовская область — Саратов
            Наименование организации: Центр гуманитарной помощи
            Область, город: Саратовская помощь, г. Саратов
            Направление помощи:
            вещевая помощь;
            4. Адрес: г.Саратов, ул. Лермонтова, 28
            5. Контакты: 8917201 94 61
            E-mail: aglinna@gmail.com
            <br/><hr/><br/>

            Наименование организации: АНО «Под покровом»
            Область, город: Саратовская область, г. Энгельс
            Направление помощи:
            вещевая помощь;
            продуктовая помощь;
            юридическая помощь;
            психологическая помощь
            4. Адрес: г. Энгельс, пл. Свободы д. 5
            5. Контакты: 8937224 95 77

            <br/><hr/><br/>
            Наименование организации: Автономная некоммерческая организация по оказанию социальных услуг "Кризисный центр "С верой в жизнь!"
            Область, город: Саратовская область, г. Балашов
            Направление помощи:
            временное проживание;
            продуктовая помощь;
            вещевая помощь;
            средства гигиены;
            юридическая помощь;
            психологическая помощь
            4.   Адрес: Саратовская обл, г. Балашов, ул. Советская д. 168
            5.   Контакты: 8-905-032-47-48, 8-909-336-64-39
            E-mail: malyuc-irina@yandex.ru
            Сайт: www.pkzveralife.ru

            <br/><hr/><br/>
            Наименование организации: Кризисный центр помощи беременным женщинам и женщинам с детьми, оказавшимся в ТЖС
            Область, город: Саратовская область, г. Вольск
            Направление помощи:
            временное проживание
            продуктовая помощь;
            вещевая помощь
            4.   Адрес: Саратовская обл, г. Вольск, пос. Пролетарский д. 38
            5.   Контакты: 8 987 313 62 71

            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Томская область, с. Межениновка
            Направление помощи:
            продуктовая помощь;
            вещевая помощь;
            средства гигиены
            4.   Адрес: Томская обл, с. Межениновка
            5.   Контакты: 89609736956
            Сайт: sluzhenie.tomsk.ru

            <br/><hr/><br/>
            Сахалинская область — Южно-Сахалинск
            Наименование организации: Благотворительный фонд "Радость жизни"/Кризисный центр "Дом милосердия"
            Область, город: Сахалинская область, г. Южно-Сахалинск
            Направление помощи:
            временное проживание;
            продуктовая помощь;
            вещевая помощь;
            гигиена и предметы мебели для детей;
            юридическая помощь;
            психологическая помощь
            4.   Адрес: Сахалинская обл., Анивский р-н, пос. Троицкое, ул. Сиреневая д. 19
            5.   Контакты: (4242) 47-17-18
            E-mail: podarizhiznsakh@mail.ru, radostzhizni@yandex.ru
            <br/><hr/><br/>

            Смоленская область — Смоленск

            Наименование организации: Смоленский Домик для мамы
            Область, город: Смоленская область, г. Смоленск
            Направление помощи:
            временное проживание;
            продуктовая помощь;
            вещевая помощь;
            психологическая помощь;
            юридическая помощь
            4.   Адрес: г. Смоленск, ул. Парковая, д. 2
            5.  Контакты: 89507063706, 89043675701
            E-mail: smol.dom.mama@mail.ru
            <br/><hr/><br/>

            Наименование организации: Центр гуманитарной помощи
            Область, город: Смоленская область, г. Вязьма
            Направление помощи:
            вещевая помощь;
            продуктовая помощь;
            4. Адрес: г. Вязьма, ул. Кронштадская д. 20
            5. Контакты: 8915655 38 54
            <br/><hr/><br/>

            Наименование организации: Центр гуманитарной помощи
            Область, город: Смоленская область, г. Рославль
            Направление помощи:
            - вещевая помощь;
            - продуктовая помощь
            4. Адрес: г. Рославль, ул. Ленина 36
            5. Контакты: 8910710 31 68
            E-mail: m9107103168@yandex.ru
            <br/><hr/><br/>
            Свердловская область — Екатеринбург

            Наименование организации: БФ "Сотвори Милость"
            Область, город: Свердловская область, г. Серов
            Направление помощи:
            продуктовая помощь;
            вещевая помощь;
            психологическая помощь;
            юридическая помощь
            4.   Адрес: г. Серов ул. Победы д. 42
            5.   Контакты: 8-902-877-02-50
            E-mail: anyk1988@yandex.ru

            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Свердловская область, г. Полевской
            Направление помощи:
            гуманитарная помощь
            4.   Адрес: г. Полевской, ул. Кологойды, д.3
            5.   Контакты:  89126781429
            E-mail: omax15@mail.ru
            <br/><hr/><br/>

            Наименование организации: Центр гуманитарной помощи
            Область, город: Свердловская область, г. Каменск-Уральский
            Направление помощи:
            гуманитарная помощь
            4.   Адрес: г. Каменск-Уральский, ул. Титова, 8
            5.   Контакты: 8-912-265-12-69
            E-mail: gallud1@mail.ru
            <br/><hr/><br/>

            Наименование организации: Центр гуманитарной помощи
            Область, город: Свердловская область, п. Большой Исток
            Направление помощи:
            вещевая помощь;
            духовная помощь
            4.   Адрес: п. Большой Исток, ул. Ленина 119В
            5.   Контакты: телефон для обращений: 89022709650
            E-mail:: hram_bistok@mail.ru
            <br/><hr/><br/>
            Наименование организации: Свердловская региональная общественная организация "Центр защиты материнства и детства "Дар жизни"
            Область, город: Свердловская область, г. Нижний Тагил
            Направление помощи:
            вещевая помощь;
            продуктовая помощь;
            психологическая помощь;
            юридическая помощь;
            патронаж подопечных семей
            4.   Адрес: г. Нижний Тагил, ул. Газетная, 54
            5.   Контакты: 89221832224
            E-mail: nt-dar@yandex.ru
            <br/><hr/><br/>

            Наименование организации: Свердловская региональная общественная организация "Центр защиты материнства и детства "Дар жизни"
            Область, город: Свердловская область, г. Алапаевск
            Направление помощи:
            вещевая помощь;
            продуктовая помощь;
            кроватки, коляски
            4.   Адрес: г. Алапаевск, ул. Ленина, 24
            5.   Контакты: 89221832224
            E-mail: nt-dar@yandex.ru
            <br/><hr/><br/>

            Наименование организации: Центр защиты семьи, материнства и детства
            Область, город: Свердловская область, г. Нижний Тагил
            Направление помощи:
            вещевая помощь;
            продуктовая помощь;
            средства гигиены;
            психологическая помощь;
            юридическая помощь;
            духовная помощь
            4. Адрес: г. Нижний Тагил, ул. Металлургов, 32
            5. Контакты:
            E-mail: info@ossne.ru
            <br/><hr/><br/>

            Наименование организации: Свердловская региональная общественная организация центр защиты материнства и детства "Дар жизни" Североуральское отделение
            Область, город: Свердловская область, Североуральск
            Направление помощи:
            продуктовая помощь;
            вещевая помощь;
            детское питание и средства гигиены;
            юридическая помощь;
            психологическая помощь
            4. Адрес: г. Североуральск, ул. Ленина 44
            5. Контакты: 8 900 210 77 15
            E-mail: darzhizni66@mail.ru
            <br/><hr/><br/>

            Наименование организации:  Приют "Мать и дитя"
            Область, город: Свердловская область, г. Первоуральск
            Направление помощи:
            временное проживание;
            продуктовая помощь;
            вещевая помощь;
            психологическая помощь;
            юридическая помощь
            4.   Адрес: г. Первоуральск, ул. Вайнера 89
            5.   Контакты: 8904545 68 23
            E-mail: aksana_04@mail.ru

            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Свердловская область, г. Екатеринбург
            Направление помощи:
            вещевая помощь;
            продуктовая помощь
            4.   Адрес: г. Екатеринбург, ул. Зеленая Роща д.1
            5.   Контакты: 8912660 88 03
            E-mail: skit1@sestry.ru
            <br/><hr/><br/>

            Наименование организации: Центр защиты семьи "Колыбель"
            Область, город: Свердловская область, г. Екатеринбург
            Направление помощи:
            временное проживание;
            продуктовая помощь;
            вещевая помощь;
            психологическая помощь;
            юридическая помощь;
            духовная помощь
            4.   Адрес: г Екатеринбург, ул. Кирова 65.
            5.   Контакты: 8950196 52 51
            <br/><hr/><br/>

            Наименование организации: Центр гуманитарной помощи
            Область, город: Свердловская область, г. Ирбит
            Направление помощи:
            вещевая помощь;
            психологическая помощь;
            юридическая помощь
            4.   Адрес: г. Ирбит, ул. Комсомольская 72
            5.   Контакты: 8902275 8 275
            E-mail: 2758275@mail.ru
            <br/><hr/><br/>

            Наименование организации: Кризисный центр для беременных и женщин с детьми «Ты не одна» / БФ "Ковчег"
            Область, город: Свердловская область, г. Нижний Тагил
            Направление помощи:
            временное проживание;
            продуктовая помощь;
            вещевая помощь;
            психологическая помощь;
            юридическая помощь
            4.   Адрес: г. Нижний Тагил. Металлургов, 32
            5.   Контакты: (3435) 46-48-09
            E-mail: kovceg-bf@yandex.ru
            <br/><hr/><br/>
            Наименование организации: Приют для женщин "Нечаянная радость"
            Область, город: Свердловская область, г. Екатеринбург
            Направление помощи:
            временное проживание;
            продуктовая помощь;
            вещевая помощь;
            психологическая помощь;
            юридическая помощь
            4.   Адрес: г. Екатеринбург, 8 км Сибирского тракта (на территории психбольницы)
            5.   Контакты: (343) 200-07-04
            E-mail: sekretar@soee.ru
            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Свердловская область, г. Екатеринбург
            Направление помощи:
            вещевая помощь;
            продуктовая помощь;
            юридическая помощь;
            психологическая помощь
            4.   Адрес: Екатеринбург, ул. Юлиуса Фучика, 3
            5.   Контакты: (343) 200-07-04
            E-mail: sekretar@soee.ru
            <br/><hr/><br/>
            Наименование организации: Благотворительный Фонд "Умиление"  Приют "Радость материнства"
            Область, город: Свердловская область, Алапаевск
            Направление помощи:
            временное проживание;
            продуктовая помощь;
            вещевая помощь;
            психологическая помощь;
            юридическая помощь;
            духовная помощь
            4.   Адрес: г. Алапаевск, Поселок Нейво-Шайтанский, ул Ленина, дом 74
            5.   Контакты: 8902 257 59 75
            E-mail: vas.elena1@yandex.ru

            <br/><hr/><br/>
            Наименование организации: Первоуральская городская общественная организация "Православный просветительский центр "СЕМЬЯ"/Приют "Мать и дитя"
            Область, город: Свердловская область, г. Первоуральск
            Направление помощи:
            временное проживание;
            продуктовая помощь;
            вещевая помощь;
            юридическая
            психологическая помощь
            4.   Адрес: г. Первоуральск, ул. Вайнера 89 (на территории храма Св.Екатерины)
            5.   Контакты: 8800201 73 44
            E-mail: alex.malahova@mail.ru
            <br/><hr/><br/>
            Наименование организации: Гуманитарный центр семьи и материнства " Надежда"
            Область, город: Свердловская область, г. Красноуфимск
            Направление помощи:
            вещевая помощь;
            продуктовая помощь;
            духовная помощь;
            психологическая помощь;
            юридическая помощь
            4.   Адрес: г. Красноуфимск, ул. Пролетарская 97
            5.   Контакты: 8902256 46 48
            E-mail: yks07@mail.ru

            <br/><hr/><br/>
            Тамбовская область — Тамбов
            Наименование организации: «Центр социальной помощи «Жемчужина леса»
            Область, город: Тамбовская область, Тамбов
            Направление помощи:
            срочная адресная социальная помощь женщинам
            4.   Адрес: Тамбовская область, с. Большая Липовица, ул. Коноплиновка д. 100
            5.   Контакты: 8-980 675 17 08
            <br/><hr/><br/>

            Наименование организации: Центр гуманитарной помощи
            Область, город: Тамбовская область, г. Тамбов
            Направление помощи:
            вещевая помощь;
            продуктовая помощь
            4. Адрес: г. Тамбов, ул. Железнодорожная д. 131
            5. Контакты: 88002508171, 89158887030
            Электронная почта: socotdel.te@gmail.com
            <br/><hr/><br/>

            Наименование организации: Центр гуманитарной помощи
            Область, город: Тамбовская область, г. Мичуринск
            Направление помощи:
            Вещевая помощь;
            Продуктовая помощь
            4.   Адрес: г. Мичуринск, ул. Красная д.72
            5.   Контакты: 8929019 1178
            E-mail: elenaum69@mail.ru
            <br/><hr/><br/>

            Тверская область — Тверь

            Наименование организации: Центр гуманитарной помощи
            Область, город: Тверская область, г. Нелидово
            Направление помощи:
            продуктовая помощь;
            вещевая помощь
            4.   Адрес: г. Нелидово, ул. Шахтерская д.5
            5.   Контакты: 8980627 23 79
            E-mail: slugenie@rambler.ru
            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Тверская область,  г.Тверь
            Направление помощи:
            вещевая помощь
            4.   Адрес: г. Тверь. Бульвар Цанова 8 (склад 10)
            5.   Контакты: 8904 003 42 70
            E-mail::040034270@mail.ru
            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Тверская область, г. Бологое
            Направление помощи:
            вещевая помощь;
            продуктовая помощь
            4.   Адрес: г. Бологое, ул. Кирова 7
            5.   Контакты: 8910933 84 57
            E-mail: lcapurova@yandex.ru
            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Тверская область, г. Ржев
            Направление помощи:
            вещевая помощь;
            продуктовая помощь
            4.   Адрес: г. Ржев, ул.  Республиканская, 11/30
            5.   Контакты: 8910832 48 93
            E-mail: ya.tatiana-maslova2016@yandex.ru
            <br/><hr/><br/>
            Наименование организации: Центр защиты семьи, материнства и детства
            Область, город: Тверская область, г. Удомля
            Направление помощи:
            вещевая помощь;
            продуктовая помощь;
            юридическая помощь;
            психологическая помощь
            4.   Адрес: г. Удомля, ул. Садовая д. 33
            5.   Контакты: 8915740 78 86
            E-mail: elenaavdeeva@mail.ru
            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Тверская область, г. Удомля
            Направление помощи:
            вещевая помощь;
            продуктовая помощь;
            лекарства
            4.   Адрес: г. Удомля, ул. Попова д. 17А (склад)
            5.   Контакты:  8915740 78 86
            E-mail: elenaavdeeva@mail.ru
            <br/><hr/><br/>

            Томская область — Томск
            Наименование организации: Автономная некоммерческая организация здравоохранения «Дом сестринского ухода святителя Луки (Войно-Ясенецкого)»
            Область, город: Томская область, г. Томск
            Направление помощи:
            продуктовая помощь;
            вещевая помощь;
            юридическая помощь
            4. Адрес: г. Томск, ул. Транспортная, 12
            5. Контакты: 8 913 114 26 14
            E-mail: salomaov@mail.ru
            <br/><hr/><br/>

            Наименование организации: Центр "Крылья"
            Область, город: Томская область, г. Северск
            Направление помощи:
            временное проживание;
            продуктовая помощь;
            вещевая помощь;
            психологическая/духовная помощь
            4.   Адрес: г. Северск, ул. Курчатова, 1а
            5.   Контакты: 8 929 373 45 55
            E-mail: s89293734555@gmail.com
            <br/><hr/><br/>

            Наименование организации: Благотворительный Центр «Поможем вместе»
            Область, город: Томская область, г. Томск
            Направление помощи:
            вещевая помощь;
            продуктовая помощь;
            психологическая помощь;
            юридическая помощь
            4.   Адрес: г. Томск, ул. Нефтяная 1
            5.   Контакты: 8 (952) 803- 85- 13
            E-mail: tomsk.pomogem@yandex.ru
            <br/><hr/><br/>

            Наименование организации: Региональный благотворительный общественный фонд «Право на детство»
            Область, город: Томская область, г. Томск
            Направление помощи:
            временное проживание;
            вещевая помощь;
            продуктовая помощь;
            психологическая помощь;
            юридическая помощь
            4.   Адрес: г. Томск, ул. Гагарина д. 46
            5.   Контакты: 8913807 35 50
            E-mail:  deti_tomsk@mail.ru
            <br/><hr/><br/>

            Наименование организации: Центр гуманитарной помощи
            Область, город: Томская область, с. Кожевниково
            Направление помощи:
            Продуктовая помощь;
            Вещевая помощь;
            Канцтовары и средства гигиены
            4. Адрес: Томская область, с. Кожевниково, ул. Калинина д. 66А
            5. Контакты: 8913284 82 66
            Сайт:  sluzhenie.tomsk.ru

            <br/><hr/><br/>
            Наименование организации: Кризисный центр "Маленькая мама"
            Область, город: Томская, г. Томск
            Направление помощи:
            - временное проживание;
            - продуктовая помощь;
            - вещевая помощь;
            - няня на час;
            - психологическая помощь;
            - юридическая помощь
            4.   Адрес: г. Томск, ул. Гагарина 46
            5.   Контакты: телефон для обращений: (3822) 535101, 89138073550
            E-mail: deti_tomsk@mail.ru

            <br/><hr/><br/>
            Тульская область — Тула
            Наименование организации:  Общероссийское Общественное Движение "В Защиту Детства"
            Область, город: Тульская, г. Советск
            Направление помощи:
            вещевая помощь;
            продуктовая помощь;
            юридическая помощь
            4.   Адрес: г. Советск, ул. Энергетиков, 52
            5.   Контакты: телефон для обращений: 89534317540

            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Тульская обл, г. Ясногорск
            Направление помощи:
            Продуктовая помощь;
            вещевая помощь;
            4.   Адрес: г. Ясногорск, ул. Садовая 21
            5.   Контакты: 8905627 81 83, 8963225 67 71
            E-mail: dmitrij12008@yandex.ru

            <br/><hr/><br/>
            Наименование организации: Хитровщинский дом милосердия
            Область, город: Тульская, пос. Новольвовск
            Направление помощи:
            временное проживание;
            продуктовая помощь;
            вещевая помощь;
            юридическая помощь;
            консультации врачей;
            помощь с трудоустройством на работу
            4. Адрес: Кимовский р-н, пос. Новольвовск, ул. Центральная, д. 12
            5. Контакты: 8903421 48 83
            E-mail: xbox360kimovsk@inbox.ru
            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Тульская, Тула
            Направление помощи:
            продуктовая помощь;
            вещевая помощь;
            средства гигиены
            4. Адрес: г. Тула ул. Льва Толстого, д. 79
            5. Контакты: телефон для обращений: (84872) 36 15 26
            E-mail: blagodelo_tula2012@mail.ru

            <br/><hr/><br/>
            Тюменская область — Тюмень
            Наименование организации: Тюменский Центр помощи беременным и женщинам с детьми, находящимся в тяжёлой жизненной ситуации «Дом для мам»
            Область, город: Тюменская, г.Тюмень
            Направление помощи:
            временное проживание;
            продуктовая помощь;
            вещевая помощь;
            духовная помощь
            4. Адрес: г. Тюмень, ул. Моторостроителей, 1а
            5. Контакты: телефон для обращений: 89223039393, 89526801012
            E-mail: zubarevo72@yandex.ru
            Сайт: tte-semya.pravorg.ru

            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город: Тюменская, г. Тюмень
            Направление помощи:
            вещевая помощь
            4. Адрес: г. Тюмень, ул. Малышева, 28, стр.2
            5. Контакты: 8 982 909 43 03
            E-mail: utolimp@mail.ru

            <br/><hr/><br/>
            Ульяновская область — Ульяновск
            1. Наименование организации: Центр гуманитарной помощи
            2. Область, город: Ульяновская область, р.п. Новая Майна
            3. Направление помощи:
            вещевая помощь;
            продуктовая помощь;
            средства гигиены
            4. Адрес: Ульяновская область, р.п. Новая Майна, ул. Советская, д.5
            5. Контакты: 8-927-822-73-78
            E-mail:  rioslaer@yandex.ru
            <br/><hr/><br/>

            Наименование организации: Ассоциация приёмных семей и опекунов Ульяновской области
            Область, город: Ульяновская область
            Направление помощи:
            юридическая помощь;
            психологическая помощь;
            вещевая помощь;
            продуктовая помощь
            г. Ульяновск, ул. Железной Дивизии, д.20
            Контакты:8 (927) 809-96-65, 8 (951) 094-41-77
            E-mail: deti73.ru@mail.ru, magda174@mail.ru
            Сайт: http://детивсенаши.рф
            <br/><hr/><br/>

            Челябинская область — Челябинск
            Наименование организации: Магнитогорский «Дом для мамы»
            Область, город: Челябинская область, г. Магнитогорск
            Направление помощи:
            временное проживание,
            психологическая помощь,
            юридическая помощь,
            помощь соцработника,
            помощь с трудоустройством
            Адрес: г. Магнитогорск, ул. Ворошилова, д. 16
            Контакты: 89028908818
            Сайт: https://vk.com/ddmam?ysclid=l75ux66s3v883951431

            <br/><hr/><br/>
            Наименование организации: Дом для мам г. Юрюзани
            Область, город: Челябинская область, г. Юрюзань
            Направление помощи:
            временное проживание,
            вещевая помощь,
            продуктовая помощь
            Адрес: г.Юрюзань, ул.Октябрьская 10 а
            Контакты: 8-912-300-66-30, 8 919 350 29 86
            E-mail: ewgenija.korobowa@yandex.ru
            <br/><hr/><br/>

            Наименование организации: Челябинская областная общественная организация "Центр поддержки семьи, материнства и детства "Дом для мамы" ( приют "Теплый дом")
            Область, город: Челябинская область, г.Магнитогорск
            Направление помощи:
            Временное проживание,
            Психологическая помощь,
            Юридическая помощь,
            Вещевая помощь,
            Продуктовая помощь
            Адрес: г. Магнитогорск, ул. Галиуллина, 19
            Контакты: +7 (922) 759-20-96, +7 (3519) 45-20-96,  +7 912 310-68-20
            E-mail: domdlamamy@mail.ru
            Сайт: https://ddm74.ru/
            <br/><hr/><br/>


            Наименование организации: Челябинская региональная общественная организация Центр защиты семьи, материнства и детства «Берег»
            Область, город: Челябинская область, г. Челябинск
            Направления помощи:
            временное проживание,
            психологическая помощь,
            юридическая помощь,
            вещевая помощь.
            г. Челябинск, ул. Российская, 59, г. Челябинск,
            ул. Бр. Кашириных, 118/1, цоколь
            Контакты: 89127741212, 8 (351) 223-03-09
            E-mail: info@bereg74.org
            Сайт: http://bereg74.org/

            <br/><hr/><br/>
            Наименование организации: Центр защиты семьи, материнства и детства
            Область, город: Челябинская обл., г. Челябинск
            Направления помощи:
            продуктовая помощь,
            вещевая помощь
            психологическая помощь,
            юридическая помощь,
            Адрес: г.Челябинск, ул.Энергетиков 21 а
            Контакты:
            89049707523, 8 932 01 47720
            E-mail: chelspas@mail.ru
            Сайт: https://vk.com/spaskonsyltant?ysclid=l768b29q3v643208754

            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город:  Челябинская область, г. Троицк
            Направление помощи:
            вещевая помощь
            Адрес: Челябинская область, г. Троицк
            Контакты:  8-967-865-98-70
            E-mail: troitsk-eparchy@mail.ru, otdel.blagotvoritelnost@yandex.ru
            сайт: https://vk.com/troeparhia

            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город:  Челябинскаяобл., г. Сатка
            Направление помощи:
            вещевая помощь,
            продуктовая помощь,
            Адрес: 	г. Сатка , ул. Пролетарская, 40
            Контакты:  8952 524 33 44
            Сайт: https://vk.com/centr.blago.satka?ysclid=l7g4wmye5x537536520

            <br/><hr/><br/>
            Наименование организации: Центр гуманитарной помощи
            Область, город:  Челябинскаяобл.
            Направление помощи: вещевая, продуктовая, детские товары
            Адрес: г. Златоуст, ул. Таганайская, 45
            Контакты: 8-908-075-31-62
            E-mail: ewgenija.korobowa@yandex.ru
            <br/><hr/><br/>


            Ярославская область — Ярославль
            Наименование организации: Приют для беременных "Покров"
            Область, город:  Ярославская область, г. Углич
            Направление помощи:
            временное проживание,
            социальная помощь,
            духовная помощь,
            помощь в трудоустройстве
            Адрес: г. Углич, ул. Шаркова, д.27
            Контакты: 8-905-643-16-92, +7 (48-532) 2-23-48
            <br/><hr/><br/>


            1. Наименование организации: Центр гуманитарной помощи
            2. Область, город:  Ярославская область, г. Ярославль
            3. Направление помощи:
            вещевая помощь,
            продуктовая помощь,
            помощь не продуктовыми товарами и средствами личной гигиены,
            юридическая помощь,
            психологическая помощь
            4. Адрес: г. Ярославль, ул. Суркова, 8 или ул. Терешковой, 3 (угловой дом)
            5. Контакты: +7 (4852) 60-00-40, +7 (4852) 60 00 50
            E-mail: http://www.medotdel76.ru

            <br/><hr/><br/>
            1. Наименование организации: Центр гуманитарной помощи
            2. Область, город:  Ярославская область, : г.Углич
            3. Направления помощи:
            вещевая помощь,
            продуктовая помощь
            4. Адрес: г.Углич, Студенческий городок, 12
            5. Контакты: 8-910-665-72-55
            E-mail: info@monastyr-bogoyavlenie.ru
            Сайт: https://monastyrbogoyavlenie.ru/
            <br/><hr/><br/>

            1. Наименование организации: Центр гуманитарной помощи
            2. Область, город:  Ярославская область, г. Ростов
            3. Направления помощи: вещевая помощь
            4. Адрес: г. Ростов , Соборная площадь ( на территории Ростовского кремля)
            5. Контакты: 8-910-829-7123
            E-mail: t-l-v@mail.ru
            <br/><hr/><br/>

            1. Наименование организации: Центр гуманитарной помощи
            2. Область, город: Ярославская область, г. Рыбинск,
            3. Направление помощи:
            вещевая помощь,
            продуктовая помощь,
            юридическая помощь,
            помощь не продовольственными товарами и средствами гигиены, медикаментами,
            психологическое консультирование
            4. Адрес: Ярославская область, г. Рыбинск,  ул. Крестовая, 7 (Отдел), бульвар Победы, д.32 (ЦГП)
            5. Контакты: 8(4855)295434 (Отдел), 8930-105 14 04 (ЦГП)
            E-mail: rybinskepar@mail.ru
            Сайт: https://rybeparhia.ru/
            <br/><hr/><br/>

            Еврейская АО — Биробиджан
            Наименование организации: Центр гуманитарной помощи
            Область, город: Еврейская АО, г. Биробиджан
            Направление помощи:
            вещевая помощь;
            питание;
            психологическая помощь
            4. Адрес: г. Биробиджан, пер. Швейный, 10
            5. Контакты: 8 914 810 11 92
            E-mail: pravoslavie79@yandex.ru

            <br/><hr/><br/>
            Ханты-Мансийский АО (Югра) — Ханты-Мансийск
            Наименование организации: Центр поддержки материнства «Моя радость»
            Область, город: ХМАО-Югра, г. Сургут
            Направления помощи:
            вещевая помощь;
            духовное окормление;
            информационная помощь;
            медицинская помощь;
            помощь в организации ночлега;
            юридическая помощь;
            психологическая помощь
            Адрес: г. Сургут, ул. Университетская, д. 12/2
            Контакты: +7 (3462) 94-02-05, +7 (3462) 60-20-24, +7 (3462) 94-02-01
            E-mail: georghramsurgut@mail.ru,
            Сайт: http://geohram.ru и https://vk.com/club74498711?ysclid=l6ysi7h7d710822791
            <br/><hr/><br/>

            Наименование организации: Центр милосердия «Доброе Дело»
            Область, город: ХМАО-Югра, Тюменская область, г.Советский
            Направления помощи:
            продуктовая помощь,
            вещевая помощь,
            юридическая помощь,
            духовная помощь,
            психологическая помощь,
            помощь в трудоустройстве
            Адрес: Тюменская область, г.Советский, ул.Ленина , 6, вход с ул. Калинина
            Контакты: +7908889 3453, +7 (34675) 33546
            E-mail: nikolskiy.hram@mail.ru
            Сайт: http://nikola-sov.cerkov.ru/, https://vk.com/dobroedelosovetskiy

            <br/><hr/><br/>
            Наименование организации: Региональный благотворительный фонд «Жизнь - священный дар»
            Область, город: ХМАО-Югра, г. Сургут
            Направление помощи:
            доабортное консультирование в ЖК,
            просвещение в защиту жизни,
            помощь в решении материальных проблем кризисной беременной.
            Адрес: г. Сургут, ул. Григория Кукуевицкого, д. 9
            Контакты: 89048794455, +7 (3462) 794455
            E-mail: shulinuna.lyudmila@yandex.ru
            Сайт: https://vk.com/zhsd86?ysclid=l709zawtds465389874
            <br/><hr/><br/>

            Наименование организации: Центр защиты материнства "Моя Радость"
            Область, город: ХМАО-Югра, г. Сургут,
            Направление помощи:
            вещевая помощь,
            продуктовая помощь,
            юридическая помощь,
            психологическая помощь,
            временное проживание для женщин с детьми до двух месяцев
            Адрес: г. Сургут, ул. Университетская, 12/2
            Контакты: +7 (3462) 60-20-24,+7 (3462) 94-02-05, +7 (3462) 94-02-01
            E-mail: cpm86@yandex/ru
            Сайт: https://vk.com/club74498711?ysclid=l70ac4d5zi589101570

            <br/><hr/><br/>




          </WrapContent>
        </WrapInner>
      </Inner>
    </Wrapper>
  )
};
