import React, { useEffect, useState } from "react";

import {
  Wrapper,
  Inner,
  WrapLogo,
  WrapRightPart,
  StyledBurger,
  StyledMenu,
  WrapHeart,
  WrapMobPoddergati,
  WrapTwoColumns
} from "./mobileMenu-styled";
import logo from "../logo.svg";
import heart from "../heart.svg";
import {Link} from "react-router-dom";

// https://codepen.io/maximakymenko/pen/aboWJpX?editors=0110

export const MobileMenu = () => {
  const [open, setOpen] = useState(false);
  const node = React.useRef();

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
      document.getElementById("root").style.overflow ='hidden';
      document.documentElement.style.overflow ='hidden';
    } else {
      document.body.style.overflow = 'unset';
      document.getElementById("root").style.overflow = 'unset';
      document.documentElement.style.overflow ='unset';
    }
  }, [open]);

  return (
    <>
      <Wrapper>

        <Inner>
          <WrapLogo>
            <a rel="noopener noreferrer" href="/" className='logo'>
              <img src={logo} alt="ДомДляМамы"/>
            </a>
          </WrapLogo>
          <WrapRightPart>
            <WrapHeart>
              <img src={heart} alt="ДомДляМамы"/>
              <a target="_blank" rel="noopener noreferrer" href="https://domdliamamy.ru/donation">Поддержать</a>
            </WrapHeart>
            <WrapMobPoddergati>
              <Link to="/nugnapomosi">Нужна помощь</Link>
            </WrapMobPoddergati>
            <div ref={node}>
              <StyledBurger open={open} onClick={() => setOpen(!open)}>
                <div />
                <div />
                <div />
              </StyledBurger>
              <StyledMenu open={open} onClick={() => setOpen(false)}>
                <Link to="/">Главная</Link>
                <Link to="/onas">о нас </Link>
                <Link to="/consulitationnii-tsentr">Консультационный центр</Link>
                <Link to="/cgp">Гуманитарная помощь</Link>
                <Link to="/krizisnye-centry">Центры страны</Link>
                <Link to="/metodicheskaia-pomoshi">Методическая помощь</Link>
                <Link to="/napravlenia-deeatelinosti">Направления деятельности</Link>
                <Link to="/novosti">Новости</Link>
                <Link to="/mivsmi">Мы в СМИ</Link>
                <WrapTwoColumns>
                  <Link to="/etapi">Этапы</Link>
                  <Link to="/priut">Приют</Link>
                  <Link to="/partneri">Партнеры</Link>
                  <Link to="/volonteri">Волонтеры</Link>
                  <Link to="/otcheti">Отчеты</Link>
                  <Link to="/contacti">Контакты</Link>
                </WrapTwoColumns>

              </StyledMenu>
            </div>
          </WrapRightPart>
        </Inner>

      </Wrapper>
    </>
  )
};