import React, { useState, useEffect } from "react";
import { YMaps, Map, GeoObject, ZoomControl, FullscreenControl } from 'react-yandex-maps';
import { HideShowButton, WrapButtonShowHide, WrapMap, HideShowButtonRed } from "./styled-centri";

export const MapCenter = (props) => {
  const { rerun, searchQuery, searchedCentri, showMap, setShowMap, sendCentOne, setRerun } = props;
  const [centri, setCentri] = useState([]);

  useEffect(() => {
    fetch("https://domdliamamy.ru/donation/wp-json/wp/v2/custom-post/?per_page=400")
      .then((response) => response.json())
      .then((data) => setCentri(data))
      .catch((error) => console.log(error));
  }, [rerun]);

  const toggleMap = () => setShowMap(!showMap);

  const initialCoordinates = searchedCentri.at(0)?.coordinates.split(',').map(parseFloat);

  useEffect(() => {
    if (sendCentOne.length === 1) {
      setCentri(sendCentOne);
    }
  }, [sendCentOne]);

  const resetMapData = () => {
    setRerun(true);
  }

  return (
    <WrapMap>
      <WrapButtonShowHide>
        { sendCentOne.length === 1 && (<HideShowButtonRed onClick={resetMapData}>
          Сбросить
        </HideShowButtonRed>)}
        <HideShowButton onClick={toggleMap}>
          {showMap ? "Скрыть карту" : "Показать карту"}
        </HideShowButton>
      </WrapButtonShowHide>
      {showMap && (
        <YMaps apiKey="acc91f13-ee4c-411f-87c1-8b6fc0b87ee1">
          <Map
            defaultState={{
              center: (searchQuery.length > 0 || sendCentOne.length > 0) ? initialCoordinates : [55.75, 37.57],
              zoom: 5,
            }}
            width="100%"
            height={{ mobile: '250px', desktop: '400px' }}
            style={{ height: '250px' }}
          >
            {(searchQuery.length > 0 ? searchedCentri : centri).map((item) => {
              const regex = /^-?\d+(\.\d+)?,\s?-?\d+(\.\d+)?$/;
              if (!regex.test(item.coordinates.split(',').map(parseFloat)))  return null;
              return (
                <GeoObject
                  key={item.id}
                  geometry={{
                    type: 'Point',
                    coordinates: item.coordinates.split(',').map(parseFloat),
                  }}
                  properties={{
                    iconCaption: item.org_name,
                    balloonContentBody: `
                      <div style="max-width: 200px; padding: 10px; display: flex; flex-direction: column;">
                        <strong>${item.org_name}</strong>
                        <br />
                        Phone: ${item.phone}
                        <br />
                        Website: ${item.website_org}
                      </div>
                    `,
                  }}
                  options={{
                    preset: 'islands#blueCircleIcon',
                    iconColor: '#ff0000',
                  }}
                  modules={['geoObject.addon.balloon', 'geoObject.addon.hint']} // Add this line
                  onClick={() => {
                  }}
                />
                )
            })}
            <ZoomControl options={{ float: 'right' }} />
            <FullscreenControl options={{ float: 'right' }} />
          </Map>
        </YMaps>
      )}
    </WrapMap>
  );
}
